import React from 'react';
import { Row } from 'react-bootstrap';
import OurClubCard from './OurClubCard/OurClubCard';

export default function OurClubsScreenContent({ clubs }) {
	return (
		<Row className='gap-4'>
			{clubs.map((club) => (
				<OurClubCard key={club.id} club={club} />
			))}
		</Row>
	);
}
