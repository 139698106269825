import React from 'react';
import {
	//Facebook,
	Whatsapp,
	EnvelopeFill,
} from 'react-bootstrap-icons';
import TwitterX from './Icon/TwitterX';
//import { Button } from 'react-bootstrap';

export default function ShareButtons() {
	const shareUrl = window.location.href;
	const shareTitle =
		document.title +
		' – Cirma | Centro de Investigaciones Regionales de Mesoamérica';
	return (
		<div className='c-share-container'>
			{/* <Button
				className='c-share-button-f'
				target='_blank'
				href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
				data-href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}>
				<Facebook />
			</Button> */}
			{/* <div
				class='fb-share-button c-share-button'
				data-href={shareUrl}
				data-layout='button'
				data-size='small'>
				<a
					target='_blank'
					href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&amp;src=sdkpreparse`}
					class='fb-xfbml-parse-ignore'
					rel='noreferrer'>
					Compartir
				</a>
			</div> */}
			<iframe
				src={`https://www.facebook.com/plugins/share_button.php?href=${shareUrl}&layout=button&size=small&width=89&height=20&appId`}
				width='89'
				height='20'
				style={{
					border: 'none',
					overflow: 'hidden',
					marginRight: '10px',
				}}
				title={shareTitle}
				allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'
			></iframe>
			{/* <Button className='c-share-button-t'>
				<Twitter />
			</Button> */}
			{/* <div className='c-share-button'>
				src={`http://platform.twitter.com/widgets/tweet_button.html?via=cirmaguatemala&text=${shareUrl}&count=horizontal`}
				<a
					href={`https://twitter.com/share?ref_src=${shareUrl}`}
					class='twitter-share-button'
					data-show-count='false'>
					Compartir
				</a>
			</div> */}
			<a
				className='c-share-button-t'
				href={`http://twitter.com/share?url=${shareUrl}`}
				title='Share by Twitter'
				target='_blank'
				rel='noreferrer'
			>
				<TwitterX width={11} height={11} /> Compartir
			</a>

			<a
				className='c-share-button-m'
				href={`mailto:?subject=${shareTitle}&body=${shareUrl}`}
				title='Share by Email'
				target='_blank'
				rel='noreferrer'
			>
				<EnvelopeFill /> Compartir
			</a>
			{/* <Button className='c-share-button-w'>
				<Whatsapp />
			</Button> */}
			<a
				className='c-share-button-w'
				href={`whatsapp://send?text=${shareUrl}`}
				data-action='share/whatsapp/share'
				title='Share by Whatsapp'
				target='_blank'
				rel='noreferrer'
			>
				<Whatsapp />
				Compartir
			</a>
		</div>
	);
}
