import React from 'react'
import Back2TopBtn from '../components/Back2TopBtn';
import Footer from '../components/Footer/Footer';
import TopMenu from '../components/Navbar/Navbar';

export default function SuperBasicLayout(props) {
    const { children } = props;
	return (
		<div className='c-main-wrapper'>
			<TopMenu background='white' light='navbar-light' dark='' />
			{/* <TopMenu/> */}
			{/* <TopSimpleSection backgroundImage={backgroundImage} search={search} /> */}
			{children}
			<Back2TopBtn/>
			<Footer />
		</div>
	);
}
