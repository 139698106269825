import React, { useState } from 'react';
import {
	Nav,
	Navbar,
	Container,
	NavDropdown,
	Button,
	Form,
	Offcanvas,
} from 'react-bootstrap';
// import { PersonCircle } from "react-bootstrap-icons";
import {
	HeartFill,
	Search,
	Facebook,
	Instagram,
	Youtube,
} from 'react-bootstrap-icons';
import logo from '../../images/cirma-logo-home.svg';
import DropdownItemContent from './DropdownItemContent';
import DropdownMenu from './DropdownMenu';
import TwitterX from '../Icon/TwitterX';
//import app_params from "../../config/params";

const basePath = '/'; /* app_params.otherSite; */

const libraryURL = 'https://cirma.org.gt/opac';

export default function TopMenu(props) {
	const [seletedDropdown, setSelectedDropdown] = useState(null);
	const width = window.innerWidth;
	return (
		<div
			className='c-nav-bar-fixer'
			id='c-nav-bar-fixer'
			onMouseLeave={() => width > 992 && setSelectedDropdown(null)}
		>
			<Navbar
				className={`c-top-nav ${props?.light} ${props?.dark} ${props?.background}`}
				expand='lg'
				id='c-top-navbar'
			>
				<Container fluid className='c-nav-cont'>
					<Navbar.Brand href={basePath}>
						<img src={logo} alt='CIRMA' id='navbar-logo' />
					</Navbar.Brand>
					{width > 992 ? (
						<>
							<Navbar.Toggle
								aria-controls='navbarScroll'
								className='c-navbar-toggle'
							/>
							<Navbar.Collapse
								id='navbarScroll'
								className='d-none d-lg-block'
							>
								<Nav className='me-auto my-2 my-lg-0 c-navbar-items'>
									<NavDropdown
										renderMenuOnMount={true}
										className='e1'
										title={
											<div className='droptext'>
												BIBLIOTECA
											</div>
										}
										id='navbarScrollingDropdown'
										onMouseEnter={() =>
											setSelectedDropdown('biblioteca')
										}
										show={seletedDropdown === 'biblioteca'}
										onClick={() =>
											(window.location = libraryURL)
										}
									>
										<DropdownItemContent
											item='biblioteca'
											path={libraryURL}
											setSelectedDropdown={
												setSelectedDropdown
											}
										/>
									</NavDropdown>
									<NavDropdown
										renderMenuOnMount={true}
										className='e2'
										title={
											<div className='droptext'>
												FOTOTECA GUATEMALA
											</div>
										}
										id='navbarScrollingDropdown'
										onMouseEnter={() =>
											setSelectedDropdown('fototeca')
										}
										show={seletedDropdown === 'fototeca'}
										onClick={() =>
											(window.location = `${basePath}glifos/agency/GT-CIRMA-FG`)
										}
									>
										<DropdownItemContent
											item='fototeca'
											path={`${basePath}glifos/agency/GT-CIRMA-FG`}
											setSelectedDropdown={
												setSelectedDropdown
											}
										/>
										{/*path='/fototeca-guatemala'*/}
									</NavDropdown>
									<NavDropdown
										renderMenuOnMount={true}
										className='e3'
										title={
											<div className='droptext'>
												ARCHIVO HISTÓRICO
											</div>
										}
										id='navbarScrollingDropdown'
										onMouseEnter={() =>
											setSelectedDropdown('archivo')
										}
										show={seletedDropdown === 'archivo'}
										onClick={() =>
											(window.location = `${basePath}glifos/agency/GT-CIRMA-AH`)
										}
									>
										<DropdownItemContent
											item='archivo'
											path={`${basePath}glifos/agency/GT-CIRMA-AH`}
											setSelectedDropdown={
												setSelectedDropdown
											}
										/>
										{/*path='/archivo-historico'*/}
									</NavDropdown>
									<NavDropdown
										renderMenuOnMount={true}
										className='e4'
										title={
											<div className='droptext'>
												ACERCA DE CIRMA
											</div>
										}
										id='navbarScrollingDropdown'
										onMouseEnter={() =>
											setSelectedDropdown('acerca')
										}
										show={seletedDropdown === 'acerca'}
										onClick={() =>
											setSelectedDropdown(
												seletedDropdown === 'acerca'
													? null
													: 'acerca'
											)
										}
									>
										<DropdownMenu
											setSelectedDropdown={
												setSelectedDropdown
											}
										/>
									</NavDropdown>
								</Nav>
								<Form
									className='c-nav-form d-flex align-items-center'
									onMouseEnter={() =>
										setSelectedDropdown(null)
									}
								>
									<Button
										href={`${basePath}donaciones`}
										variant='primary'
										className='c-nav-btn-donate'
									>
										<HeartFill /> DONAR
									</Button>
									<div className='c-nav-btns d-flex flex-wrap'>
										<Button
											className='c-search'
											variant='transparent'
											href={`${basePath}search`}
										>
											<Search />
										</Button>
										<Button
											className='c-search vertical-bar-left social-media'
											variant='transparent'
											href={`https://www.facebook.com/cirma.guatemala`}
											target='_blank'
										>
											<Facebook
												color={
													props?.dark
														? 'white'
														: '#831131'
												}
											/>
										</Button>
										<Button
											className='c-search social-media'
											variant='transparent'
											href={`https://www.instagram.com/cirma.guatemala/`}
											target='_blank'
										>
											<Instagram
												color={
													props?.dark
														? 'white'
														: '#831131'
												}
											/>
										</Button>
										<Button
											className='c-search social-media'
											variant='transparent'
											href={`https://www.youtube.com/channel/UC8rBjONgDjb6BVZsDaj7WuQ`}
											target='_blank'
										>
											<Youtube
												color={
													props?.dark
														? 'white'
														: '#831131'
												}
											/>
										</Button>
										<Button
											className='c-search social-media'
											variant='transparent'
											href={`https://twitter.com/CIRMAGUATEMALA`}
											target='_blank'
										>
											<TwitterX
												color={
													props?.dark
														? 'white'
														: '#831131'
												}
												width='16'
												height='16'
											/>
										</Button>
									</div>
								</Form>
							</Navbar.Collapse>
						</>
					) : (
						<>
							<Navbar.Toggle
								aria-controls='navbarOffset'
								className='c-navbar-toggle'
							/>
							<Navbar.Offcanvas
								id={`navbarOffset`}
								aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
								placement='end'
							>
								<Offcanvas.Header closeButton>
									<img
										src={logo}
										alt='CIRMA'
										id='navbar-logo'
										height='70'
									/>
								</Offcanvas.Header>
								<Offcanvas.Body>
									<Nav className='justify-content-end flex-grow-1 pe-3'>
										<NavDropdown
											renderMenuOnMount={true}
											className='e1 offcanvas-dropdown'
											title={
												<div className='droptext'>
													BIBLIOTECA
												</div>
											}
											id='navbarScrollingDropdown'
											show={
												seletedDropdown === 'biblioteca'
											}
											onClick={() =>
												setSelectedDropdown(
													seletedDropdown ===
														'biblioteca'
														? null
														: 'biblioteca'
												)
											}
										>
											<DropdownItemContent
												item='biblioteca'
												path={libraryURL}
												setSelectedDropdown={
													setSelectedDropdown
												}
											/>
										</NavDropdown>
										<NavDropdown
											renderMenuOnMount={true}
											className='e2 offcanvas-dropdown'
											title={
												<div className='droptext'>
													FOTOTECA GUATEMALA
												</div>
											}
											id='navbarScrollingDropdown'
											show={
												seletedDropdown === 'fototeca'
											}
											onClick={() =>
												setSelectedDropdown(
													seletedDropdown ===
														'fototeca'
														? null
														: 'fototeca'
												)
											}
										>
											<DropdownItemContent
												item='fototeca'
												path={`${basePath}glifos/agency/GT-CIRMA-FG`}
												setSelectedDropdown={
													setSelectedDropdown
												}
											/>
											{/*path='/fototeca-guatemala'*/}
										</NavDropdown>
										<NavDropdown
											renderMenuOnMount={true}
											className='e3 offcanvas-dropdown'
											title={
												<div className='droptext'>
													ARCHIVO HISTÓRICO
												</div>
											}
											id='navbarScrollingDropdown'
											show={seletedDropdown === 'archivo'}
											onClick={() =>
												setSelectedDropdown(
													seletedDropdown ===
														'archivo'
														? null
														: 'archivo'
												)
											}
										>
											<DropdownItemContent
												item='archivo'
												path={`${basePath}glifos/agency/GT-CIRMA-AH`}
												setSelectedDropdown={
													setSelectedDropdown
												}
											/>
										</NavDropdown>
										<NavDropdown
											renderMenuOnMount={true}
											className='e4 offcanvas-dropdown'
											title={
												<div className='droptext'>
													ACERCA DE CIRMA
												</div>
											}
											id='navbarScrollingDropdown'
											show={seletedDropdown === 'acerca'}
											onClick={() =>
												setSelectedDropdown(
													seletedDropdown === 'acerca'
														? null
														: 'acerca'
												)
											}
										>
											<DropdownMenu
												setSelectedDropdown={
													setSelectedDropdown
												}
											/>
										</NavDropdown>
									</Nav>
									<Form className='c-nav-form'>
										<Button
											href={`${basePath}donaciones`}
											variant='primary'
											className='c-nav-btn-donate'
										>
											<HeartFill /> DONAR
										</Button>
										<div className='c-nav-btns d-flex flex-wrap'>
											<Button
												className='c-search'
												variant='transparent'
												href={`${basePath}search`}
											>
												<Search color={'#831131'} />
											</Button>
											<Button
												className='c-search vertical-bar-left social-media'
												variant='transparent'
												href={`https://www.facebook.com/cirma.guatemala`}
											>
												<Facebook color={'#831131'} />
											</Button>
											<Button
												className='c-search social-media'
												variant='transparent'
												href={`https://www.instagram.com/cirma.guatemala/`}
											>
												<Instagram color={'#831131'} />
											</Button>
											<Button
												className='c-search social-media'
												variant='transparent'
												href={`https://www.youtube.com/channel/UC8rBjONgDjb6BVZsDaj7WuQ`}
											>
												<Youtube color={'#831131'} />
											</Button>
											<Button
												className='c-search social-media'
												variant='transparent'
												href={`https://twitter.com/CIRMAGUATEMALA`}
											>
												<TwitterX
													color={'#831131'}
													width='16'
													height='16'
												/>
											</Button>
										</div>
									</Form>
								</Offcanvas.Body>
							</Navbar.Offcanvas>
						</>
					)}
				</Container>
			</Navbar>
		</div>
	);
}
