import React from 'react';

export default function Paragraph(props) {
	const { children, className } = props;
	return (
		<div
			className={className}
			dangerouslySetInnerHTML={{ __html: children }}
		></div>
	);
}
