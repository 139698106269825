import React, { useMemo } from 'react';
import { NavDropdown } from 'react-bootstrap';
import historia from '../../images/icons/historia.svg';
import fundadores from '../../images/icons/fundadores.svg';
import sede from '../../images/icons/sede.svg';
import videos from '../../images/icons/videos.svg';
import contactenos from '../../images/icons/contactenos.svg';
import noticias from '../../images/icons/noticias.svg';
import exposiciones from '../../images/icons/exposiciones.svg';
import publicaciones from '../../images/icons/publicaciones.svg';
import reconocimientos from '../../images/icons/reconocimientos.svg';
import investigacion from '../../images/icons/investigacion.svg';

/* import app_params from "../../config/params"; */

const basePath = '/'; /* app_params.otherSite; */

export default function DropdownMenu(props) {
	const { setSelectedDropdown } = props;
	const width = useMemo(() => {
		if (typeof window !== 'undefined') {
			return window.innerWidth;
		}
	}, []);
	return (
		<div
			className='c-dropdown-menu'
			onMouseLeave={() => width > 992 && setSelectedDropdown(null)}
		>
			<div className='c-dropdown-item'>
				<NavDropdown.Item href={`${basePath}historia-y-mision`}>
					<div className='item-container'>
						<div className='item-icon-container'>
							<div className='item-icon'>
								<img src={historia} alt='' />
							</div>
						</div>
						<div className='item-text'>Historia y misión</div>
					</div>
				</NavDropdown.Item>
			</div>
			<div className='c-dropdown-item'>
				<NavDropdown.Item href={`${basePath}fundadores`}>
					<div className='item-container'>
						<div className='item-icon-container'>
							<div className='item-icon'>
								<img src={fundadores} alt='' />
							</div>
						</div>
						<div className='item-text'>Fundadores</div>
					</div>
				</NavDropdown.Item>
			</div>
			<div className='c-dropdown-item'>
				<NavDropdown.Item href={`${basePath}sede`}>
					<div className='item-container'>
						<div className='item-icon-container'>
							<div className='item-icon'>
								<img src={sede} alt='' />
							</div>
						</div>
						<div className='item-text'>Sede</div>
					</div>
				</NavDropdown.Item>
			</div>
			<div className='c-dropdown-item'>
				<NavDropdown.Item href={`${basePath}videos-informativos`}>
					<div className='item-container'>
						<div className='item-icon-container'>
							<div className='item-icon'>
								<img src={videos} alt='' />
							</div>
						</div>
						<div className='item-text'>Videos informativos</div>
					</div>
				</NavDropdown.Item>
			</div>
			<div className='c-dropdown-item'>
				<NavDropdown.Item href={`${basePath}contactenos`}>
					<div className='item-container'>
						<div className='item-icon-container'>
							<div className='item-icon'>
								<img src={contactenos} alt='' />
							</div>
						</div>
						<div className='item-text'>Contáctanos</div>
					</div>
				</NavDropdown.Item>
			</div>
			<div className='c-dropdown-item'>
				<NavDropdown.Item href={`${basePath}noticias-y-novedades`}>
					<div className='item-container'>
						<div className='item-icon-container'>
							<div className='item-icon'>
								<img src={noticias} alt='' />
							</div>
						</div>
						<div className='item-text'>Noticias y novedades</div>
					</div>
				</NavDropdown.Item>
			</div>
			<div className='c-dropdown-item'>
				<NavDropdown.Item href={`${basePath}exposiciones`}>
					<div className='item-container'>
						<div className='item-icon-container'>
							<div className='item-icon'>
								<img src={exposiciones} alt='' />
							</div>
						</div>
						<div className='item-text'>Exposiciones itinerantes</div>
					</div>
				</NavDropdown.Item>
			</div>
			<div className='c-dropdown-item'>
				<NavDropdown.Item href={`${basePath}publicaciones`}>
					<div className='item-container'>
						<div className='item-icon-container'>
							<div className='item-icon'>
								<img src={publicaciones} alt='' />
							</div>
						</div>
						<div className='item-text'>Publicaciones</div>
					</div>
				</NavDropdown.Item>
			</div>
			<div className='c-dropdown-item'>
				<NavDropdown.Item href={`${basePath}reconocimientos`}>
					<div className='item-container'>
						<div className='item-icon-container'>
							<div className='item-icon'>
								<img src={reconocimientos} alt='' />
							</div>
						</div>
						<div className='item-text'>Reconocimientos</div>
					</div>
				</NavDropdown.Item>
			</div>
			<div className='c-dropdown-item'>
				<NavDropdown.Item
					href={`${basePath}proyectos-de-investigacion`}
				>
					<div className='item-container'>
						<div className='item-icon-container'>
							<div className='item-icon'>
								<img src={investigacion} alt='' />
							</div>
						</div>
						<div className='item-text'>Investigación</div>
					</div>
				</NavDropdown.Item>
			</div>
		</div>
	);
}
