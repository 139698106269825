import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function ModalComponent(props) {
	const {
		title,
		subtitle = '',
		content,
		showModal,
		setShowModal,
		expo,
		link,
		donation,
		keys = {
			title: '',
			description: '',
		},
	} = props;
	const navigate = useNavigate();
	//console.log(link);
	function handleClick() {
		if (link.includes('virtual-tour')) window.open(link, '_blank');
		else navigate(link);
	}
	return showModal ? (
		<Modal
			show={showModal}
			onHide={() => setShowModal(false)}
			size='lg'
			className='c-modal'
			centered
			aria-labelledby='modal-founder'
		>
			<Modal.Header
				className='c-modal-header'
				closeButton
				closeVariant='white'
			>
				<Modal.Title id='example-custom-modal-styling-title'>
					{!subtitle ? (
						<h3 className='c-modal-title' wbk={keys.title}>
							{title}
						</h3>
					) : (
						<div className='d-flex flex-column'>
							<h3 className='c-modal-title' wbk={keys.title}>
								{title}
							</h3>
							<h4 className='c-modal-subtitle'>{subtitle}</h4>
						</div>
					)}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{!donation && (
					<p className='c-modal-content' wbk={keys.description}>
						{content}
					</p>
				)}
				{expo && (
					<div className='c-modal-button'>
						<Button
							variant='outline-primary'
							className='c-button'
							onClick={handleClick}
						>
							{' '}
							Ver guión museográfico
						</Button>
					</div>
				)}
				{donation && (
					//Aqui va las opciones de donaciones
					<div></div>
				)}
			</Modal.Body>
		</Modal>
	) : (
		''
	);
}
