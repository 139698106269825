import React from 'react';
import { render } from 'react-dom';
import { createBrowserHistory } from 'history';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './index.css';
import './scss/theme.scss';
import reportWebVitals from './reportWebVitals';
import { AllRoutes } from './utilities/routes';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-G8454T9E63');
const hist = createBrowserHistory();

render(
	<BrowserRouter history={hist}>
		<Routes>
			{AllRoutes.map((route, index) => (
				<Route key={index} path={route.path} element={<route.page />} />
			))}
		</Routes>
	</BrowserRouter>,
	document.getElementById('c-root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
