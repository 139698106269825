import React from 'react';

export default function SimpleSection(props) {
	const { className, children, backgroundimage, id='' } = props;
	return (
		<section
			className={
				'c-simple-section ' + (undefined !== className ? className : '')
			}
			id={id}
			style={
				undefined !== backgroundimage
					? { backgroundImage: 'url(' + backgroundimage + ')' }
					: {}
			}>
			{children}
		</section>
	);
}
