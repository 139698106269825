import Axios from '../services/DrupalAPI';
import axios from 'axios';
import { MONTHS } from './globals';

export const isValidURL = (url) => {
	try {
		return Boolean(new URL(url));
	} catch (e) {
		return false;
	}
};

export const AxiosDrupal = async (
	method,
	params = {},
	data = {},
	urlParams
) => {
	try {
		//console.log(Axios);
		const response = await Axios[method]({ data, params, urlParams });
		return { success: true, data: response.data };
	} catch (error) {
		console.log(error);
		if (error.response)
			return {
				success: false,
				error: error.response?.data ?? error.message,
			};
		else if (error.request) return { success: false, error: error.request };
		else return { success: false, error: error.message };
	}
};

export const CustomAxios = async (
	method,
	url,
	body = {},
	headers = { 'Content-Type': 'application/json' }
) => {
	try {
		const { data } = await axios({
			method,
			url,
			data: body,
			headers,
		});
		return { success: true, data };
	} catch (error) {
		console.log(error);
		if (error.response)
			return {
				success: false,
				error: error.response?.data ?? error.message,
			};
		else if (error.request) return { success: false, error: error.request };
		else return { success: false, error: error.message };
	}
};

export const cleanText = (text) =>
	text
		?.replaceAll(/\s+/g, '_')
		?.normalize('NFD')
		?.replace(/[\u0300-\u036f]/g, '')
		?.toLowerCase();

export const getDateFormat = (date, format) => {
	const time = !date?.includes('T') ? 'T00:00:00' : '';
	console.log(date, time);
	const fecha = new Date(date + time);

	fecha.toLocaleString('es-GT', { timeZone: 'America/Guatemala' });
	const [day, month, year] = [
		fecha.getDate(),
		fecha.getMonth(),
		fecha.getFullYear(),
	];
	return format
		?.replace('DD', day)
		?.replace('MM', month + 1)
		?.replace('YYYY', year)
		?.replace('MON', MONTHS[month]?.toString().toUpperCase().slice(0, 3))
		?.replace('Month', MONTHS[month])
		?.replace('month', MONTHS[month]?.toString().toLowerCase())
		?.replace('D', day)
		?.replace('M', month + 1)
		?.replace('Y', year);
};

export const removeRightClick = (e) => {
	if (e.button === 2) {
		e.preventDefault();
		return false;
	}
};
