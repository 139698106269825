import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import AboutLayout from '../layouts/AboutLayout';

import SimpleSection from '../components/SimpleSection';

import Cover1 from '../images/research-image.jpg';
import WBkey from '../components/WBkey';

export default function ResearchScreen(props) {
	return (
		<AboutLayout
			title='Proyectos de investigación'
			subtitle='Inician en 1998 y finalizan en 2013'
			backgroundImage='investigacion'>
			<Container>
				<SimpleSection className='c-research-cirma'>
					<Row>
						<Col sm={12} lg={6}>
							<div className='c-research-info'>
								<div className='c-research-note'>
									<p className='c-note'>
										<WBkey wbkey='3walQW8g' />
									</p>
								</div>
								<h3 className='c-cirma-h3'>
									<WBkey wbkey='9xVaWE0f' />
								</h3>
								<h3 className='c-cirma-h3'>
									<WBkey wbkey='mvftctUk' />
								</h3>
								<h3 className='c-cirma-h3'>
									<WBkey wbkey='2xUZKKBD' />
								</h3>
								<div className='c-research-note'>
									<p className='c-note'>
										{/* También se ejecutaron proyectos como
										“Derecho Maya: La realidad de su
										práctica y aplicación” y el Programa
										Internacional de Becas de Postgrado de
										la Fundación Ford, entre otros. */}
										<WBkey wbkey='LtCgUUIC' />
									</p>
								</div>
								<div class="elementor-widget-container">
									<div class="elementor-divider">
										<span class="elementor-divider-separator">
										</span>
									</div>
								</div>
								<div className='c-note-inv'>
									<WBkey wbkey='zGGaP5DK' />
								</div>
							</div>
						</Col>
						<Col sm={12} lg={6}>
							<div className='c-research-image'>
								<div className='c-img-cont'>
									<img
										src={Cover1}
										alt='Más de 40 años de historia en Guatemala'
										className='c-img-cover'
									/>
								</div>
							</div>
						</Col>
					</Row>
				</SimpleSection>
			</Container>
		</AboutLayout>
	);
}
