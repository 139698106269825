import React from 'react';

export default function HeaderImage(props) {
	const { backgroundImage, isLoading = false } = props;
	if (isLoading) {
		return (
			<section className='c-header-image'>
				<div className='c-loader'></div>
			</section>
		);
	}
	return (
		<section
			className='c-header-image'
			style={{
				backgroundImage: `url(${backgroundImage})`,
				backgroundPosition: 'center center',
			}}
		></section>
	);
}
