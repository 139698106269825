import ManuelMatheu from '../images/site/Manuel-Matheu-150x150.jpg';
import CirmaLogo from '../images/site/cirma2.svg';

export const timeline1 = [
	{
		text: 'Francisco Calderón, c.c. Catalina Hurtado',
		year: '1549',
	},
	{
		text: 'Francisco de Godoy Guzmán, encomendero, c.c. doña Isabel Calderón',
		year: '1588-1606',
	},
	{
		text: 'Don Antonio de Godoy Guzmán, hijo de Francisco de Godoy Guzmán, da la propiedad en arrendamiento al licenciado don Jaime del Portillo y Sosa, chantre de Catedral',
		year: '1623',
	},
	{
		text: 'Doctor don Esteban de Alvarado, canónigo',
		year: '1646-1650',
	},
	{
		text: 'Don Antonio de Gálvez',
		year: '1654',
	},
	{
		text: `Gobernador don Antonio de Gálvez, regidor\nNota: La propiedad fue dividida a principios del siglo XVIII`,
		year: '1688-1679',
	},
	{
		text: 'Alférez Mayor don Thomás de Arrivillaga, c.c. doña Manuela Dighero (conocida desde entonces como "Solar de Arrivillaga"")',
		year: '1723-1733',
	},
	{
		text: 'Capitán don Joseph de Arrivillaga',
		year: '1738-1745',
	},
	{
		text: 'Herederos del Alférez Mayor don Thomás de Arrivillaga',
		year: '1757',
	},
	{
		text: 'Manuel Zárate',
		year: '1792',
	},
	{
		text: 'Manuel Zárate deja herederos a sus cuatro hijos',
		year: '1816',
	},
	{
		text: 'Gregoria Becha',
		year: '1852',
	},
	{
		text: 'Juan de Dios Poggio (conocida desde entonces como "Lo de Poggio")',
		year: '1856',
	},
	{
		text: 'Manuel Matheu (erróneamente identificada como Rancho del Marquez")',
		year: '1870',
		image: ManuelMatheu,
	},
	{
		text: 'José Saturnino Tinoco (agricultor)',
		year: '1889',
	},
	{
		text: 'Alfredo Ubico (comerciante)',
		year: '1899',
	},
	{
		text: 'Hercilia Cofiño García Salas de Matheu',
		year: '1900',
	},
	{
		text: `María Victoria Matheu Cofiño viuda de Sueiras\nMargarita Matheu Cofiño de de la Cerda\nElisa Matheu Cofiño viuda de Dalton\nMarta Matheu Cofiño de Alejos\nBlanca Matheu Cofiño de Penny\nCarlos Matheu Cofiño\n(hijos de doña Hercilia Cofiño viuda de Matheu)`,
		year: '1954',
	},
	{
		text: 'Frances Burks Atkins',
		year: '1969',
	},
	{
		text: 'Ernesto Ricardo Viteri Echeverría y Fernando José Eugenio Viteri Echeverría',
		year: '1973',
	},
	{
		text: 'Desde entonces sede de CIRMA',
		year: '1979',
		image: CirmaLogo,
	},
];

/* export const timeline2 = [
	
]; */
