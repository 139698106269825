import React, { useState, useEffect } from 'react';
import { DRUPAL_URL, DRUPAL_HEADERS } from '../utilities/globals';
import { CustomAxios } from '../utilities/util';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export default function PhotoWithCredits(props) {
	const { image, data, className } = props;
	const [imageData, setImageData] = useState(null);
	const [isLightboxOpen, setIsLightboxOpen] = useState(false);

	useEffect(() => {
		CustomAxios(
			'get',
			image,
			{},
			{ 'Content-Type': 'application/json', ...DRUPAL_HEADERS }
		).then((response) => {
			setImageData(response.success ? response.data.data[0] : null);
		});
	}, [image]);

	return (
		<div className={className}>
			{isLightboxOpen && (
				<Lightbox
					mainSrc={`${DRUPAL_URL}${imageData?.attributes?.uri?.url.replace(
						'/glifos',
						''
					)}`}
					onCloseRequest={() => setIsLightboxOpen(false)}
				/>
			)}
			<img
				src={`${DRUPAL_URL}${imageData?.attributes?.uri?.url.replace(
					'/glifos',
					''
				)}`}
				alt={data?.meta?.alt ?? 'No se pudo recuperar la imágen'}
				style={{ cursor: 'pointer' }}
				onClick={() => {
					setIsLightboxOpen(true);
				}}
			/>
			<h6>{data.meta.title}</h6>
		</div>
	);
}
