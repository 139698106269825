import React from 'react';
import NoticiasNovedadesMenu from '../components/NoticiasNovedadesMenu/NoticiasNovedadesMenu';
import BasicLayout from '../layouts/BasicLayout';

export default function NoticiasNovedadesScreen() {
	return (
		<BasicLayout>
			<NoticiasNovedadesMenu />
		</BasicLayout>
	);
}
