import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

import AboutLayout from '../../../layouts/AboutLayout';

import SimpleSection from '../../../components/SimpleSection';

import LecturaEntreRuinas from '../../../images/clubs/lectura_ninos/club_lectura_ninos2.jpg';
import { removeRightClick } from '../../../utilities/util';
//import WBkey from '../../../components/WBkey';
import { ArrowLeft } from 'react-bootstrap-icons';
import DirectContact from '../DirectContact/DirectContact';
import WBkey from '../../../components/WBkey';

export default function ChildrenReadingClubScreen(props) {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<AboutLayout
			title={
				<div className='p-0 m-0 text-center'>
					<WBkey wbkey='Kn2ixapV' />
					<br />
					<WBkey wbkey='6NRiNO9s' />
				</div>
			}
			backgroundImage='lectura_ninos'
			noMarginTop
		>
			<Container>
				<SimpleSection className={'mt-2'}>
					<Row className='justify-content-between align-items-center mb-2'>
						<Button
							href={'/clubes'}
							variant='transparent'
							className='c-btn-goback w-auto'
						>
							<div className='c-btn-content'>
								<ArrowLeft /> Regresar
							</div>
						</Button>
						{/* <div className='w-auto align-items-center'>
							<BreadcrumbComponent
								items={[
									...newsAndUpdates,
									{ url: '/eventos', title: 'Eventos' },
								]}
							/>
						</div> */}
					</Row>
					<Row>
						<Col xs={12} md={6} lg={4}>
							<div className='c-img-cont'>
								<img
									src={LecturaEntreRuinas}
									alt='Club de Lectura "Letras entre ruinas"'
									loading='lazy'
									onContextMenu={removeRightClick}
								/>
							</div>
						</Col>
						<Col xs={12} md={6} lg={8}>
							<div className='d-flex flex-column justify-content-center h-100'>
								<p
									className='mt-0 fw-600  title-font color-primary'
									style={{ fontSize: '19px' }}
									wbk='vTa_Rld1'
								>
									<WBkey wbkey='vTa_Rld1' variant='plain' />
								</p>
								<p className='mt-0 fw-500' wbk='GMJ61RAd'>
									<WBkey wbkey='GMJ61RAd' variant='plain' />
								</p>
								<p
									className='mt-0 fw-600 title-font color-primary'
									style={{ fontSize: '19px' }}
									wbk='qCp5RuyP'
								>
									<WBkey wbkey='qCp5RuyP' variant='plain' />
								</p>
								<p className='mt-0 fw-500' wbk='PCHbQ4fi'>
									<WBkey wbkey='PCHbQ4fi' variant='plain' />
								</p>
							</div>
						</Col>
					</Row>
					<Row className='justify-content-center mt-4'>
						<h2
							className='c-cirma-title text-center'
							style={{ fontSize: '24px' }}
						>
							<WBkey wbkey='qhtTZyLg' />
						</h2>
					</Row>
					<Row className='justify-content-around flex-wrap gap-4'>
						<div
							className='d-flex flex-column w-auto align-items-center'
							style={{ maxWidth: '300px' }}
						>
							<i
								aria-hidden='true'
								className='fas fa-calendar-check color-primary mb-4'
								style={{
									fontSize: '50px',
								}}
							></i>
							<h3 className='c-cirma-subtitle'>
								<WBkey wbkey='QK34iGyK' />
							</h3>
							<p className='m-0 p-0 fw-500 text-wrap-pretty text-center'>
								<WBkey wbkey='4Mb1vNGO' />
							</p>
						</div>
						<div
							className='d-flex flex-column w-auto align-items-center'
							style={{ maxWidth: '300px' }}
						>
							<i
								aria-hidden='true'
								className='fas fa-clock color-primary mb-4'
								style={{
									fontSize: '50px',
								}}
							></i>
							<h3 className='c-cirma-subtitle'>
								<WBkey wbkey='VkKcFnBT' />
							</h3>
							<p className='m-0 p-0 fw-500'>
								<WBkey wbkey='qajxEPTQ' />
							</p>
						</div>
						<div
							className='d-flex flex-column w-auto align-items-center'
							style={{ maxWidth: '300px' }}
						>
							<i
								aria-hidden='true'
								className='fas fa-house-user color-primary mb-4'
								style={{
									fontSize: '50px',
								}}
							></i>
							<h3 className='c-cirma-subtitle'>
								<WBkey wbkey='nDZBPLvO' />
							</h3>
							<p className='m-0 p-0 fw-500 text-wrap-pretty text-center'>
								<WBkey wbkey='h7fEhTIh' />
								<br />
								<WBkey wbkey='9coacvXQ' />
							</p>
						</div>
						<div
							className='d-flex flex-column w-auto align-items-center'
							style={{ maxWidth: '300px' }}
						>
							<i
								aria-hidden='true'
								className='fas fa-book-reader color-primary mb-4'
								style={{
									fontSize: '50px',
								}}
							></i>
							<h3 className='c-cirma-subtitle'>
								<WBkey wbkey='LHiYQ8LJ' />
							</h3>
							<p className='m-0 p-0 fw-500 text-wrap-pretty text-center'>
								<WBkey wbkey='TwsSaaDC' />
							</p>
						</div>
					</Row>
				</SimpleSection>
			</Container>
			<DirectContact
				subject={WBkey({ variant: 'plain', wbkey: 'DCbZwayG' })}
				message={WBkey({ variant: 'plain', wbkey: 'Ra9R3RUO' })}
			/>
		</AboutLayout>
	);
}
