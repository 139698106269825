import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { Heart } from 'react-bootstrap-icons';
import TopSection from '../components/TopSection';
import TopMenu from '../components/Navbar/Navbar';
import BigTitle from '../components/BigTitle';
import SearchField from '../components/SearchField';
import SlidingGallery from '../components/SlidingGallery';
import SimpleSection from '../components/SimpleSection';
import Subscribe from '../components/Subscribe';
import Footer from '../components/Footer/Footer';
import WBkey from '../components/WBkey';
import LoadingCard from '../components/LoadingCard';
import CIRMASede from '../images/cirma-sede2.jpg';
import AH from '../images/home-archivo.jpg';
import FG from '../images/home-fototeca.jpg';
import Bib from '../images/home-biblioteca.jpg';
import Alliances from '../components/Aalliances/Alliances';
import NewsCard from '../components/NewsCard';
import { cleanText, getDateFormat } from '../utilities/util';
import Axios from '../services/Api';

import donaciones01 from '../images/donaciones-background.jpg';
import donaciones02 from '../images/cirma-letter3.png';

import preserv from '../images/icons/conservacion2.svg';
import catalog from '../images/icons/catalogacion2.svg';
import digi from '../images/icons/digitalizacion2.svg';
import SocialSwiper from '../components/SocialSwiper';
import MessageBox from '../components/MessageBox';
import Back2TopBtn from '../components/Back2TopBtn';

document.addEventListener('DOMContentLoaded', function () {
	/* const location = useLocation();
	console.log(location) */
	window.addEventListener('scroll', function () {
		let navbar_height =
			document.getElementById('c-top-navbar').offsetHeight;
		//console.log('Scroll event',window.scrollY);
		if (window.scrollY > navbar_height + 50) {
			document.getElementById('c-top-navbar').classList.add('fixed-top');
			// add padding top to show content behind navbar
			//let navbar_height = document.querySelector('.navbar').offsetHeight;
			//document.body.style.paddingTop = navbar_height + 'px';
			document.getElementById('c-nav-bar-fixer').style.height =
				navbar_height + 'px';
			document.getElementById('navbar-logo').style.width = '70px';
			document.getElementById('navbar-logo').style.height = '55px';
			document
				.querySelectorAll(
					'#navbarScroll>form>.c-nav-btns>.c-search>svg'
				)
				.forEach((el) => {
					//console.log(el);
					el.style.color = '#831131';
					el.style.fill = '#831131';
				});
		} else {
			document
				.getElementById('c-top-navbar')
				.classList.remove('fixed-top');
			document.getElementById('c-nav-bar-fixer').style.height = 'auto';
			// remove padding top from body
			//document.body.style.paddingTop = '0';
			document.getElementById('navbar-logo').style.width = '100px';
			document.getElementById('navbar-logo').style.height = '76px';

			// Verificar si la ruta es la página principal ("/")
			if (window.location.pathname === '/') {
				document
					.querySelectorAll(
						'#navbarScroll>form>.c-nav-btns>.c-search>svg'
					)
					.forEach((el) => {
						el.style.color = 'white';
						el.style.fill = 'white';
					});
			}
		}
	});
});

export default function PrimaryScreen(props) {
	const LIBRARYURL = 'https://cirma.org.gt/opac';
	const FGURL = '/glifos/agency/GT-CIRMA-FG';
	const AHURL = '/glifos/agency/GT-CIRMA-AH';
	const [news, setNews] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');
	useEffect(() => {
		const getContentType = (type) => {
			switch (type) {
				case 'noticia':
					return 'Adquisiciones';
				case 'evento':
				case 'event':
					return 'Eventos';
				case 'boletin':
					return 'Boletines';
				case 'coleccion':
				case 'colecciones':
					return 'Colecciones';
				case 'proyecto':
					return 'Proyectos';
				case 'club':
					return 'Nuestros Clubes';
				case 'conociendo_cirma':
				case 'conociendo-cirma':
					return 'Conociendo CIRMA';
				case 'homenaje':
					return 'Homenajes';
				case 'donante_y_donacion':
				case 'voluntarios_practicantes':
					return 'Voluntarios y practicantes';
				case 'especial':
					return 'Especiales';
				case 'redes_sociales':
					return 'Redes Sociales';
				default:
					return 'Noticias & Novedades';
			}
		};
		const getBaseRoute = (type) => {
			switch (type) {
				case 'noticia':
					return 'noticia';
				case 'evento':
				case 'event':
					return 'evento';
				case 'boletin':
					return 'boletines';
				case 'coleccion':
				case 'colecciones':
					return 'colecciones';
				case 'proyecto':
					return 'proyectos';
				case 'club':
					return 'club';
				case 'conociendo_cirma':
				case 'conociendo-cirma':
					return 'conociendo_cirma';
				case 'homenaje':
					return 'homenajes';
				case 'donante_y_donacion':
				case 'voluntarios_practicantes':
					return 'voluntarios_practicantes';
				case 'especial':
					return 'Especiales';
				case 'redes_sociales':
					return 'Redes Sociales';
				default:
					return 'Noticias & Novedades';
			}
		};
		function extractContent(s) {
			var span = document.createElement('span');
			span.innerHTML = s;
			return span.textContent || span.innerText;
		}
		const fetchData = async () => {
			try {
				setLoading(true);
				setError('');
				const { data } = await Axios.GetRecentNodes();
				if (!Boolean(data?.success)) {
					throw new Error(
						data?.message ??
							'Error al obtener los contenidos recientes'
					);
				}
				/* console.log(
					'GetLastSocialContents',
					data,
				); */
				if (data.success && data?.data.length > 0) {
					const formattedData = data.data.map((item) => ({
						id: item?.nid,
						fecha_publicacion: item?.fecha,
						titulo: item?.title,
						autor: item?.author,
						uri_portada: item?.file_uri,
						body:
							extractContent(item?.body)
								?.split(' ')
								.slice(0, 15)
								.join(' ') + '...',
						subtitulo: getContentType(item?.type),
						tipo_contenido: getBaseRoute(item?.type),
					}));
					//console.log('formattedData', formattedData);
					setNews(formattedData);
				} else setNews([]);
			} catch (error) {
				console.log(error);
				setError(error);
			} finally {
				setLoading(false);
			}
		};
		fetchData();
	}, []);

	return (
		<div className='c-main-wrapper'>
			<TopSection>
				<TopMenu dark='navbar-dark' light=''></TopMenu>
				<BigTitle title={<WBkey wbkey='SYdmDFa1' />}>
					<div className='c-bt-description'>
						<WBkey wbkey='aaaaaaaa' />
					</div>
					<SearchField
						placeholder={window.wb?.getAsset('n1UhlpZx').label}
						withOption={true}
					/>
					<a href='#colecciones' className='c-welcome-scroll'>
						<div className='scroll' />
					</a>
				</BigTitle>
			</TopSection>
			{/* <WBkey wbkey='aCMZKXd7' /> */}
			<SimpleSection id='colecciones'>
				<SlidingGallery
					title={<WBkey wbkey='RNq_rVct' />}
				></SlidingGallery>
			</SimpleSection>
			<SimpleSection className='c-welcome-cirma'>
				<Container>
					<Row>
						<Col
							sm={12}
							lg={6}
							className='c-welcome-cirma-container'
						>
							<div className='c-welcome-title'>
								<WBkey wbkey='5zB3zuhc' />
							</div>
							<h1 className='c-cirma-title'>
								<WBkey wbkey='kXT7es5g' />
							</h1>
							<div className='c-divisor'>
								<div className='c-line'></div>
							</div>
							<div className='c-cirma-desc'>
								<WBkey wbkey='wMudjP_a' />
							</div>
							{/* <div className='c-cirma-btn'>
								<Button variant='outline-primary' href='/sede'>
									Conozca Más
								</Button>
							</div> */}
						</Col>
						<Col sm={12} lg={6}>
							<div className='c-img-cont'>
								<img src={CIRMASede} alt='' />
							</div>
						</Col>
					</Row>
				</Container>
			</SimpleSection>
			<SimpleSection className='c-cirma-slogan'>
				<Container>
					<Row>
						<div className='c-vertical-line'>
							<div className='c-vline'></div>
						</div>
					</Row>
					<Row>
						<div className='c-slogan-text'>
							<WBkey wbkey='eabZ4zZN' />
						</div>
					</Row>
				</Container>
			</SimpleSection>
			<SimpleSection className='c-tres-cirma'>
				<Container>
					<Row>
						<Col sm={12} md={6} lg={4} xl={3}>
							<div className='c-welcome-title'>
								<WBkey wbkey='966elaQf' />
							</div>
							<h1 className='c-cirma-title'>
								<WBkey wbkey='Fz8bnRE8' />
							</h1>
							<div className='c-divisor'>
								<div className='c-line'></div>
							</div>
							<div className='c-cirma-desc'>
								<WBkey wbkey='4erKoSSu' />
							</div>
						</Col>
						<Col sm={12} md={6} lg={4} xl={3}>
							<div className='c-img-cont c-bib'>
								<a href={LIBRARYURL}>
									<img src={Bib} alt='' />
								</a>
								<a
									href={LIBRARYURL}
									className='c-text'
									style={{ textWrap: 'balance' }}
								>
									<WBkey wbkey='G7mtLNvI' />
								</a>
							</div>
						</Col>
						<Col sm={12} md={6} lg={4} xl={3}>
							<div className='c-img-cont c-fg'>
								<a href={FGURL}>
									<img src={FG} alt='' />
								</a>
								<a href={FGURL} className='c-text'>
									<WBkey wbkey='E5r_yIny' />
								</a>
							</div>
						</Col>
						<Col sm={12} md={6} lg={4} xl={3}>
							<div className='c-img-cont c-ah'>
								<a href={AHURL}>
									<img src={AH} alt='' />
								</a>
								<a href={AHURL} className='c-text'>
									<WBkey wbkey='YB4_zYTH' />
								</a>
							</div>
						</Col>
					</Row>
				</Container>
			</SimpleSection>
			<SimpleSection className='c-cirma-news'>
				<Container>
					<Row>
						<div className='c-vertical-line'>
							<div className='c-vline'></div>
						</div>
					</Row>
					<Row>
						<h2 className='c-title'>
							<WBkey wbkey='D_KNFYq9' />
						</h2>
					</Row>
					<Row>
						<Container>
							<Row>
								{loading &&
									new Array(3)
										.fill(0)
										.map((_, index) => (
											<LoadingCard key={index} />
										))}
								{error && (
									<MessageBox severity='danger'>
										{error}
									</MessageBox>
								)}
								{news.length > 0 &&
									news.map((item, index) => (
										<NewsCard
											key={item?.id ?? index}
											image={item?.uri_portada}
											subtitle={item?.subtitulo}
											title={item?.titulo}
											date={getDateFormat(
												item?.fecha_publicacion,
												'DD de month de YYYY'
											)}
											url={`/${item?.tipo_contenido}/${
												item?.id
											}/${cleanText(item?.titulo)}`}
											text={item?.body}
											isURL={false}
											style={{
												cursor: 'pointer',
												objectFit: 'cover',
												objectPosition: 'center',
												minHeight: '200px',
											}}
										/>
									))}
							</Row>
						</Container>
					</Row>
				</Container>
			</SimpleSection>
			<SimpleSection className='c-cirma-social'>
				<SocialSwiper slidesPerView={6} spaceBetween={50} />
			</SimpleSection>
			<SimpleSection
				className='c-cirma-donations'
				backgroundimage={donaciones01}
			>
				<div className='c-overlay'> </div>
				<div className='c-letter-wrapper'>
					<div className='c-img-cont'>
						<img src={donaciones02} alt='' />
					</div>
					<div className='c-h'>
						<div className='c-heart-line'> </div>
						<div className='c-heart'>
							<Heart />
						</div>
						<div className='c-heart-line'> </div>
					</div>
					<div className='c-thanks'>
						<h2>
							<WBkey wbkey='UrzHAimj' />
						</h2>
					</div>
					<div className='c-forfree'>
						<WBkey wbkey='XvnGSMVL' />
					</div>
					<div className='c-cirma-action'>
						<Row className='c-r-nl'>
							<Col className='c-nolucra'>
								<WBkey wbkey='ZJsBN6kG' />
							</Col>
						</Row>
						<Row className='c-svs'>
							<Col className='c-s1' sm={12} md={4}>
								<div className='c-img-cont'>
									<a href='/donaciones'>
										<img src={preserv} alt='Preservación' />
									</a>
								</div>
								<h3>
									<WBkey wbkey='wtALmiie' />
								</h3>
							</Col>
							<Col className='c-s2' sm={12} md={4}>
								<div className='c-img-cont'>
									<a href='/donaciones'>
										<img src={catalog} alt='Catalogación' />
									</a>
								</div>
								<h3>
									<WBkey wbkey='kaWjzJZ1' />
								</h3>
							</Col>
							<Col className='c-s3' sm={12} md={4}>
								<div className='c-img-cont'>
									<a href='/donaciones'>
										<img src={digi} alt='Digitalización' />
									</a>
								</div>
								<h3>
									<WBkey wbkey='sxD1DoaM' />
								</h3>
							</Col>
						</Row>
					</div>

					<div className='c-donatebtn'>
						<Button variant='light' href='/donaciones'>
							<WBkey wbkey='P0cqg_2k' />
						</Button>
					</div>
				</div>
			</SimpleSection>
			<Alliances />
			<SimpleSection className='c-cirma-conectado mt-0'>
				<Container>
					<Row>
						<Col className='c-mantcont'>
							<h2>
								<WBkey wbkey='2kYIVfKT' />
							</h2>
						</Col>
					</Row>
					<Row>
						<Col className='c-desc'>
							<WBkey wbkey='lvteTzsp' />
						</Col>
					</Row>
					<Row className='c-row-form'>
						<Col className='c-col-form'>
							<Subscribe />
						</Col>
					</Row>
				</Container>
			</SimpleSection>
			<Back2TopBtn />
			<Footer />
		</div>
	);
}
