import React from 'react';
import BoletinCard from './BoletinCard';

export default function BoletinGroup(props) {
	const { year, boletines } = props;
	return (
		<div className='c-group'>
			<div className='c-group-title'>
				<h2 className='c-group-year'>{year}</h2>
			</div>
			<div>
				{boletines.map((boletin, index) => {
					return (
						<BoletinCard
							title={boletin.title}
							date={boletin.date}
							key={index}
							file={boletin.file}
							body={boletin.body}
						/>
					);
				})}
			</div>
		</div>
	);
}
