import React from 'react';
import { Col, Row } from 'react-bootstrap';
import MessageBox from '../../../components/MessageBox';
import OlderEventsCards from '../OlderEventsCard/OlderEventsCards';

export default function EventsScreenNoContent() {
	return (
		<Row>
			<Col xs={12} md={6} lg={8} xl={9}>
				<Row className='mb-3'>
					<h2 className='c-title'>Próximos eventos</h2>
				</Row>
				<Row className='gap-4 justify-content-center'>
					<MessageBox severity='info'>
						No hay eventos próximos
					</MessageBox>
				</Row>
			</Col>
			<Col xs={12} md={6} lg={4} xl={3}>
				<Row className='mb-3 justify-content-center'>
					<h2 className='c-title text-center'>Eventos pasados</h2>
				</Row>
				<Row
					className='justify-content-center'
					style={{ padding: '0 35px' }}
				>
					<OlderEventsCards />
				</Row>
			</Col>
		</Row>
	);
}
