import React, { useState } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';

import AboutLayout from '../layouts/AboutLayout';

import SimpleSection from '../components/SimpleSection';

import Lutz from '../images/founders/christopher-lutz.jpg';
import Swezey from '../images/founders/william-swezey.jpg';
import Denburg from '../images/founders/Mitchell-Denburg.jpg';
import Adams from '../images/founders/Tani-Adams.jpg';
import ModalComponent from '../components/Modal';
import WBkey from '../components/WBkey';

const founders = {
	LUTZ: {
		title: 'BRowphIB',
		description: '_cBYz_c3',
	},
	SWEZEY: {
		title: 'waFYcBWY',
		description: 'bYroOeOi',
	},
	DENBURG: {
		title: 'tu60bvlB',
		description: 'NWyGq_mw',
	},
	ADAMS: {
		title: 'uhKTiqSy',
		description: '1JkNhmzi',
	},
};

export default function FoundersScreen(props) {
	const [showModal, setShowModal] = useState(false);
	const [founder, setFounder] = useState({
		title: '',
		description: '',
	});

	const activateModal = (selectedFounder) => {
		//console.log(selectedFounder);
		setFounder({
			title: WBkey({
				wbkey: selectedFounder.title,
				variant: 'plain',
			}),
			keys: {
				title: selectedFounder.title,
				description: selectedFounder.description,
			},
			description: WBkey({
				wbkey: selectedFounder.description,
				variant: 'plain',
			}),
		});
		setShowModal(true);
	};
	return (
		<AboutLayout
			title='Fundadores'
			subtitle='Centro de Investigaciones Regionales de Mesoamérica (CIRMA)'
			backgroundImage='fundadores'
		>
			<SimpleSection className='c-founders-cirma'>
				<Container>
					<Row>
						<Col sm={12} lg={4}>
							<div className='c-founders-info'>
								<WBkey wbkey='zKvXwCt6' />
							</div>
						</Col>
						<Col sm={12} lg={4}>
							<div className='c-img-founder'>
								<div className='c-img-cont c-img-background'>
									<img src={Lutz} alt='Christopher H. Lutz' />
								</div>
								<div className='c-img-info'>
									<div
										className='c-img-title'
										onClick={() =>
											activateModal(founders.LUTZ)
										}
									>
										<WBkey wbkey='pFHbjopg' />
									</div>
									<div className='c-img-description'>
										<WBkey wbkey='_6iM6KJA' />
									</div>
									<div className='c-biography-button'>
										<Button
											variant='outline-light'
											onClick={() =>
												activateModal(founders.LUTZ)
											}
										>
											<WBkey wbkey='BOJWfh7w' />
										</Button>
									</div>
								</div>
							</div>
						</Col>
						<Col sm={12} lg={4}>
							<div className='c-img-founder'>
								<div className='c-img-cont c-img-background'>
									<img src={Swezey} alt='William R. Swezey' />
								</div>
								<div className='c-img-info'>
									<div
										className='c-img-title'
										onClick={() =>
											activateModal(founders.SWEZEY)
										}
									>
										<WBkey wbkey='mZYuzm3H' />
									</div>
									<div className='c-img-description'>
										<WBkey wbkey='qwR5tuN4' />
									</div>
									<div className='c-biography-button'>
										<Button
											variant='outline-light'
											onClick={() =>
												activateModal(founders.SWEZEY)
											}
										>
											<WBkey wbkey='BOJWfh7w' />
										</Button>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</SimpleSection>
			<SimpleSection className='c-founders-cirma'>
				<Container>
					<Row>
						<Col sm={12} lg={3}>
							<div className='c-founders-info'>
								<WBkey wbkey='NbdK5jbi' />
							</div>
						</Col>
						<Col sm={12} lg={3}>
							<div className='c-img-founder'>
								<div className='c-img-cont c-img-background'>
									<img src={Denburg} alt='Mitchell Denburg' />
								</div>
								<div className='c-img-info'>
									<div
										className='c-img-title'
										onClick={() =>
											activateModal(founders.DENBURG)
										}
									>
										<WBkey wbkey='DoIvLaMY' />
									</div>
									<div className='c-img-description'>
										<WBkey wbkey='HBYSMito' />
									</div>
									<div className='c-biography-button'>
										<Button
											variant='outline-light'
											onClick={() =>
												activateModal(founders.DENBURG)
											}
										>
											<WBkey wbkey='BOJWfh7w' />
										</Button>
									</div>
								</div>
							</div>
						</Col>
						<Col sm={12} lg={3}>
							<div className='c-founders-info'>
								<WBkey wbkey='FsL9jamC' />
							</div>
						</Col>
						<Col sm={12} lg={3}>
							<div className='c-img-founder'>
								<div className='c-img-cont c-img-background'>
									<img src={Adams} alt='Tani Adams' />
								</div>
								<div className='c-img-info'>
									<div
										className='c-img-title'
										onClick={() =>
											activateModal(founders.ADAMS)
										}
									>
										<WBkey wbkey='o0noABcF' />
									</div>
									<div className='c-img-description'>
										<WBkey wbkey='liimyHrg' />
									</div>
									<div className='c-biography-button'>
										<Button
											variant='outline-light'
											onClick={() =>
												activateModal(founders.ADAMS)
											}
										>
											<WBkey wbkey='BOJWfh7w' />
										</Button>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</SimpleSection>
			<ModalComponent
				title={founder.title}
				content={founder.description}
				showModal={showModal}
				setShowModal={setShowModal}
				keys={founder.keys}
			/>
		</AboutLayout>
	);
}
