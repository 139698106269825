import React from 'react';
import { Card, Placeholder } from 'react-bootstrap';

export default function EventCardLoading() {
	return (
		<Card style={{ width: '18.75rem', height: '31.25rem' }} className='p-0'>
			<Placeholder as={Card.Text} animation='glow' className='m-0 p-0'>
				<Placeholder xs={12} size='lg' style={{ height: '240px' }} />
			</Placeholder>
			<Card.Body className='m-0 p-2rem'>
				<Placeholder
					as={Card.Text}
					animation='glow'
					className='d-flex flex-row justify-content-center align-items-center'
				>
					<Placeholder
						xs={2}
						size='lg'
						style={{ aspectRatio: '1/1' }}
					/>
				</Placeholder>
				<Placeholder
					as={Card.Text}
					animation='glow'
					className='d-flex flex-row justify-content-center align-items-center flex-wrap gap-2'
				>
					<Placeholder xs={7} /> <Placeholder xs={4} />{' '}
					<Placeholder xs={4} /> <Placeholder xs={6} />{' '}
				</Placeholder>
				<Placeholder
					as={Card.Text}
					animation='glow'
					className='d-flex flex-row justify-content-center align-items-center flex-wrap gap-2'
				>
					<Placeholder xs={8} />
				</Placeholder>
				<div className='m-0 p-0 d-flex flex-row justify-content-center align-items-center flex-wrap'>
					<Placeholder.Button variant='primary' xs={8} />
				</div>
			</Card.Body>
		</Card>
	);
}
