import React, { useMemo } from 'react';
import { NavDropdown } from 'react-bootstrap';

import Biblioteca from '../../images/biblioteca-navbar.jpg';
import Fototeca from '../../images/fototeca-navbar.jpg';
import Archivo from '../../images/archivo-navbar.jpg';

const dumpData = {
	biblioteca: {
		title: 'Biblioteca de Ciencias Sociales',
		description:
			'En 1978 los fundadores unieron sus bibliotecas personales a las que luego se incorporaron las de otros académicos. Hoy en día se ha constituido en una destacada colección enfocada en las ciencias sociales, libros antiguos desde el siglo XVI y literatura guatemalteca.',
		image: Biblioteca,
	},
	fototeca: {
		title: 'Fototeca Guatemala',
		description:
			'Fundada en 1979, representa un esfuerzo significativo para preservar la memoria visual de Guatemala. Sus colecciones retratan los diferentes grupos étnicos, sociales y políticos desde 1850 hasta el presente y proveen un rico registro de la evolución histórica del país.',
		image: Fototeca,
	},
	archivo: {
		title: 'Archivo <br/> Histórico',
		description:
			'Creado en 1997, juega un papel crítico en la recuperación de la memoria histórica de Guatemala. Sus colecciones, algunas reconocidas como patrimonio cultural de la nación, datan del siglo XVI a la fecha, haciendo énfasis en la época contemporánea a partir de 1944.',
		image: Archivo,
	},
};

export default function DropdownItemContent(props) {
	const { item, path, setSelectedDropdown } = props;
	const width = useMemo(() => {
		if (typeof window !== 'undefined') {
			return window.innerWidth;
		}
	}, []);
	return (
		<div
			className='c-dropdown-item-cont'
			onMouseLeave={() => width > 992 && setSelectedDropdown(null)}
			onClick={() => (window.location = path)}
			style={{ cursor: 'pointer' }}
		>
			<div className='c-dropdown-item'>
				<div className='c-dropdown-item-title'>
					<NavDropdown.Item href={path}>
						<h2
							className='c-dropdown-title'
							dangerouslySetInnerHTML={{
								__html: dumpData[item].title,
							}}
						/>
					</NavDropdown.Item>
					<div className='horizontal-line'></div>
				</div>
			</div>
			<div>
				<div className='c-dropdown-item'>
					<p className='c-dropdown-text'>
						{dumpData[item].description}
					</p>
				</div>
			</div>
			<div className='c-dropdown-item'>
				<NavDropdown.Item href={path}>
					<div className='c-img-cont c-img-si'>
						<img
							src={dumpData[item].image}
							alt={dumpData[item].title}
						/>
					</div>
				</NavDropdown.Item>
			</div>
		</div>
	);
}
