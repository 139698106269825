import React, { useState } from 'react';
import { Col, Row, Button, Container } from 'react-bootstrap';

import AboutLayout from '../layouts/AboutLayout';
import SimpleSection from '../components/SimpleSection';

import { HeartFill } from 'react-bootstrap-icons';
import DonateModal from '../components/DonateModal';

import Donaciones from '../images/donations/donaciones2.jpg';
import Donaciones2 from '../images/donations/donaciones3.jpg';
import Catalogacion from '../images/donations/catalogacion.jpg';
import Conservacion from '../images/donations/conservacion.jpg';
import Digitalizacion from '../images/donations/digitalizacion.jpg';
import WBkey from '../components/WBkey';

export default function Donation() {
	const [showModal, setShowModal] = useState(false);
	return (
		<AboutLayout
			title='Donaciones'
			subtitle='Gracias por apoyar esta causa'
			backgroundImage='donaciones'
			donation
		>
			<Container>
				<SimpleSection className='c-donation-cirma'>
					<Row>
						<Col xs={12} lg={4}>
							<div className='c-img-cont'>
								<img src={Donaciones} alt='Donaciones' />
							</div>
						</Col>
						<Col xs={12} lg={8}>
							<div className='c-donation-info'>
								<WBkey wbkey='H3g5TMcl' />
								<Row className='c-donation-title-center'>
									<h4>
										<WBkey wbkey='IXVSFv9D' />
									</h4>
								</Row>
								<Row className='c-donation-button'>
									<Button
										variant='outline-primary'
										className='c-donation-button w-auto'
										onClick={() => {
											setShowModal(true);
										}}
									>
										<HeartFill /> <WBkey wbkey='4ycA0pkx' />
									</Button>
								</Row>
							</div>
						</Col>
					</Row>
				</SimpleSection>
				<SimpleSection className='c-donation-cirma'>
					<Row className='c-donation-title-center'>
						<h2>
							<WBkey wbkey='lkcZzbwN' />
						</h2>
					</Row>
					<Row>
						<Col xs={12} lg={4} className='my-2 h-auto'>
							<Row className='h-auto h-lg-50 justify-content-center align-items-center'>
								<div className='c-img-cont'>
									<img src={Catalogacion} alt='Donaciones' />
								</div>
							</Row>
							<br />
							<Row className='h-auto h-lg-50 justify-content-center align-items-center'>
								<div className='c-donation-title-center'>
									<h3>
										<WBkey wbkey='5afVyLEq' />
									</h3>
								</div>
								<div className='c-donation-info-center'>
									<WBkey wbkey='MUkVmNna' />
								</div>
							</Row>
						</Col>
						<Col xs={12} lg={4} className='my-2 h-auto'>
							<Row className='h-auto h-lg-50 justify-content-center align-items-center'>
								<div className='c-donation-title-center'>
									<h3>
										<WBkey wbkey='lhflQAXG' />
									</h3>
								</div>
								<div className='c-donation-info-center'>
									<WBkey wbkey='ReGTtB7I' />
								</div>
							</Row>
							<Row className='h-auto h-lg-50 justify-content-center align-items-center'>
								<div className='c-img-cont'>
									<img
										src={Conservacion}
										alt='Conservación'
									/>
								</div>
							</Row>
						</Col>
						<Col xs={12} lg={4} className='my-2 h-auto'>
							<Row className='h-auto h-lg-50 justify-content-center align-items-center'>
								<div className='c-img-cont'>
									<img
										src={Digitalizacion}
										alt='Digitalizacion'
									/>
								</div>
							</Row>
							<br />
							<Row className='h-auto h-lg-50 justify-content-center align-items-center'>
								<div className='c-donation-title-center'>
									<h3>
										<WBkey wbkey='ugkauZyF' />
									</h3>
								</div>
								<div className='c-donation-info-center'>
									<WBkey wbkey='cjLZckBD' />
								</div>
							</Row>
						</Col>
					</Row>
				</SimpleSection>
				<SimpleSection className='c-donation-cirma'>
					<Row>
						<Col xs={12} lg={8}>
							<Row className='c-donation-title-center'>
								<h2>
									<WBkey wbkey='7XzYpS_S' />
								</h2>
							</Row>
							<Row className='c-donation-title-center'>
								<h5>
									<WBkey wbkey='EqpYzy0k' />
								</h5>
							</Row>
							<div className='c-donation-info'>
								<WBkey wbkey='3HzSlwAB' />
								<Row className='c-donation-button my-4'>
									<Button
										variant='outline-primary'
										className='c-donation-button w-auto'
										onClick={() => {
											setShowModal(true);
										}}
									>
										<HeartFill /> <WBkey wbkey='4ycA0pkx' />
									</Button>
								</Row>
							</div>
						</Col>
						<Col xs={12} lg={4}>
							<div className='c-img-cont'>
								<img src={Donaciones2} alt='Donaciones' />
							</div>
						</Col>
					</Row>
				</SimpleSection>
			</Container>
			<DonateModal showModal={showModal} setShowModal={setShowModal} />
		</AboutLayout>
	);
}
