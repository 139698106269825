import React, { useEffect, useState /* , { useEffect }  */ } from 'react';
/* import EventCard from '../components/EventCard'; */
import BasicLayout from '../../layouts/BasicLayout';
import SimpleSection from '../../components/SimpleSection';
import { Button, Container, Row } from 'react-bootstrap';
import { ArrowLeft } from 'react-bootstrap-icons';
import EventsScreenLoading from './EventsScreenLoading/EventsScreenLoading';
import EventsScreenError from './EventsScreenError/EventsScreenError';

import EventsScreenNoContent from './EventsScreenNoContent/EventsScreenNoContent';
import EventsScreenContent from './EventsScreenContent/EventsScreenContent';
import BreadcrumbComponent from '../../components/Breadcrumb/Breadcrumb';
import { newsAndUpdates } from '../../data/breadcrumbsData';
import Axios from '../../services/Api';

export default function EventsScreen() {
	const [events, setEvents] = useState({
		isLoading: true,
		isError: false,
		error: '',
		payload: [],
	});

	useEffect(() => {
		const fetchData = async () => {
			try {
				setEvents({
					isLoading: true,
					isError: false,
					error: '',
					payload: [],
				});
				const { data } = await Axios.GetRecentEvents();
				if (!Boolean(data?.success)) {
					throw new Error(
						data?.message ?? 'Error al obtener los eventos'
					);
				}
				setEvents((prevData) => ({
					...prevData,
					payload: data?.data ?? [],
				}));
			} catch (error) {
				setEvents({
					isLoading: false,
					isError: true,
					error: error?.message ?? 'Error al obtener los eventos',
					payload: [],
				});
			} finally {
				setEvents((prevData) => ({
					...prevData,
					isLoading: false,
				}));
			}
		};
		fetchData();
	}, []);

	return (
		<BasicLayout>
			<SimpleSection>
				<Container className='c-news-updates'>
					<Row className='justify-content-between align-items-center mb-4'>
						<Button
							href={'/noticias-y-novedades'}
							variant='transparent'
							className='c-btn-goback w-auto'
						>
							<div className='c-btn-content'>
								<ArrowLeft /> Regresar
							</div>
						</Button>
						<div className='w-auto align-items-center'>
							<BreadcrumbComponent
								items={[
									...newsAndUpdates,
									{ url: '/eventos', title: 'Eventos' },
								]}
							/>
						</div>
					</Row>
					{events.isLoading && <EventsScreenLoading />}
					{!events.isLoading && events.isError && (
						<EventsScreenError error={events.error} />
					)}
					{!events.isLoading &&
						!events.error &&
						events.payload.length === 0 && (
							<EventsScreenNoContent />
						)}
					{!events.isLoading &&
						!events.error &&
						events.payload.length > 0 && (
							<EventsScreenContent events={events.payload} />
						)}
				</Container>
			</SimpleSection>
		</BasicLayout>
	);
}
