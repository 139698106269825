import React from "react";

export default function BigTitle(props){
    const {children, title} = props;
    return <div className="c-big-title">
        <div className="c-bt-cont">
            <div className="c-title">
                {title}
            </div>
            <div className="c-separator-cont">
                <div className="c-bar"></div>
            </div>
            {children}
        </div>
    </div>;
}