import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import AboutLayout from '../layouts/AboutLayout';

import SimpleSection from '../components/SimpleSection';

import DP from '../images/acknowledgment/diegodeporres-1.jpg';
import MCD from '../images/acknowledgment/ministerio-de-cultura-y-deportes.jpg';
import AGH from '../images/acknowledgment/academia-de-geografia-e-historia-1.jpg';
import AMCV from '../images/acknowledgment/alcaldia-municipal-de-ciudad-vieja.jpg';
import AGC from '../images/acknowledgment/Archivo-general-de-centroamerica-1.jpg';
import CRAG from '../images/acknowledgment/el-club-rotario-de-la-antigua-guatemala.jpg';
import EJ1 from '../images/acknowledgment/embajador-de-japon01.jpg';
import EJ2 from '../images/acknowledgment/embajador-de-japon.jpg';
import MUNI from '../images/acknowledgment/MUNI.jpg';
import OPC from '../images/acknowledgment/orden-patrimonio-cultural.jpg';
import OCV from '../images/acknowledgment/orden-de-los-cinco-volanes2-1.jpg';
import RL from '../images/acknowledgment/rafael-landivar2-1.jpg';
import RUSAC from '../images/acknowledgment/reconocimiento-usac-1024x786.jpg';
import USACA from '../images/acknowledgment/usac-aquino.jpg';
import { removeRightClick } from '../utilities/util';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Watcher from '../components/Watcher';
import Swiperr from '../components/Swiper';

import WBkey from '../components/WBkey';

export default function AcknowledgmentsScreen(props) {
	const [showWatcher, setShowWatcher] = useState(false);
	const [link, setLink] = useState('');
	const [horizontal, setHorizontal] = useState(false);
	const [vertical, setVertical] = useState(false);
	const Images = [EJ1, EJ2];
	const Alts = ['Monografia', 'Monografia'];

	return (
		<AboutLayout
			title='Reconocimientos'
			subtitle='Otorgados a Cirma'
			backgroundImage='reconocimientos'
		>
			<Container>
				<SimpleSection className='c-acknowledgment-cirma'>
					<Row>
						<Col sm={12} md={6} lg={3}>
							<div className='c-acknowledgment-cont'>
								<div
									className='c-img-cont'
									onClick={() => {
										setShowWatcher(true);
										setLink(DP);
										setHorizontal(false);
										setVertical(true);
									}}
								>
									<img
										src={DP}
										alt='Monografia'
										onContextMenu={removeRightClick}
									/>
								</div>
								<h5 className='c-acknowledgment-title'>
									<WBkey wbkey='ShxYIRbl' />
								</h5>
							</div>
						</Col>
						<Col sm={12} md={6} lg={3}>
							<div className='c-acknowledgment-cont'>
								<div
									className='c-img-cont'
									onClick={() => {
										setShowWatcher(true);
										setLink(OCV);
										setHorizontal(false);
										setVertical(true);
									}}
								>
									<img
										src={OCV}
										alt='Monografia'
										onContextMenu={removeRightClick}
									/>
								</div>
								<h5 className='c-acknowledgment-title'>
									<WBkey wbkey='c0MoyqbM' />
								</h5>
							</div>
						</Col>
						<Col sm={12} md={6} lg={3}>
							<div className='c-acknowledgment-cont'>
								<div
									className='c-img-cont'
									onClick={() => {
										setShowWatcher(true);
										setLink(OPC);
										setHorizontal(false);
										setVertical(true);
									}}
								>
									<img
										src={OPC}
										alt='Monografia'
										onContextMenu={removeRightClick}
									/>
								</div>
								<h5 className='c-acknowledgment-title'>
									<WBkey wbkey='OreYF_N1' />
								</h5>
							</div>
						</Col>
						<Col sm={12} md={6} lg={3}>
							<div className='c-acknowledgment-cont'>
								<div
									className='c-img-cont'
									onClick={() => {
										setShowWatcher(true);
										setLink(RL);
										setHorizontal(false);
										setVertical(true);
									}}
								>
									<img
										src={RL}
										alt='Monografia'
										onContextMenu={removeRightClick}
									/>
								</div>
								<h5 className='c-acknowledgment-title'>
									<WBkey wbkey='LZCa3YUH' />
								</h5>
							</div>
						</Col>
					</Row>
				</SimpleSection>
				<SimpleSection className='c-acknowledgment-cirma'>
					<Row>
						<h3 className='c-cirma-h3'>
							<WBkey wbkey='5TYmT7Ji' />
						</h3>
					</Row>
				</SimpleSection>
				<SimpleSection className='c-acknowledgment-cirma'>
					<Row>
						<Col sm={12} md={6} lg>
							<div className='c-acknowledgment-cont'>
								<div
									className='c-img-cont'
									onClick={() => {
										setShowWatcher(true);
										setLink(AGC);
										setHorizontal(false);
										setVertical(true);
									}}
								>
									<img
										src={AGC}
										alt='Monografia'
										onContextMenu={removeRightClick}
									/>
								</div>
							</div>
						</Col>
						<Col sm={12} md={6} lg>
							<div className='c-acknowledgment-cont'>
								<div
									className='c-img-cont'
									onClick={() => {
										setShowWatcher(true);
										setLink(CRAG);
										setHorizontal(false);
										setVertical(true);
									}}
								>
									<img
										src={CRAG}
										alt='Monografia'
										onContextMenu={removeRightClick}
									/>
								</div>
							</div>
						</Col>
						<Col sm={12} md={6} lg>
							<div className='c-acknowledgment-cont'>
								<div
									className='c-img-cont'
									onClick={() => {
										setShowWatcher(true);
										setLink(AMCV);
										setHorizontal(false);
										setVertical(true);
									}}
								>
									<img
										src={AMCV}
										alt='Monografia'
										onContextMenu={removeRightClick}
									/>
								</div>
							</div>
						</Col>
						<Col sm={12} md={6} lg>
							<div className='c-acknowledgment-cont'>
								<div
									className='c-img-cont'
									onClick={() => {
										setShowWatcher(true);
										setLink(USACA);
										setHorizontal(false);
										setVertical(true);
									}}
								>
									<img
										src={USACA}
										alt='Monografia'
										onContextMenu={removeRightClick}
									/>
								</div>
							</div>
						</Col>
						<Col sm={12} md={6} lg>
							<div className='c-acknowledgment-cont'>
								<div
									className='c-img-cont'
									onClick={() => {
										setShowWatcher(true);
										setLink(MUNI);
										setHorizontal(false);
										setVertical(true);
									}}
								>
									<img
										src={MUNI}
										alt='Monografia'
										onContextMenu={removeRightClick}
									/>
								</div>
							</div>
						</Col>
					</Row>
				</SimpleSection>
				<SimpleSection className='c-acknowledgment-cirma'>
					<Row>
						<Col sm={12} md={6} lg={3}>
							<div className='c-acknowledgment-cont'>
								<div
									className='c-img-cont'
									onClick={() => {
										setShowWatcher(true);
										setLink(MCD);
										setHorizontal(true);
										setVertical(false);
									}}
								>
									<img
										src={MCD}
										alt='Monografia'
										onContextMenu={removeRightClick}
									/>
								</div>
							</div>
						</Col>
						<Col sm={12} md={6} lg={3}>
							<div className='c-acknowledgment-cont'>
								<div className='c-swiper-cont'>
									<Swiperr
										Images={Images}
										Alts={Alts}
										slidesPerView={1}
										spaceBetween={10}
										Ack
										setShowWatcher={setShowWatcher}
										setLink={setLink}
									></Swiperr>
								</div>
							</div>
						</Col>
						<Col sm={12} md={6} lg={3}>
							<div className='c-acknowledgment-cont'>
								<div
									className='c-img-cont'
									onClick={() => {
										setShowWatcher(true);
										setLink(AGH);
										setHorizontal(true);
										setVertical(false);
									}}
								>
									<img
										src={AGH}
										alt='Monografia'
										onContextMenu={removeRightClick}
									/>
								</div>
							</div>
						</Col>
						<Col sm={12} md={6} lg={3}>
							<div className='c-acknowledgment-cont'>
								<div
									className='c-img-cont'
									onClick={() => {
										setShowWatcher(true);
										setLink(RUSAC);
										setHorizontal(true);
										setVertical(false);
									}}
								>
									<img
										src={RUSAC}
										alt='Monografia'
										onContextMenu={removeRightClick}
									/>
								</div>
							</div>
						</Col>
					</Row>
				</SimpleSection>
			</Container>
			<Watcher
				link={link}
				show={showWatcher}
				picture
				setShowWatcher={setShowWatcher}
				horizontal={horizontal}
				vertical={vertical}
			/>
		</AboutLayout>
	);
}
