import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import SimpleSection from '../components/SimpleSection';
import SearchField from '../components/SearchField';
import TopMenu from '../components/Navbar/Navbar';
//import Footer from '../components/Footer';
import Footer from '../components/Footer/Footer';
import WBkey from '../components/WBkey';
import Subscribe from '../components/Subscribe';

export default function NotFoundScreen(props) {
	return (
		<div className='c-main-wrapper'>
			<TopMenu background='white' />
			<Container>
				<SimpleSection className='c-notfound-cirma'>
					<Row>
						<Col sm={12}>
							<div className='c-notfound'>
								<h2 className='c-cirma-title'>
									Parece que esta página no existe.
								</h2>
								<h3 className='c-cirma-subtitle'>
									Parece que el enlace que apuntaba aquí no
									sirve. ¿Quieres probar con una búsqueda?
								</h3>
								<div className='c-searchfiel'>
									<SearchField placeholder={'Search...'} withOption={true}/>
								</div>
							</div>
						</Col>
					</Row>
				</SimpleSection>
			</Container>
			<SimpleSection className='c-cirma-conectado'>
				<Container>
					<Row>
						<Col className='c-mantcont'>
							<h2>
								<WBkey wbkey='2kYIVfKT' />
							</h2>
						</Col>
					</Row>
					<Row>
						<Col className='c-desc'>
							<WBkey wbkey='lvteTzsp' />
						</Col>
					</Row>
					<Row className='c-row-form'>
						<Col className='c-col-form'>
							<Subscribe />
						</Col>
					</Row>
				</Container>
			</SimpleSection>
			<Footer />
		</div>
	);
}
