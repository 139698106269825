import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

import AboutLayout from '../layouts/AboutLayout';

import SimpleSection from '../components/SimpleSection';

import Biblioteca from '../images/thumbnail/thumbnail-biblioteca.jpg';
import Fototeca from '../images/thumbnail/thumbnail-fg.jpg';
import Archivos from '../images/thumbnail/thumbnail-archivo.jpg';
import Cover1 from '../images/cirma-sede2.jpg';
import { PlayCircle } from 'react-bootstrap-icons';
import Watcher from '../components/Watcher';
import WBkey from '../components/WBkey';

export default function VideosScreen(props) {
	/* TODO, Icono de play y mostrar video */
	const [showWatcher, setShowWatcher] = useState(false);
	const [link, setLink] = useState('');
	return (
		<AboutLayout
			title='Videos informativos'
			subtitle='Conozca la labor de cirma'
			backgroundImage='videos'
		>
			<Container>
				<SimpleSection className='c-videos-cirma'>
					<Row>
						<Col xs={12} sm={12} md={6}>
							<div className='c-videos-info'>
								<h2 className='c-videos-title'>
									<WBkey wbkey='fWFFAp4z' />
									<div className='c-divisor'>
										<div className='c-line'></div>
									</div>
								</h2>
								<div className='c-videos-note'>
									<WBkey wbkey='phpCtDAd' />
								</div>
								<div className='c-videos-disclaimer'>
									<WBkey wbkey='x1Ss31ls' />
								</div>
							</div>
						</Col>
						<Col xs={12} sm={12} md={6}>
							<div className='c-videos-video-note'>
								<div className='c-videos-video'>
									<Button
										className='c-video'
										style={{
											backgroundImage: `url(${Cover1})`,
											backgroundSize: 'contain',
											backgroundRepeat: 'no-repeat',
											backgroundPosition: 'center',
											aspectRatio: '16/9',
											backgroundColor: 'transparent',
											border: 'none',
										}}
										onClick={() => {
											setShowWatcher(true);
											setLink(
												'https://www.youtube.com/embed/8DWCxwLiNgM'
											);
										}}
									>
										<div className='c-play'>
											<PlayCircle
												style={{
													color: 'white',
													width: '30%',
													height: '30%',
												}}
											/>
										</div>
									</Button>
								</div>
								<div className='c-video-note'>
									<WBkey wbkey='x09H8tI4' />
								</div>
							</div>
						</Col>
					</Row>
				</SimpleSection>
				<SimpleSection className='c-videos-cirma'>
					<Row>
						<Col xs={12} sm={6} lg={4}>
							<div className='c-video-note'>
								<Button
									className='c-video'
									style={{
										backgroundImage: `url(${Biblioteca})`,
										backgroundSize: 'contain',
										backgroundRepeat: 'no-repeat',
										backgroundPosition: 'center',
										aspectRatio: '16/9',
										backgroundColor: 'transparent',
										border: 'none',
									}}
									onClick={() => {
										setShowWatcher(true);
										//console.log(showWatcher)
										setLink(
											'https://www.youtube.com/embed/MZdttOt40Go'
										);
									}}
								>
									<div className='c-play'>
										<PlayCircle
											style={{
												color: 'white',
												width: '30%',
												height: '30%',
											}}
										/>
									</div>
								</Button>
								<div className='c-note'>
									<WBkey wbkey='iTGwfcta' />
								</div>
							</div>
						</Col>
						<Col xs={12} sm={6} lg={4}>
							<div className='c-video-note'>
								<Button
									className='c-video'
									style={{
										backgroundImage: `url(${Fototeca})`,
										backgroundSize: 'contain',
										backgroundRepeat: 'no-repeat',
										backgroundPosition: 'center',
										aspectRatio: '16/9',
										backgroundColor: 'transparent',
										border: 'none',
									}}
									onClick={() => {
										setShowWatcher(true);
										setLink(
											'https://www.youtube.com/embed/464F0WZG3F8'
										);
									}}
								>
									<div className='c-play'>
										<PlayCircle
											style={{
												color: 'white',
												width: '30%',
												height: '30%',
											}}
										/>
									</div>
								</Button>
								<div className='c-note'>
									<WBkey wbkey='jGQ1cKtp' />
								</div>
							</div>
						</Col>
						<Col sm={13} lg={4}>
							<div className='c-video-note'>
								<Button
									className='c-video'
									style={{
										backgroundImage: `url(${Archivos})`,
										backgroundSize: 'contain',
										backgroundRepeat: 'no-repeat',
										backgroundPosition: 'center',
										aspectRatio: '16/9',
										backgroundColor: 'transparent',
										border: 'none',
									}}
									onClick={() => {
										setShowWatcher(true);
										setLink(
											'https://www.youtube.com/embed/vidtyyLiaIs'
										);
									}}
								>
									<div className='c-play'>
										<PlayCircle
											style={{
												color: 'white',
												width: '30%',
												height: '30%',
											}}
										/>
									</div>
								</Button>
								<div className='c-note'>
									<WBkey wbkey='5q9IZt0l' />
								</div>
							</div>
						</Col>
					</Row>
				</SimpleSection>
			</Container>
			<Watcher
				link={link}
				show={showWatcher}
				youtube
				setShowWatcher={setShowWatcher}
			/>
		</AboutLayout>
	);
}
