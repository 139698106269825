import React from 'react';
import { Row } from 'react-bootstrap';
import MessageBox from '../../../components/MessageBox';

export default function OurClubsScreenNoContent() {
	return (
		<Row className='gap-4 justify-content-center'>
			<MessageBox severity='info'>
				No hay noticias de eventos actuales
			</MessageBox>
		</Row>
	);
}
