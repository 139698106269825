import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
//import BoletinCard from '../components/BoletinCard'
import BoletinGroup from '../components/BoletinGroup';
import SimpleSection from '../components/SimpleSection';
import AboutLayout from '../layouts/AboutLayout';
import Axios from '../services/Api';

export default function BoletinesScreen() {
	const [boletines, setBoletines] = useState({
		isLoading: false,
		isError: false,
		message: '',
		payload: [],
	});

	useEffect(() => {
		const fetchData = async () => {
			try {
				setBoletines({
					isLoading: true,
					isError: false,
					error: '',
					payload: [],
				});
				const { data } = await Axios.GetBoletines();
				console.log(!Boolean(data?.success));
				if (!Boolean(data?.success)) {
					throw new Error(
						data?.message ?? 'Error al obtener los boletines'
					);
				}
				let boletines = data.data.map((item) => ({
					year: new Date(item.fecha_publicacion).getUTCFullYear(),
					id: item.nid,
					uuid: item.uuid,
					title: item.title,
					date: item.fecha_publicacion,
					file: item.file_id,
				}));
				boletines = boletines.reduce((acc, item) => {
					const year = item.year;
					const index = acc.findIndex((item) => item?.year === year);
					if (index === -1) {
						acc.push({
							year: year,
							boletines: [item],
						});
					} else {
						acc[index].boletines.push(item);
					}
					return acc;
				}, []);
				console.log(boletines);
				setBoletines((prevData) => ({
					...prevData,
					payload: boletines ?? [],
				}));
			} catch (error) {
				setBoletines({
					isLoading: false,
					isError: true,
					error: error?.message ?? 'Error al obtener los boletines',
					payload: [],
				});
			} finally {
				setBoletines((prevData) => ({
					...prevData,
					isLoading: false,
				}));
			}
		};
		fetchData();
		return () => {
			setBoletines({
				isLoading: false,
				isError: false,
				message: '',
				payload: [],
			});
		};
	}, []);

	return (
		<AboutLayout title='Bitácora de boletines' backgroundImage='boletin'>
			<SimpleSection>
				<Container>
					<Row>
						{boletines.payload.map((boletin, index) => {
							return (
								<Col
									md={12}
									lg={6}
									key={`boletines-${index}-${boletin.year}`}
								>
									<BoletinGroup
										year={boletin.year}
										boletines={boletin.boletines}
									/>
								</Col>
							);
						})}
					</Row>
				</Container>
			</SimpleSection>
		</AboutLayout>
	);
}
