import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

import AboutLayout from '../../../layouts/AboutLayout';

import SimpleSection from '../../../components/SimpleSection';

import LecturaEntreRuinas from '../../../images/clubs/lectura_entre_ruinas/club_de_lectura_cirma.jpg';
import { removeRightClick } from '../../../utilities/util';
//import WBkey from '../../../components/WBkey';
import { ArrowLeft } from 'react-bootstrap-icons';
import DirectContact from '../DirectContact/DirectContact';
import WBkey from '../../../components/WBkey';

export default function ReadingClubScreen(props) {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<AboutLayout
			title={
				<div className='p-0 m-0 text-center'>
					<WBkey wbkey='Hn4FOGDo' />
					<br />
					<WBkey wbkey='2HlkBjzx' />
				</div>
			}
			backgroundImage='letras_entre_ruinas'
			noMarginTop
		>
			<Container>
				<SimpleSection className={'mt-2'}>
					<Row className='justify-content-between align-items-center mb-2'>
						<Button
							href={'/clubes'}
							variant='transparent'
							className='c-btn-goback w-auto'
						>
							<div className='c-btn-content'>
								<ArrowLeft /> Regresar
							</div>
						</Button>
						{/* <div className='w-auto align-items-center'>
							<BreadcrumbComponent
								items={[
									...newsAndUpdates,
									{ url: '/eventos', title: 'Eventos' },
								]}
							/>
						</div> */}
					</Row>
					<Row>
						<Col xs={12} md={6} lg={4}>
							<div className='c-img-cont'>
								<img
									src={LecturaEntreRuinas}
									alt='Club de Lectura "Letras entre ruinas"'
									loading='lazy'
									onContextMenu={removeRightClick}
								/>
							</div>
						</Col>
						<Col xs={12} md={6} lg={8}>
							<div className='d-flex flex-column justify-content-center h-100'>
								<p className='mt-0 mb-5 fw-700' wbk='xLxEy2tP'>
									<WBkey wbkey='xLxEy2tP' variant='plain' />
								</p>
								<p className='mt-0 fw-500' wbk='GIXPlaZf'>
									<WBkey wbkey='GIXPlaZf' variant='plain' />
								</p>
							</div>
						</Col>
					</Row>
					<Row className='justify-content-center mt-4'>
						<h2
							className='c-cirma-title text-center'
							style={{ fontSize: '24px' }}
						>
							<WBkey wbkey='t3JJT1we' />
						</h2>
					</Row>
					<Row className='flex-column mt-2'>
						<p className='fw-500' wbk='E_DKpM_a'>
							<WBkey wbkey='E_DKpM_a' variant='plain' />
						</p>
					</Row>
					<Row className='justify-content-around flex-wrap gap-4'>
						<div
							className='d-flex flex-column w-auto align-items-center'
							style={{ maxWidth: '300px' }}
						>
							<i
								aria-hidden='true'
								className='fas fa-calendar-check color-primary mb-4'
								style={{
									fontSize: '50px',
								}}
							></i>
							<h3 className='c-cirma-subtitle'>
								<WBkey wbkey='PulqmV0q' />
							</h3>
							<p
								className='m-0 p-0 fw-500 text-wrap-pretty text-center'
								wbk='aXwg2tuA'
							>
								<WBkey wbkey='aXwg2tuA' variant='plain' />
							</p>
						</div>
						<div
							className='d-flex flex-column w-auto align-items-center'
							style={{ maxWidth: '300px' }}
						>
							<i
								aria-hidden='true'
								className='fas fa-clock color-primary mb-4'
								style={{
									fontSize: '50px',
								}}
							></i>
							<h3 className='c-cirma-subtitle'>
								<WBkey wbkey='EFWxcEQp' />
							</h3>
							<p className='m-0 p-0 fw-500' wbk='uzRzfXLg'>
								<WBkey wbkey='uzRzfXLg' variant='plain' />
							</p>
						</div>
						<div
							className='d-flex flex-column w-auto align-items-center'
							style={{ maxWidth: '300px' }}
						>
							<i
								aria-hidden='true'
								className='fas fa-house-user color-primary mb-4'
								style={{
									fontSize: '50px',
								}}
							></i>
							<h3 className='c-cirma-subtitle'>
								<WBkey wbkey='aLtrhwZa' />
							</h3>
							<p
								className='m-0 p-0 fw-500 text-wrap-pretty text-center'
								wbk1='z_4RPSuu'
								wbk2='HqeGgj3t'
							>
								<WBkey wbkey='z_4RPSuu' variant='plain' />

								<br />
								<WBkey wbkey='HqeGgj3t' variant='plain' />
							</p>
						</div>
					</Row>
				</SimpleSection>
			</Container>
			<DirectContact
				subject={WBkey({ variant: 'plain', wbkey: 'QODLtaDN' })}
				message={WBkey({ variant: 'plain', wbkey: 'b5Yz491B' })}
			/>
		</AboutLayout>
	);
}
