import React, { useEffect, useState } from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import Select from 'react-select';
import { Search } from 'react-bootstrap-icons';

const options = [
	{ value: '', label: 'En todo CIRMA' },
	{ value: 'CIRMA-AH', label: 'Archivo Histórico' },
	{ value: 'CIRMA-FG', label: 'Fototeca Guatemala' },
	{ value: 'CIRMA-BIB', label: 'Biblioteca' },
];

const colourStyles = {
	menuList: (styles) => ({
		...styles,
		background: 'white',
		color: 'black',
		borderRadius: '3px',
	}),
	option: (styles, { isFocused, isSelected }) => ({
		...styles,
		background: isFocused
			? 'rgba(131,17,49)'
			: isSelected
			? 'rgba(131,17,49,0.8)'
			: undefined,
		zIndex: 1,
		fontSize: 'smaller',
		color: isFocused ? 'white' : isSelected ? 'white' : undefined,
	}),
	menu: (base) => ({
		...base,
		zIndex: 100,
		fontSize: 'smaller',
	}),
	control: (base) => ({
		...base,
		border: '1px solid grey',
		fontSize: 'smaller',
		boxShadow: 'none',
		height: '49px',
		'&:hover': {
			border: '1px solid grey',
		},
	}),
};

export default function SearchField(props) {
	const { placeholder } = props;
	const [showOptions, setShowOptions] = useState(false);
	const [currentSearchArgs, setCurrentSearchArgs] = useState({
		keywords: '',
		repository: '',
	});
	useEffect(() => {
		if (props?.withOption) {
			// console.log(props);
			setShowOptions(true);
		}
	}, [props?.withOption]);
	const submitForm = (e) => {
		e.preventDefault();
		const searchUrl = `/search?keywords=${currentSearchArgs.keywords}&repository=${currentSearchArgs.repository}`;
		window.location.href = searchUrl;
	};
	const catchKeywords = (e) => {
		setCurrentSearchArgs((prev) => ({ ...prev, keywords: e.target.value }));
	};
	return (
		<div className='c-input-search'>
			<form action={`/search`} onSubmit={submitForm}>
				<InputGroup className='mb-3'>
					<FormControl
						placeholder={placeholder}
						aria-label={placeholder}
						aria-describedby='basic-addon2'
						name='keywords'
						autoComplete='off'
						onChange={catchKeywords}
					/>
					<button type='submit' id='btnSearch' className='d-block d-lg-none' style={{marginLeft:"-7px"}}/>
					{showOptions && (
						<div className='selectContainer'>
							<Select
								options={options}
								isSearchable={false}
								defaultValue={options[0]}
								styles={colourStyles}
								onChange={(e) => {
									setCurrentSearchArgs((prev) => ({
										...prev,
										repository: e.value,
									}));
								}}
							/>
						</div>
					)}
					<button id='btnSearch' className='d-none d-lg-block'>
						<InputGroup.Text id='basic-addon2'>
							<Search />
						</InputGroup.Text>
					</button>
				</InputGroup>
			</form>
		</div>
	);
}
