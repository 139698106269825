import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {
	ClockFill,
	EnvelopeFill,
	GeoAltFill,
	TelephoneFill,
} from 'react-bootstrap-icons';

import AboutLayout from '../layouts/AboutLayout';

import SimpleSection from '../components/SimpleSection';
import ContactForm from '../components/ContactForm';
import WBkey from '../components/WBkey';

export default function ContactScreen(props) {
	return (
		<AboutLayout title='Contáctanos' backgroundImage='contactenos'>
			<SimpleSection className='c-contact-cirma'>
				<Container>
					<Row>
						<Col sm={12} lg={6} className='pe-lg-0'>
							<div className='c-contact-info'>
								<h3 className='c-contact-title'>
									<WBkey wbkey = '8V1G2tt_'/>
								</h3>
								<div className='c-divisor'>
									<div className='c-line'></div>
								</div>
								<div className='c-contact-note'>
									<p className='c-note'>
										<WBkey wbkey ='2uqD5rYq'/>
										<p className='c-red-note'>
											<WBkey wbkey = 'o0cEVdw9'/>
										</p>
									</p>
								</div>
								<div className='c-contact-data'>
									<div className='c-data-row'>
										<div className='c-icon'>
											<GeoAltFill />
										</div>
										<div className='c-data'>
											<WBkey wbkey = '0HvMragW'/>
										</div>
									</div>
									<div className='c-data-row'>
										<div className='c-icon'>
											<TelephoneFill />
										</div>
										<div className='c-data'>
											<WBkey wbkey = 'T8JOhhUu'/>
										</div>
									</div>
									<div className='c-data-row'>
										<div className='c-icon'>
											<EnvelopeFill />
										</div>
										<div className='c-data'>
											<WBkey wbkey = '8tn7hFny'/>
										</div>
									</div>
									<div className='c-data-row'>
										<div className='c-icon'>
											<ClockFill />
										</div>
										<div className='c-data'>
											<WBkey wbkey = 'hTrBxAo3'/>
										</div>
									</div>
								</div>
							</div>
						</Col>
						<Col sm={12} lg={6} className='ps-lg-0'>
							<div className='c-contact-form'>
								<h3 className='c-contact-title'>
									<WBkey wbkey = '3BdpIaOm'/>
								</h3>
								<ContactForm />
							</div>
						</Col>
					</Row>
				</Container>
			</SimpleSection>
			<SimpleSection className='c-contact-cirma-map'>
				<Container>
					<iframe
						style={{ width: '100%', height: '300px' }}
						frameborder='0'
						scrolling='no'
						marginheight='0'
						marginwidth='0'
						src='https://maps.google.com/maps?q=14.556292327224975%2C%20-90.73201232937747&amp;t=m&amp;z=17&amp;output=embed&amp;iwloc=near'
						title='14.556292327224975, -90.73201232937747'
						aria-label='14.556292327224975, -90.73201232937747'></iframe>
				</Container>
			</SimpleSection>
		</AboutLayout>
	);
}
