import React, { useState, useEffect } from 'react';
import SimpleSection from '../../components/SimpleSection';
import { Container, Row } from 'react-bootstrap';
import NewsCard from '../../components/NewsCard';
import BasicLayout from '../../layouts/BasicLayout';
import RegresarButton from '../../components/RegresarButton';
import { AxiosDrupal, cleanText, getDateFormat } from '../../utilities/util';

//import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import LoadingCard from '../../components/LoadingCard';
export default function AdquisicionesScreen() {
	const [news, setNews] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');

	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true);
				setError('');
				const { success, data, error } = await AxiosDrupal('GetNews');
				if (success && data?.data.length > 0) setNews(data.data);
				else if (success) setNews([]);
				else setError(error);
			} catch (error) {
				console.log(error);
				setError(error);
			} finally {
				setLoading(false);
			}
		};
		fetchData();
	}, []);

	return (
		<BasicLayout>
			<div className='c-container'>
				<Container>
					<SimpleSection>
						<Row>
							<RegresarButton link='noticias-y-novedades' />
						</Row>
						<Row>
							{loading &&
								new Array(3)
									.fill(0)
									.map((_, index) => (
										<LoadingCard key={index} />
									))}
							{error && (
								<MessageBox severity='danger'>
									{error}
								</MessageBox>
							)}
							{news.length > 0 &&
								news.map((item, index) => (
									<NewsCard
										key={index}
										image={
											item?.relationships?.field_portada
												?.links?.related?.href
										}
										subtitle='Adquisiciones'
										title={item?.attributes?.title}
										date={getDateFormat(
											item?.attributes
												?.field_fecha_publicacion,
											'DD de month de YYYY'
										)}
										url={`/noticia/${
											item?.attributes
												?.drupal_internal__nid
										}/${cleanText(
											item?.attributes?.title
										)}`}
										text={
											<span
												dangerouslySetInnerHTML={{
													__html:
														item?.attributes
															?.field_parrafos_1[0]
															?.processed ??
														item?.attributes
															?.field_parrafos_2[0]
															?.processed ??
														item?.attributes
															?.field_parrafos_3[0]
															?.processed,
												}}
											/>
										}
									/>
								))}
						</Row>
					</SimpleSection>
				</Container>
			</div>
		</BasicLayout>
	);
}
