import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import RegresarButton from '../components/RegresarButton';
import SimpleSection from '../components/SimpleSection';
import SuperBasicLayout from '../layouts/SuperBasicLayout';
// Import styles
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import GLIFOSPdfViewer from '../components/GLIFOSPdfViewer';
import { useParams } from 'react-router-dom';
import { removeRightClick } from '../utilities/util';
export default function PdfScreen(props) {
	const { goback, id } = useParams();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<SuperBasicLayout>
			<div className='c-container my-2'>
				<Container>
					<RegresarButton link={goback} />
				</Container>
			</div>
			<SimpleSection className='m-0 mb-2'>
				<Container>
					<div className='g-vc-wr'>
						<div
							className='g-vw-c'
							id='gdac-v'
							onContextMenu={removeRightClick}
						>
							<GLIFOSPdfViewer id={id} />
						</div>
					</div>
				</Container>
			</SimpleSection>
		</SuperBasicLayout>
	);
}
