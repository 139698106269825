import React, { useState, useRef } from 'react';
import { Spinner } from 'react-bootstrap';
import { Button, Form } from 'react-bootstrap';
import Axios from '../services/Api';
//import { useLocation } from 'react-router-dom';

export default function ContactForm() {
	const formRef = useRef(null);
	//const location = useLocation();
	//Get acervo from url
	//const { acervo } = useParams();

	//console.log(location);
	let params = new URL(document.location).searchParams;
	let acervo = params.get('acervo');

	const [formData, setFormData] = useState({
		name: '',
		lastname: '',
		email: '',
		phone: '',
		appointment: '',
		message: '',
		area: acervo ? acervo : '',
	});
	const [response, setResponse] = useState({
		isLoading: false,
		isError: false,
		payload: {
			message: '',
		},
	});

	const handleChange = (e) =>
		setFormData((prevData) => ({
			...prevData,
			[e.target.name]: e.target.value,
		}));

	const handleSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const form = e.currentTarget;
		if (form.checkValidity() === false) return;
		sendData();
	};

	const sendData = async () => {
		try {
			setResponse((prevResponse) => ({
				...prevResponse,
				isLoading: true,
			}));
			const { data } = await Axios.SendContactForm(formData);
			const validRespose = Boolean(data?.success);
			setResponse((prevResponse) => ({
				...prevResponse,
				isLoading: false,
				isError: !validRespose,
				payload: {
					message: data.message,
				},
			}));
			validRespose && resetForm();
		} catch (error) {
			console.log(error);
			setResponse((prevResponse) => ({
				...prevResponse,
				isLoading: false,
				isError: true,
				payload: {
					message: error?.message ?? 'Error al enviar el mensaje',
				},
			}));
		}
	};

	const resetForm = () => {
		setFormData({
			name: '',
			lastname: '',
			email: '',
			phone: '',
			appointment: '',
			message: '',
			area: '',
		});
		formRef.current.reset();
	};

	return (
		<Form onSubmit={handleSubmit} ref={formRef}>
			<Form.Group className='mb-3' controlId='formName'>
				<Form.Control
					type='text'
					placeholder='Nombre*'
					autoComplete='off'
					name='name'
					onChange={handleChange}
					value={formData.name}
					autoFocus
					required
				/>
			</Form.Group>

			<Form.Group className='mb-3 d-none' controlId='formApellido'>
				<Form.Control
					type='text'
					placeholder='Apellido*'
					autoComplete='off'
					name='lastname'
					className='d-none'
					onChange={handleChange}
					value={formData.lastname}
				/>
			</Form.Group>

			<Form.Group className='mb-3' controlId='formEmail'>
				<Form.Control
					type='email'
					placeholder='Correo electrónico*'
					autoComplete='off'
					name='email'
					onChange={handleChange}
					value={formData.email}
					required
				/>
			</Form.Group>

			<Form.Group className='mb-3' controlId='formPhone'>
				<Form.Control
					type='text'
					placeholder='No. de teléfono*'
					autoComplete='off'
					name='phone'
					onChange={handleChange}
					value={formData.phone}
				/>
			</Form.Group>

			{/* <Form.Group className='mb-3' controlId='formDate'>
				<Form.Label>Programa una cita</Form.Label>
				<Form.Control
					type='date'
					placeholder='dd/mm/yyyy'
					autoComplete='off'
					name='appointment'
					onChange={handleChange}
					value={formData.appointment}
				/>
			</Form.Group> */}

			<Form.Group className='mb-3' controlId='formArea'>
				<Form.Select
					aria-label='Acervo'
					name='area'
					onChange={handleChange}
					value={formData.area}
					required
				>
					<option value=''>Dirige tu mensaje a:</option>
					<option value='cirma'>CIRMA</option>
					<option value='biblioteca'>
						Biblioteca de Ciencias Sociales
					</option>
					<option value='fototeca'>Fototeca Guatemala</option>
					<option value='archivo'>Archivo Histórico</option>
				</Form.Select>
			</Form.Group>

			<Form.Group className='mb-3' controlId='formMessage'>
				<Form.Control
					as='textarea'
					placeholder='Mensaje'
					autoComplete='off'
					rows={3}
					name='message'
					onChange={handleChange}
					value={formData.message}
					required
				/>
			</Form.Group>
			<Button variant='primary' type='submit'>
				{response.isLoading ? (
					<>
						<Spinner animation='border' variant='light' size='sm' />{' '}
						Enviando mensaje...
					</>
				) : (
					'Enviar mensaje'
				)}
			</Button>
			{!response.isLoading && response.payload.message && (
				<p
					className={`mt-3 text-wrap fw-bold ${
						response.isError ? 'text-danger' : ''
					}`}
				>
					{response.payload.message}
				</p>
			)}
		</Form>
	);
}
