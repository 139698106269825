import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
//import EmptySpace from '../components/EmptySpace';
import PhotoWithCredits from '../components/PhotoWithCredits';
//import Video from '../components/Video';
import {
	DRUPAL_URL,
	DRUPAL_URI_EVENTS,
	DRUPAL_HEADERS,
} from '../utilities/globals';
import { eventsBreadcrumb } from '../data/breadcrumbsData';

import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import NoticiaLayout from '../layouts/NoticiaLayout';
import DrupalGalery from '../components/DrupalGalery';
import { CustomAxios, cleanText, getDateFormat } from '../utilities/util';
export default function EventScreen() {
	const { id } = useParams();
	const [article, setArticle] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');
	const [author, setAuthor] = useState('');

	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true);
				setError('');
				const response = await CustomAxios(
					'get',
					`${DRUPAL_URL}${DRUPAL_URI_EVENTS}?filter[drupal_internal__nid]=${id}`,
					{},
					{ 'Content-Type': 'application/json', ...DRUPAL_HEADERS }
				);
				if (response.success && response.data?.data) {
					//console.log(response.data.data[0]);
					setArticle(response.data.data[0]);
				} else {
					setArticle({});
					setError(response.error);
				}
			} catch (error) {
				setError(error);
			} finally {
				setLoading(false);
			}
		};
		if (id) fetchData();
	}, [id]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (article?.relationships?.revision_uid?.links?.related?.href) {
			CustomAxios(
				'get',
				article.relationships.revision_uid.links.related.href,
				{},
				{ 'Content-Type': 'application/json', ...DRUPAL_HEADERS }
			).then((response) => {
				if (response.success) {
					setAuthor(response.data.data.attributes.display_name);
				} else setAuthor('');
			});
		}
	}, [article]);
	return (
		<NoticiaLayout
			backgroundImage={
				article?.relationships?.field_portada_evento?.links?.related
					?.href ??
				`${DRUPAL_URL}/glifos/file/get/${article?.attributes?.glifos_digital_file}`
			}
			autor={author}
			date={getDateFormat(
				article?.attributes?.field_fecha_inicio,
				'month DD, YYYY'
			)}
			title={article?.attributes?.title}
			goback='eventos'
			sidebar='eventos'
			breadcrumb={[
				...eventsBreadcrumb,
				{
					url: `/eventos/${id}/${cleanText(
						article?.attributes?.title
					)}`,
					title: article?.attributes?.title,
				},
			]}
		>
			{loading && <LoadingBox />}
			{error && <MessageBox severity='danger'>{error}</MessageBox>}
			{article?.attributes?.body?.processed && (
				<div
					dangerouslySetInnerHTML={{
						__html: article?.attributes?.body?.processed,
					}}
				/>
			)}
			{article?.relationships?.field_galeria_1?.data?.length > 1 && (
				<DrupalGalery
					url={
						article?.relationships?.field_galeria_1?.links?.related
							?.href
					}
					info={article?.relationships?.field_galeria_1?.data}
				/>
			)}
			{article?.relationships?.field_galeria_1?.data?.length === 1 && (
				<PhotoWithCredits
					className='c-image-w-credits'
					data={article?.relationships?.field_galeria_1?.data[0]}
					image={
						article?.relationships?.field_galeria_1?.links?.related
							?.href
					}
				/>
			)}
		</NoticiaLayout>
	);
}
