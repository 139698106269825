import React, { useEffect, useState } from 'react';
/* import EventCard from '../components/EventCard'; */
import SimpleSection from '../../components/SimpleSection';
import { Button, Container, Row } from 'react-bootstrap';
import { ArrowLeft } from 'react-bootstrap-icons';

import styles from './SocialMediaScreen.module.scss';
import AboutLayout from '../../layouts/AboutLayout';
import { useSearchParams, Link } from 'react-router-dom';
import Axios from '../../services/Api';

import Masonry from 'react-masonry-css';
import { DRUPAL_URL } from '../../utilities/globals';
import { Search } from 'react-bootstrap-icons';
import { Link45deg } from 'react-bootstrap-icons';
import Watcher from '../../components/Watcher';
const breakpointColumnsObj = {
	default: 4,
	1100: 3,
	768: 2,
	500: 1,
};

const DiffussionTypes = {
	ALL: { label: 'Todos', param: 'all', show: true },
	STORIES_VIDEOS: {
		label: 'Historias y videos',
		param: 'historia_video',
		show: true,
	},
	PUBLICATIONS: { label: 'Publicaciones', param: 'publicacion', show: true },
};

export default function SocialMediaScreen() {
	const [watcherInfo, setWatcherInfo] = useState({
		show: false,
		link: '',
		horizontal: false,
		vertical: false,
	});
	const [publications, setPublications] = useState({
		isLoading: false,
		isError: false,
		error: '',
		payload: [],
	});

	const [searchParams] = useSearchParams();
	const search_type =
		searchParams.get('search_type') || DiffussionTypes.ALL.param;

	useEffect(() => {
		const fetchData = async () => {
			try {
				setPublications({
					isLoading: true,
					isError: false,
					error: '',
					payload: [],
				});
				const { data } = await Axios.GetSocialMedia();
				if (!Boolean(data?.success)) {
					throw new Error(
						data?.message ??
							'Error al obtener las publicaciones de redes sociales'
					);
				}
				setPublications((prevData) => ({
					...prevData,
					payload: data?.data ?? [],
				}));
			} catch (error) {
				setPublications({
					isLoading: false,
					isError: true,
					error:
						error?.message ??
						'Error al obtener las publicaciones de redes sociales',
					payload: [],
				});
			} finally {
				setPublications((prevData) => ({
					...prevData,
					isLoading: false,
				}));
			}
		};
		fetchData();
	}, []);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const hideWatcher = (showWatcher) => {
		setWatcherInfo((prevData) => ({
			...prevData,
			show: showWatcher,
		}));
	};

	return (
		<AboutLayout
			title={'Redes Sociales'}
			backgroundImage='redes'
			noMarginTop
			socialMedia
		>
			<Container>
				<SimpleSection className={'mt-2'}>
					<Row className='justify-content-between align-items-center mb-2'>
						<Button
							href={'/noticias-y-novedades'}
							variant='transparent'
							className='c-btn-goback w-auto'
						>
							<div className='c-btn-content'>
								<ArrowLeft /> Regresar
							</div>
						</Button>
						{/* <div className='w-auto align-items-center'>
							<BreadcrumbComponent
								items={[
									...newsAndUpdates,
									{ url: '/eventos', title: 'Eventos' },
								]}
							/>
						</div> */}
					</Row>
					<Row className='d-flex justify-content-center'>
						<div className='c-difusion-head'>
							<h2 className='c-cirma-title'>
								Nos entusiasma compartir el trabajo de CIRMA con
								el mundo
							</h2>
							<p className='c-subtitle'>
								A través de las redes sociales buscamos conectar
								con una audiencia diversa y generar conciencia e
								interés acerca del resguardo del patrimonio
								visual y documental de Guatemala, además de dar
								a conocer nuestras mútliples actividades
								culturales.
							</p>
						</div>
					</Row>
					<Row className='d-flex flex-wrap justify-content-center gap-3 mb-3'>
						{Object.entries(DiffussionTypes).map(([_, type]) => (
							<Link
								key={type.param}
								to={`?search_type=${type.param}`}
								className={`w-auto py-2 px-4 ${
									styles.mediaLink
								} ${
									type.param === search_type
										? styles.active
										: ''
								}`}
							>
								{type.label}
							</Link>
						))}
					</Row>
					<Row className='w-100'>
						<Masonry
							breakpointCols={breakpointColumnsObj}
							className='my-masonry-grid w-100'
							columnClassName='my-masonry-grid_column'
						>
							{publications.payload
								.filter((item) =>
									search_type === DiffussionTypes.ALL.param
										? true
										: item.source_type === search_type
								)
								.map((data) => (
									<div
										key={data.uuid}
										className={`w-auto h-auto ${styles.mediaCard}`}
									>
										<img
											src={`${DRUPAL_URL}/glifos/file/get/thumbnail/${data.file_id}/400`}
											alt={data.image_alt}
											loading='lazy'
										/>
										<div
											className={`${styles.mediaCardInfo}`}
										>
											<Row className='d-flex justify-content-center w-100 gap-3 flex-wrap mb-3'>
												<Button
													variant='primary'
													className={`w-auto ${styles.mediaBtn}`}
													onClick={() => {
														setWatcherInfo({
															show: true,
															link:
																data.video !==
																null
																	? data.video
																	: `${DRUPAL_URL}/glifos/file/get/${data.file_id}/`,
															horizontal: false,
															vertical: true,
															video:
																data.video !==
																null
																	? data.video
																	: false,
														});
													}}
												>
													<Search size={17} />
												</Button>
												{data?.publication_link && (
													<Button
														variant='primary'
														className={`w-auto ${styles.mediaBtn}`}
														href={
															data.publication_link
														}
														target='_blank'
													>
														<Link45deg size={17} />
													</Button>
												)}
											</Row>
											<div
												className={`${styles.mediaCaption}`}
											>
												<h5>{data.title}</h5>
												<p>{data.description}</p>
											</div>
										</div>
									</div>
								))}
						</Masonry>
					</Row>
				</SimpleSection>
			</Container>
			<Watcher
				link={watcherInfo.link}
				show={watcherInfo.show}
				picture={!watcherInfo.video}
				youtube={watcherInfo.video}
				setShowWatcher={hideWatcher}
				horizontal={watcherInfo.horizontal}
				vertical={watcherInfo.vertical}
			/>
		</AboutLayout>
	);
}
