import React from 'react';
import jstor from '../../images/footer/jstor.png';
import family from '../../images/footer/familysearch.png';
import wihciteca from '../../images/footer/wichiteca.png';
import { useState } from 'react';
import WBkey from '../WBkey';
import ModalAlliances from './ModalAalliances';

export default function Alliances() {
	const [active, setActive] = useState({
		status: false,
		number: '0',
		location: '#',
	});
	const handleClose = () => {
		setActive((prev) => ({ ...prev, status: false }));
	};
	const openModal = (e) => {
		const option = e.currentTarget.getAttribute('data-value');
		var img = null;
		var newHref = '';
		switch (option) {
			case '1':
				img = jstor;
				newHref = 'https://www.jstor.org/';
				break;
			case '2':
				img = family;
				newHref = 'https://www.familysearch.org/es/';
				break;
			case '3':
				img = wihciteca;
				newHref = 'https://wichiteca.com/opac/';
				break;
			default:
				img = jstor;
				newHref = 'https://www.jstor.org/';
				break;
		}
		setActive({
			status: true,
			number: option,
			image: img,
			location: newHref,
		});
	};
	return (
		<>
			<div className='mx-0 mt-0 p-3 py-5 cuerpo'>
				<div className='row p-2'>
					<div className='col-2 d-none d-lg-block' />
					<div
						className='col text-left texto'
						style={{ maxWidth: '850px', margin: '0 auto' }}
					>
						<WBkey wbkey='vlZhpOTk' />
						<div className='justify-content-between aliences'>
							<img
								src={jstor}
								alt='jstor'
								data-value='1'
								onClick={openModal}
							/>
							<img
								src={family}
								alt='family'
								data-value='2'
								onClick={openModal}
							/>
							<img
								src={wihciteca}
								alt='wihciteca'
								data-value='3'
								onClick={openModal}
							/>
						</div>
					</div>
					<div className='col-2 d-none d-lg-block' />
				</div>
			</div>
			<ModalAlliances handleClose={handleClose} active={active} />
		</>
	);
}
