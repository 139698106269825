import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isValidURL, CustomAxios } from '../utilities/util';
import { DRUPAL_URL, DRUPAL_HEADERS } from '../utilities/globals';

export default function LastContentCard(props) {
	const { image, title, date, url } = props;
	const navigate = useNavigate();
	const [imageData, setImageData] = useState(null);
	useEffect(() => {
		if (isValidURL(image)) {
			CustomAxios(
				'get',
				image,
				{},
				{ 'Content-Type': 'application/json', ...DRUPAL_HEADERS }
			).then((response) => {
				setImageData(response.success ? response.data.data : null);
			});
		}
		return () => {
			setImageData(null);
		};
	}, [image]);
	return (
		<div
			className='c-last-card'
			onClick={() => {
				navigate(url);
			}}
		>
			<div className='c-card-image'>
				<img
					src={
						imageData
							? `${DRUPAL_URL}${imageData?.attributes?.uri?.url.replace(
									'/glifos',
									''
							  )}`
							: image
					}
					alt={title}
				/>
			</div>
			<div className='c-card-content'>
				<h5 className='c-card-title'>{title}</h5>
				<h6 className='c-card-date'>{date}</h6>
			</div>
		</div>
	);
}
