import React, { useState, useEffect } from 'react';
/* import conozcaCirma from '../images/FAKElastcontent/conozca-cirma.jpg'; 
import eventosCirma2 from '../images/FAKElastcontent/eventos-cirma-2.jpg';
import eventosCirma1 from '../images/FAKElastcontent/eventos-cirma-1.jpg';
import ArqueologiaEnGt from '../images/FAKElastcontent/Arqueologia-en-gt.jpg';
import ArqueologiaEnGt3 from '../images/FAKElastcontent/Arqueologia-en-gt3.jpg';
import ArqueologiaEnGt2 from '../images/FAKElastcontent/Arqueologia-en-gt2.jpg'; */
import LastContentCard from './LastContentCard';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { AxiosDrupal, cleanText, getDateFormat } from '../utilities/util';

/* const fakeData = [
    {
        image: conozcaCirma,
        title: 'Conozca Cirma',
        date: '26 de abril de 2022',
    },
    {
        image: eventosCirma2,
        title: 'Concierto entre ruinas',
        date: '26 de abril de 2022',
    },
    {
        image: eventosCirma1,
        title: 'Exposición fotografica',
        date: '26 de abril de 2022',
    },
    {
        image: ArqueologiaEnGt,
        title: 'Anuncian XXXIII Simposio de Arqueología en Guatemala',
        date: '26 de abril de 2022',
    },
    {
        image: ArqueologiaEnGt3,
        title: 'Guatemala lidera la semana dedicada a la arqueología',
        date: '26 de abril de 2022',
    },
    {
        image: ArqueologiaEnGt2,
        title: 'Historia de la Arqueología en Guatemala',
        date: '26 de abril de 2022',
    }
] */
export default function LastContent(props) {
	const { children, title } = props;
	const [news, setNews] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');
	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true);
				setError('');
				const { success, data, error } = await AxiosDrupal('GetNews');
				if (success && data?.data.length > 0) setNews(data.data);
				else if (success) setNews([]);
				else setError(error);
			} catch (error) {
				console.log(error);
				setError(error);
			} finally {
				setLoading(false);
			}
		};
		fetchData();
		return () => {
			setNews([]);
			setLoading(true);
			setError('');
		};
	}, []);
	return (
		<div>
			<h3>Últimos contenidos</h3>
			{loading && <LoadingBox />}
			{error && <MessageBox severity='danger'>{error}</MessageBox>}
			{news.length > 0 &&
				news.map(
					(item, index) =>
						title !== item?.attributes?.title && (
							<LastContentCard
								key={index}
								image={
									item?.relationships?.field_portada?.links
										?.related?.href
								}
								title={item?.attributes?.title}
								date={getDateFormat(
									item?.attributes?.field_fecha_publicacion,
									'DD de month de YYYY'
								)}
								url={`/noticia/${
									item?.attributes?.drupal_internal__nid
								}/${cleanText(item?.attributes?.title)}`}
							/>
						)
				)}

			{children}
		</div>
	);
}
