import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {
	Facebook,
	GeoAltFill,
	Instagram,
	TelephoneFill,
	Youtube,
	Twitter,
	ChatSquareFill,
	EnvelopeFill,
} from 'react-bootstrap-icons';

//import app_params from "../../config/params";
import jstor from '../../images/footer/jstor.png';
import family from '../../images/footer/familysearch.png';
import wihciteca from '../../images/footer/wichiteca.png';
import TwitterX from '../Icon/TwitterX';
import ModalAlliances from '../Aalliances/ModalAalliances';

const basePath = '/'; /* app_params.otherSite */

const footerLinks = [
	{
		columnLabel: 'QUIÉNES SOMOS' /*"QUIÉNES SOMOS"*/,
		children: [
			{
				label: 'Historia y misión', //"Historia y misión",
				href: `${basePath}historia-y-mision`, // "/historia-y-mision/"
			},
			{
				label: 'Fundadores', //"Fundadores",
				href: `${basePath}fundadores`, // "/historia-y-mision/"
			},
			{
				label: 'Sede', //"Sede",
				href: `${basePath}sede`, // "/historia-y-mision/"
			},
			{
				label: 'Videos informativos', //"Videos informativos",
				href: `${basePath}videos-informativos`, // "/historia-y-mision/"
			},
			{
				label: 'Noticias y novedades', //"Videos informativos",
				href: `${basePath}noticias-y-novedades`, // "/historia-y-mision/"
			},
			{
				label: 'Exposiciones itinerantes', //"Exposiciones",
				href: `${basePath}exposiciones`, // "/historia-y-mision/"
			},
			{
				label: 'Publicaciones', //"Publicaciones",
				href: `${basePath}publicaciones`, // "/historia-y-mision/"
			},
			{
				label: 'Reconocimientos', //"Reconocimientos",
				href: `${basePath}reconocimientos`, // "/historia-y-mision/"
			},
			{
				label: 'Proyectos de investigación', //"Proyectos de investigación",
				href: `${basePath}proyectos-de-investigacion`, // "/historia-y-mision/"
			},
		],
	},
	{
		columnLabel: 'Colecciones',
		children: [
			{
				label: 'Biblioteca de Ciencias Sociales',
				href: `https://cirma.org.gt/opac`,
			},
			{
				label: 'Fototeca Guatemala',
				href: `${basePath}glifos/agency/GT-CIRMA-FG`,
			},
			{
				label: 'Archivo Histórico',
				href: `${basePath}glifos/agency/GT-CIRMA-AH`,
			},
		],
	},
	/* {
		columnLabel: 'Publicaciones',
		children: [
			{
				label: 'Disponibles en CIRMA',
				href: `https://cirma.org.gt/opac/acquisitions`,
			},
			{
				label: 'Revista Mesoamérica 1-55',
				href: `https://dialnet.unirioja.es/servlet/revista?codigo=10118`,
				target: '_blank',
			},
			{
				label: 'Revista Mesoamérica 56-61',
				href: `http://www.mesoamericarevista.org/`,
				target: '_blank',
			},
		],
	}, */
	/* {
		columnLabel: 'Boletines',
		children: [
			{
				label: 'Boletin informativo',
				href: `${basePath}boletines`,
			},
		],
	}, */
	{
		columnLabel: 'Links',
		children: [
			{
				label: 'Maya Educational Foundation (MEF)',
				href: 'https://mayaedufound.org',
				target: '_blank',
			},
			{
				label: 'Plumsock Mesoamerican Studies',
				href: 'https://plumsock.org',
				target: '_blank',
			},
			{
				label: 'Revista Mesoamérica',
				href: 'https://mesoamericarevista.org',
				target: '_blank',
			},
			{
				label: 'JSTOR',
				href: 'https://www.jstor.org/',
				target: '_blank',
				modal: true,
				value: 1,
			},
			{
				label: 'FamilySearch',
				href: 'https://www.familysearch.org/es/',
				target: '_blank',
				modal: true,
				value: 2,
			},
			{
				label: 'Wichiteca',
				href: 'https://wichiteca.com/opac/',
				target: '_blank',
				modal: true,
				value: 3,
			},
		],
	},
	{
		columnLabel: 'Contacto',
		children: [
			{
				icon: 'message',
				label: 'Déjanos un mensaje',
				href: '/contactenos',
			},
			{
				icon: 'mappoint',
				label: '5a. Calle Oriente No. 5',
				label2: 'La Antigua Guatemala',
			},
			{
				icon: 'phone',
				label: '(502) 7931-0300',
			},
			{
				icon: 'email',
				label: 'cirma@cirma.org.gt',
				href: 'mailto:cirma@cirma.org.gt',
			},
		],
	},
	{
		columnLabel: 'Social Networks',
		variant: 'social-networks',
		children: [
			{
				icon: 'facebook',
				href: 'https://www.facebook.com/cirma.guatemala/',
				target: '_blank',
			},
			{
				icon: 'instagram',
				href: 'https://www.instagram.com/cirma.guatemala/',
				target: '_blank',
			},
			{
				icon: 'youtube',
				href: 'https://www.youtube.com/channel/UC8rBjONgDjb6BVZsDaj7WuQ',
				target: '_blank',
			},
			{
				icon: 'twitter',
				href: 'https://twitter.com/CIRMAGUATEMALA',
				target: '_blank',
			},
		],
	},
];

function FooterColumn(props) {
	const { element } = props;
	let { variant } = props;

	switch (variant) {
		case 'social-networks':
			return (
				<div className='c-social'>
					{element.children.map((elec, index2) => {
						let { href, icon, target } = elec;
						if (undefined !== icon) {
							switch (icon) {
								case 'facebook':
									icon = (
										<div className='c-f-sicon'>
											<Facebook />
										</div>
									);

									break;
								case 'instagram':
									icon = (
										<div className='c-f-sicon'>
											<Instagram />
										</div>
									);

									break;
								case 'youtube':
									icon = (
										<div className='c-f-sicon'>
											<Youtube />
										</div>
									);

									break;
								case 'twitter':
									icon = (
										<div className='c-f-sicon'>
											<TwitterX />
										</div>
									);

									break;
								default:
									icon = '';
							}
						} else {
							icon = '';
						}

						target = undefined === target ? '_self' : target;
						return (
							<div className={'c-s-' + index2} key={index2}>
								{href !== undefined ? (
									<a href={href} target={target}>
										{icon}
										{elec.label !== undefined ? (
											<span className='c-lbl'>
												{elec.label}
											</span>
										) : (
											''
										)}
									</a>
								) : (
									<div>{icon}</div>
								)}
							</div>
						);
					})}
				</div>
			);

		default:
			return (
				<div>
					<h5>{element.columnLabel}</h5>
					<div className='c-divider'> </div>
					<div className='c-list-cont'>
						<ul>
							{element.children.map((elec, index2) => {
								let {
									href,
									icon,
									label,
									//label2,
									target,
									image,
									modal,
								} = elec;
								if (undefined !== icon) {
									switch (icon) {
										case 'message':
											icon = (
												<div className='c-f-icon'>
													<ChatSquareFill />
												</div>
											);

											break;
										case 'mappoint':
											icon = (
												<div className='c-f-icon'>
													<GeoAltFill />
												</div>
											);

											break;
										case 'phone':
											icon = (
												<div className='c-f-icon'>
													<TelephoneFill />
												</div>
											);
											break;
										case 'email':
											icon = (
												<div className='c-f-icon'>
													<EnvelopeFill />
												</div>
											);

											break;
										case 'facebook':
											icon = (
												<div className='c-f-icon'>
													<Facebook />
												</div>
											);

											break;
										case 'instagram':
											icon = (
												<div className='c-f-icon'>
													<Instagram />
												</div>
											);

											break;
										case 'youtube':
											icon = (
												<div className='c-f-icon'>
													<Youtube />
												</div>
											);

											break;
										case 'twitter':
											icon = (
												<div className='c-f-icon'>
													<Twitter />
												</div>
											);
											break;
										default:
											icon = '';
									}
								} else {
									icon = '';
								}
								target =
									undefined === target ? '_self' : target;
								//label2 = undefined === label2 ? '' : label2;
								if (undefined !== image) {
									return (
										<a
											href={href}
											target={target}
											key={label}
										>
											<img src={image} alt={label} />
										</a>
									);
								}
								if (undefined !== label) {
									if (modal) {
										return (
											<li index={index2} key={index2}>
												<div
													data-value={
														elec?.value ?? label
													}
													onClick={props.openModal}
													style={{
														cursor: 'pointer',
													}}
												>
													{icon}{' '}
													<span className='c-lbl'>
														{elec.label}
													</span>
												</div>
											</li>
										);
									} else
										return (
											<li index={index2} key={index2}>
												{href !== undefined ? (
													<a
														href={href}
														target={target}
													>
														{icon}{' '}
														<span className='c-lbl'>
															{elec.label}
														</span>
													</a>
												) : (
													<div className='d-flex'>
														{icon}{' '}
														<span className='c-lbl'>
															{elec.label}
															<br /> {elec.label2}
														</span>
													</div>
												)}
											</li>
										);
								} else {
									return '&nbsp;';
								}
							})}
						</ul>
					</div>
				</div>
			);
	}
}

export default function Footer(props) {
	const [active, setActive] = useState({
		status: false,
		number: '0',
		location: '#',
	});
	const handleClose = () => {
		setActive((prev) => ({ ...prev, status: false }));
	};
	const openModal = (e) => {
		const option = e.currentTarget.getAttribute('data-value');
		var img = null;
		var newHref = '';
		switch (option) {
			case '1':
				img = jstor;
				newHref = 'https://www.jstor.org/';
				break;
			case '2':
				img = family;
				newHref = 'https://www.familysearch.org/es/';
				break;
			case '3':
				img = wihciteca;
				newHref = 'https://wichiteca.com/opac/';
				break;
			default:
				img = jstor;
				newHref = 'https://www.jstor.org/';
				break;
		}
		setActive({
			status: true,
			number: option,
			image: img,
			location: newHref,
		});
	};
	return (
		<footer>
			<Container>
				<Row>
					<Col sm={12} md={3}>
						<FooterColumn element={footerLinks[0]} index={0} />
					</Col>
					<Col sm={12} md={3}>
						<FooterColumn element={footerLinks[1]} index={1} />
						{/* <FooterColumn element={footerLinks[2]} index={2} /> */}
					</Col>
					<Col sm={12} md={3}>
						{/* <FooterColumn element={footerLinks[3]} index={3} /> */}
						<FooterColumn
							element={footerLinks[2]}
							index={2}
							openModal={openModal}
						/>
					</Col>
					<Col sm={11} md={3}>
						<FooterColumn element={footerLinks[3]} index={3} />
						<FooterColumn
							element={footerLinks[4]}
							index={4}
							variant='social-networks'
						/>
					</Col>
				</Row>
			</Container>
			<ModalAlliances handleClose={handleClose} active={active} />
			<div className={`rigths`}>
				®Todos los derechos reservados {new Date().getFullYear()} |
				Centro de Investigaciones Regionales de Mesoamérica (CIRMA)
			</div>
		</footer>
	);
}
