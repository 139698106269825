import React from 'react';
import { Col, Card } from 'react-bootstrap';
import { CalendarFill } from 'react-bootstrap-icons';

export default function LoadingCard(props) {
	return (
		<Col sm={12} md={6} lg={4} className='c-news-card'>
			<Card className='c-card'>
				<Card.Header
					variant='top'
					className='c-card-image animated'
					loading='lazy'
				></Card.Header>
				<Card.Body className='c-card-body'>
					<Card.Subtitle className='c-card-subtitle animated'>
						...
					</Card.Subtitle>
					<Card.Title className='c-card-title animated'>
						...
					</Card.Title>
					<Card.Subtitle className='c-card-date animated'>
						<CalendarFill /> ...
					</Card.Subtitle>
					<div className='c-card-text'>
						<p className='animated'>...</p>
						<p className='animated'>...</p>
					</div>
				</Card.Body>
			</Card>
		</Col>
	);
}
