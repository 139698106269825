import React from 'react';
import { Modal } from 'react-bootstrap';
import WBkey from '../WBkey';

export default function ModalAlliances({ handleClose, active }) {
	return (
		<Modal show={active.status} onHide={handleClose} size='lg'>
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body className='headerModal'>
				<img src={active.image} alt='logo' />
				{active.number === '1' && <JstorBody />}
				{active.number === '2' && <FamilyBody />}
				{active.number === '3' && <WichitecaBody />}
				<a
					className='btn btn-outline-primary'
					href={active.location}
					target='_blank'
					rel='noreferrer'
				>
					<WBkey wbkey='aRtadJcP' />
				</a>
			</Modal.Body>
		</Modal>
	);
}

function JstorBody() {
	return (
		<div className='text-left'>
			<WBkey wbkey='13noPtGK' />
			<p className='neg mb-0'>
				<WBkey wbkey='OLHvz82j' />
			</p>
			<p className='mb-0'>
				<WBkey wbkey='Gd74QNgD' />
			</p>
			<p className='mb-0'>
				<WBkey wbkey='Yty0qO1w' />
			</p>
		</div>
	);
}

function FamilyBody() {
	return (
		<div className='text-left'>
			<WBkey wbkey='Q_Rw1gi6' />
		</div>
	);
}

function WichitecaBody() {
	return (
		<div className='text-left'>
			<WBkey wbkey='YNhaRyqM' />
		</div>
	);
}
