import adquisiciones from '../images/icons/Social/adquisitions.svg';
import eventos from '../images/icons/Social/nextEvents.svg';
import boletines from '../images/icons/Social/boletines.svg';
import projects from '../images/icons/Social/projects.svg';
import clubes from '../images/icons/Social/clubes.svg';
import articles from '../images/icons/Social/articles.svg';
import donations from '../images/icons/Social/donations.svg';
import socialMedia from '../images/icons/Social/social-media.svg';

export const socialOptions = [
	{
		name: 'Eventos',
		icon: eventos,
		url: '/eventos',
		description:
			'Noticias de eventos pasados y fechas de próximos eventos.',
		show: true,
	},
	{
		name: 'Nuestros clubes',
		icon: clubes,
		url: '/clubes',
		description: 'Noticias e información general de nuestros clubes.',
		show: true,
	},
	{
		name: 'Redes sociales',
		icon: socialMedia,
		url: '/redes-sociales',
		description:
			'No te pierdas los contenidos de nuestros canales digitales.',
		show: true,
	},
	{
		name: 'Adquisiciones',
		icon: adquisiciones,
		url: '/adquisiciones',
		description:
			'Noticias sobre las colecciones y libros de reciente ingreso a CIRMA.',
		show: false,
	},
	{
		name: 'Proyectos',
		icon: projects,
		url: '/proyectos',
		description:
			'Descripción de los proyectos de conservación y digitalización.',
		show: false,
	},
	{
		name: 'Voluntarios y practicantes',
		icon: donations,
		url: '/voluntarios-y-practicantes',
		description: 'Noticias acerca del trabajo que realizan en CIRMA.',
		show: false,
	},
	{
		name: 'Difusión en medios',
		icon: articles,
		url: '/difusion-en-medios',
		description:
			'Publicaciones sobre CIRMA en diversos medios de comunicación.',
		show: true,
	},
	{
		name: 'Bitácora de boletines',
		icon: boletines,
		url: '/boletines',
		description: 'Boletines en PDF de años anteriores.',
		show: true,
	},
];
