import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { CollectionFill } from 'react-bootstrap-icons';

import AboutLayout from '../layouts/AboutLayout';

import SimpleSection from '../components/SimpleSection';

import Monografia from '../images/publications/monografias.jpg';
import Revista from '../images/publications/revista-mesoamerica.jpg';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import WBkey from '../components/WBkey';

export default function PublicationsScreen(props) {
	const libraryURL = 'https://cirma.org.gt/opac/featured';
	return (
		<AboutLayout title='Publicaciones' backgroundImage='publicaciones'>
			<Container>
				<SimpleSection className='c-publications-cirma'>
					<Row>
						<Col sm={12} lg={7}>
							<div className='c-publications-info'>
								<WBkey wbkey='uVbP2Kuq' />
								<div className='c-publications-button'>
									<Button
										variant='outline-primary'
										className='c-button'
										href={libraryURL}
										target='_blank'
									>
										<CollectionFill />{' '}
										<WBkey wbkey='fQFFVu6H' />
									</Button>
								</div>
							</div>
						</Col>
						<Col sm={12} lg={5}>
							<div className='c-img-cont'>
								<img src={Monografia} alt='Monografia' />
							</div>
						</Col>
					</Row>
				</SimpleSection>
				<SimpleSection className='c-publications-cirma'>
					<Row>
						<Col sm={12} lg={5}>
							<div className='c-img-cont'>
								<img src={Revista} alt='Monografia' />
							</div>
						</Col>
						<Col sm={12} lg={7}>
							<div className='c-publications-info'>
								<h3 className='c-cirma-h3'>
									<WBkey wbkey='pen3ujYN' />
								</h3>
								<p className='c-publications-text'>
									<WBkey wbkey='xlK6EbUT' />
								</p>
								<div className='c-publications-button justify-content-evenly'>
									<Button
										variant='outline-primary'
										className='c-button'
										onClick={(e) => {
											e.preventDefault();
											window.open(
												'https://dialnet.unirioja.es/servlet/revista?codigo=10118',
												'_blank'
											);
										}}
									>
										{' '}
										<WBkey wbkey='EEmRMtTA' />
									</Button>
									<Button
										variant='outline-primary'
										className='c-button'
										onClick={(e) => {
											e.preventDefault();
											window.open(
												'http://www.mesoamericarevista.org/',
												'_blank'
											);
										}}
									>
										{' '}
										<WBkey wbkey='5z3fgcWc' />
									</Button>
								</div>
							</div>
						</Col>
					</Row>
				</SimpleSection>
				{/* <SimpleSection className='c-publications-cirma'>
					<Row>
						<Col sm={12} lg={6}>
							<div className='c-publications-info'>
								<h3 className='c-cirma-h3'>
									<WBkey wbkey='jjfMbGwK'/>
								</h3>
								<WBkey wbkey='_aGfSiDF'/>
								<div className='c-publications-button'>
									<Button
										variant='outline-primary'
										className='c-button'
										href={libraryURL}
										target='_blank'>
										<CollectionFill /> <WBkey wbkey='fQFFVu6H'/>
									</Button>
								</div>
							</div>
						</Col>
						<Col sm={12} lg={6}>
							<div className='c-publications-slider'>
								<div className='c-swiper-cont'>
									<Swiperr
										Images={Images}
										Alts={Alts}
										slidesPerView={3}
										spaceBetween={10}
										publication
									/>
								</div>
							</div>
						</Col>
					</Row>
				</SimpleSection> */}
			</Container>
		</AboutLayout>
	);
}
