import React from 'react';

export default function TitleBackground(props) {
	const { subtitle, title } = props;
	return (
		<div className='c-title-background'>
			<div className='c-bt-cont'>
				<div className='c-subtitle'>{subtitle}</div>
				<div className='c-title'>{title}</div>
			</div>
		</div>
	);
}
