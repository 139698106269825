import PrimaryScreen from '../views/PrimaryScreen';
import FoundersScreen from '../views/FoundersScreen';
import HistoryMisionScreen from '../views/HistoryMisionScreen';
import SiteScreen from '../views/SiteScreen';
import VideosScreen from '../views/VideosScreen';
import ContactScreen from '../views/ContactScreen';
import ResearchScreen from '../views/ResearchScreen';
import NotFoundScreen from '../views/NotFoundScreen';
import ExhibitionsScreen from '../views/ExhibitionsScreen';
import PublicationsScreen from '../views/PublicationsScreen';
import AcknowledgmentsScreen from '../views/AcknowledgmentsScreen';
import AdquisicionesScreen from '../views/AdquisicionesScreen/AdquisicionesScreen';
import GLIFOSredirect from '../views/GLIFOSredirect';
import Donation from '../views/Donation';
import NoticiasNovedadesScreen from '../views/NoticiasNovedadesScreen';
import EventsScreen from '../views/EventsScreen/EventsScreen';
import NoticiaScreen from '../views/NoticiaScreen';
import ProjectsScreen from '../views/ProjectsScreen';
import OurClubsScreen from '../views/OurClubsScreen/OurClubsScreen';
import VoluntariosPracticanesScreen from '../views/VoluntariosPracticantesScreen';
import EventScreen from '../views/EventScreen';
import ProjectScreen from '../views/ProjectScreen';
import ClubScreen from '../views/ClubScreen';
import MeetScreen from '../views/MeetScreen';
import BoletinesScreen from '../views/BoletinesScreen';
import PdfScreen from '../views/PdfScreen';
import ChessClubScreen from '../views/ClubsScreen/ChessClubScreen/ChessClubScreen';
import ReadingClubScreen from '../views/ClubsScreen/ReadingClubScreen/ReadingClubScreen';
import ChildrenReadingClubScreen from '../views/ClubsScreen/ChildrenReadingClubScreen/ChildrenReadingClubScreen';
import MediaScreen from '../views/MediaScreen/MediaScreen';
import SocialMediaScreen from '../views/SocialMediaScreen/SocialMediaScreen';
//import PruebaDrupal from '../views/PruebaDrupal';

export const AllRoutes = [
	{
		path: '/',
		page: PrimaryScreen,
	},
	{
		path: '/historia-y-mision',
		page: HistoryMisionScreen,
	},
	{
		path: '/fundadores',
		page: FoundersScreen,
	},
	{
		path: '/sede',
		page: SiteScreen,
	},
	{
		path: '/videos-informativos',
		page: VideosScreen,
	},
	{
		path: '/contactenos',
		page: ContactScreen,
	},
	{
		path: '/proyectos-de-investigacion',
		page: ResearchScreen,
	},
	{
		path: '/exposiciones',
		page: ExhibitionsScreen,
	},
	{
		path: '/publicaciones',
		page: PublicationsScreen,
	},
	{
		path: '/reconocimientos',
		page: AcknowledgmentsScreen,
	},
	{
		path: '/donaciones',
		page: Donation,
	},
	{
		path: '/adquisiciones',
		page: AdquisicionesScreen,
	},
	{
		path: '/glifos',
		page: GLIFOSredirect,
	},
	{
		path: '/glifos/:any',
		page: GLIFOSredirect,
	},
	{
		path: '/noticias-y-novedades',
		page: NoticiasNovedadesScreen,
	},
	{
		path: '/noticia/:id/:title',
		page: NoticiaScreen,
	},
	{
		path: '/eventos',
		page: EventsScreen,
	},
	{
		path: '/evento/:id/:title',
		page: EventScreen,
	},
	{
		path: '/proyectos',
		page: ProjectsScreen,
	},
	{
		path: '/proyecto/:id/:title',
		page: ProjectScreen,
	},
	{
		path: '/clubes',
		page: OurClubsScreen,
	},
	{
		path: '/clubes/club-de-ajedrez-para-la-vida',
		page: ChessClubScreen,
	},
	{
		path: '/clubes/club-de-lectura-letras-entre-ruinas',
		page: ReadingClubScreen,
	},
	{
		path: '/clubes/club-de-lectura-infantil-pequenos-investigadores',
		page: ChildrenReadingClubScreen,
	},
	{
		path: '/club/:id/:title',
		page: ClubScreen,
	},
	{
		path: '/voluntarios-y-practicantes',
		page: VoluntariosPracticanesScreen,
	},
	{
		path: '/voluntarios-y-practicantes/:id/:title',
		page: MeetScreen,
	},
	{
		path: '/difusion-en-medios',
		page: MediaScreen,
	},
	{
		path: '/redes-sociales',
		page: SocialMediaScreen,
	},
	{
		path: '/boletines',
		page: BoletinesScreen,
	},
	{
		path: '/viewer/:goback/:id',
		page: PdfScreen,
	},
	{
		path: '*',
		page: NotFoundScreen,
	},
];
