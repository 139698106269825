import React from 'react';

import Fundadores from '../images/backgrounds/header-fundadores.jpg';
import HistoriaMision from '../images/backgrounds/header-historia-mision.jpg';
import Contactenos from '../images/backgrounds/header-contactenos.jpg';
import Sede from '../images/backgrounds/header-sede.jpg';
import VideosInformativos from '../images/backgrounds/header-videos-informativos.jpg';
import Exposiciones from '../images/backgrounds/header-exposiciones.jpg';
import Publicaciones from '../images/backgrounds/header-publicaciones.jpg';
import Reconocimientos from '../images/backgrounds/header-reconocimientos.jpg';
import Investigacion from '../images/backgrounds/header-investigacion.jpg';
import Biblioteca from '../images/biblioteca1.jpg';
import Fototeca from '../images/fototeca-guatemala.jpg';
import Archivos from '../images/archivo-historico1.jpg';
import SearchField from '../components/SearchField';
import Noticias from '../images/archivo-historico1.jpg';
import Donation from '../images/donaciones.jpg';
import Boletin from '../images/backgrounds/header-boletin.jpg';
import Difusion from '../images/backgrounds/header-difusion.jpg';
import Redes from '../images/backgrounds/header-redes-sociales.jpg';
import Ajedrez from '../images/backgrounds/header-club-ajedrez.jpg';
import LetrasEntreRuinas from '../images/backgrounds/header-letras-entre-ruinas.jpg';
import ClubLecturaNinos from '../images/backgrounds/header-club-lectura-ninos.jpg';
//import WBkey from './WBkey';
import Service from '../services/Api';

const backgrounds = {
	ajedrez: {
		image: Ajedrez,
		position: '0 -248px',
	},
	letras_entre_ruinas: {
		image: LetrasEntreRuinas,
		position: '0 -320px',
	},
	lectura_ninos: {
		image: ClubLecturaNinos,
		position: '0 -248px',
	},
	fundadores: {
		image: Fundadores,
		position: '0px -60px',
		asset: 'ob8DdlNj',
	},
	historia: {
		image: HistoriaMision,
		position: '0px -248px',
		asset: 'aCMZKXd7',
	},
	contactenos: {
		image: Contactenos,
		position: '0px -220px',
		asset: 'qHfWnBOj',
	},
	sede: {
		image: Sede,
		position: '0px -248px',
		asset: 'yeCy4fAO',
	},
	videos: {
		image: VideosInformativos,
		position: '0px -57px',
		asset: 'bMKxS2Zu',
	},
	exposiciones: {
		image: Exposiciones,
		position: '0px 0px',
		asset: 'Qn2NulLz',
	},
	publicaciones: {
		image: Publicaciones,
		position: '0px 0px',
		asset: 'aFuvoTU9',
	},
	reconocimientos: {
		image: Reconocimientos,
		position: '0px -105px',
		asset: 'ylixdFaS',
	},
	investigacion: {
		image: Investigacion,
		position: '0px -171px',
		asset: 'fWRBae2o',
	},
	biblioteca: {
		image: Biblioteca,
		position: 'center 200%',
	},
	fototeca: {
		image: Fototeca,
		position: 'center bottom',
	},
	archivos: {
		image: Archivos,
		position: 'center 200%',
	},
	noticias: {
		image: Noticias,
		position: 'center 200%',
	},
	donaciones: {
		image: Donation,
		position: 'center bottom',
	},
	boletin: {
		image: Boletin,
		position: 'center bottom',
	},
	difusion: {
		image: Difusion,
		position: 'center -181px',
	},
	redes: {
		image: Redes,
		position: '-49px -206px',
	},
};

export default function TopSimpleSection(props) {
	const { children, backgroundImage, search } = props;
	//console.log(backgrounds[backgroundImage]?.image);
	//const backimage = WBkey(backgrounds[backgroundImage]?.image)
	const asset = window.wb?.getAsset(backgrounds[backgroundImage]?.asset);
	const defaultImage = backgrounds[backgroundImage]?.image;
	//console.log(asset);
	return (
		<>
			{/* <WBkey wbkey= {backgrounds[backgroundImage]?.image}/> */}
			<section
				className='c-top-simple-section'
				style={{
					backgroundImage: `url(${
						asset?.href
							? Service.__getFileSrc(asset?.href)
							: defaultImage
					})`,
					backgroundPosition: backgrounds[backgroundImage]?.position,
				}}
			>
				{search && (
					<div className='c-search-cont'>
						<div className='c-search'>
							<SearchField placeholder='¿Qué desea buscar?' />
						</div>
					</div>
				)}
				{children}
			</section>
		</>
	);
}
