import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import AboutLayout from '../layouts/AboutLayout';

import SimpleSection from '../components/SimpleSection';
import WBkey from '../components/WBkey';

import Mas40A from '../images/masde40a.jpg';
import HnM1 from '../images/HistorynMision/01-007-179-para-la-pag-web.jpg';
import HnM2 from '../images/HistorynMision/01-007-180-para-la-pag-web.jpg';

import { removeRightClick } from '../utilities/util';
import Watcher from '../components/Watcher';

export default function HistoryMisionScreen(props) {
	const [showWatcher, setShowWatcher] = useState(false);
	const [link, setLink] = useState('');
	const [horizontal, setHorizontal] = useState(false);
	const [vertical, setVertical] = useState(false);
	return (
		<AboutLayout
			title={<WBkey wbkey='v00djg4X' />}
			backgroundImage='historia'
		>
			<SimpleSection className='c-hm-cirma'>
				<Container>
					<Row>
						<Col sm={12} lg={3}>
							<div className='c-tres-cirma'>
								<h2 className='c-cirma-title'>
									<WBkey wbkey='ETTIB024' />
								</h2>
								<div className='c-divisor'>
									<div className='c-line'></div>
								</div>
							</div>
						</Col>
						<Col sm={12} lg={7}>
							<div className='c-hm-info'>
								<WBkey wbkey='PZW8S3k9' />
							</div>
						</Col>
						<Col sm={12} lg={2}>
							<div className='c-img-cont'>
								<img
									src={Mas40A}
									alt='Más de 40 años de historia en Guatemala'
									onContextMenu={removeRightClick}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col
							sm={{ span: 12, order: 'last' }}
							lg={{ span: 6, order: 'first' }}
						>
							<Row className='mb-3'>
								<Col>
									<div
										className='c-img-cont'
										onClick={() => {
											setShowWatcher(true);
											setLink(HnM2);
											setHorizontal(true);
											setVertical(false);
										}}
									>
										<img
											className='style-image'
											src={HnM2}
											alt='Más de 40 años de historia en Guatemala'
											onContextMenu={removeRightClick}
										/>
									</div>
								</Col>
							</Row>
							<Row>
								<Col>
									<div
										className='c-img-cont'
										onClick={() => {
											setShowWatcher(true);
											setLink(HnM1);
											setHorizontal(true);
											setVertical(false);
										}}
									>
										<img
											className='style-image'
											src={HnM1}
											alt='Más de 40 años de historia en Guatemala'
											onContextMenu={removeRightClick}
										/>
									</div>
								</Col>
							</Row>
						</Col>
						<Col
							sm={{ span: 12, order: 'first' }}
							lg={{ span: 6, order: 'last' }}
							className='c-hm-historia'
						>
							<Row>
								<Col sm={12}>
									<div className='c-tres-cirma'>
										<h2 className='c-cirma-title'>
											<WBkey wbkey='wF9lS0AJ' />
										</h2>
										<div className='c-divisor'>
											<div className='c-line'></div>
										</div>
									</div>
								</Col>
							</Row>
							<Row>
								<Col sm={12}>
									<div className='c-hm-info'>
										<WBkey wbkey='fn7oJqVI' />
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
				<Watcher
					link={link}
					show={showWatcher}
					picture
					setShowWatcher={setShowWatcher}
					horizontal={horizontal}
					vertical={vertical}
				/>
			</SimpleSection>
		</AboutLayout>
	);
}
