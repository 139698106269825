import React from 'react';
import { Row } from 'react-bootstrap';
import OurClubCardLoading from './OurClubCardLoading/OurClubCardLoading';

export default function OurClubsScreenLoading() {
	return (
		<Row>
			{Array.from({ length: 4 }).map((_, index) => (
				<OurClubCardLoading key={index} />
			))}
		</Row>
	);
}
