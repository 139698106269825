import React from 'react';
import { Accordion } from 'react-bootstrap';
import CustomModal from './CustomModal';
import WBkey from './WBkey';

const donationData = [
	{
		title: 'MROeUejj',
		text: 'qI9rq39l',
	},
	{
		title: '0hdLebkU',
		text: 'Jn3YreUU',
	},
	{
		title: '98Ae12nU',
		text: 'OCmjJVSq',
	},
];

export default function DonateModal(props) {
	return (
		<CustomModal
			title={<WBkey wbkey='i_9NrB5E' />}
			showModal={props.showModal}
			setShowModal={props.setShowModal}
		>
			<Accordion>
				{donationData.map((item, index) => (
					<Accordion.Item key={index} eventKey={index}>
						<Accordion.Header>
							<WBkey wbkey={item.title} />
						</Accordion.Header>
						<Accordion.Body>
							<WBkey wbkey={item.text} />
						</Accordion.Body>
					</Accordion.Item>
				))}
			</Accordion>
		</CustomModal>
	);
}
