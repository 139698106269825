import React, { useState } from 'react';
import { Container, Button, Row } from 'react-bootstrap';
import { HeartFill } from 'react-bootstrap-icons';
import DonateModal from './DonateModal';
import TwitterX from './Icon/TwitterX';
export default function HeaderSection(props) {
	const [showModal, setShowModal] = useState(false);

	const {
		title,
		subtitle,
		button,
		colection,
		donation,
		socialMedia = false,
	} = props;
	return (
		<div className='c-top-header-section'>
			<Container>
				{!button && (
					<div className='c-top-header-container'>
						<div className='c-top-header-title'>
							<h1>{title}</h1>
						</div>
						{subtitle && (
							<div className='c-top-header-subtitle'>
								{subtitle}
							</div>
						)}
						<div className='c-divisor'>
							<div className='c-line'></div>
						</div>

						{socialMedia && (
							<>
								<Row className='flex-wrap w-100 justify-content-center text-align-center c-social-media-subtitle my-3'>
									Enlaces Directos
								</Row>
								<Row className='flex-wrap w-100 gap-5 justify-content-center '>
									<a
										href='https://www.facebook.com/cirma.guatemala'
										target='_blank'
										rel='noreferrer noopener'
										className='c-social-media-link'
									>
										<i className='fab fa-facebook'></i>
									</a>
									<a
										href='https://www.instagram.com/cirma.guatemala'
										target='_blank'
										rel='noreferrer noopener'
										className='c-social-media-link'
									>
										<i className='fab fa-instagram'></i>
									</a>
									<a
										href='https://twitter.com/CIRMAGUATEMALA'
										target='_blank'
										rel='noreferrer noopener'
										className='c-social-media-link'
									>
										<TwitterX
											width='19'
											height='19'
											color='#831131'
										/>
									</a>
									<a
										href='https://www.youtube.com/channel/UC8rBjONgDjb6BVZsDaj7WuQ'
										target='_blank'
										rel='noreferrer noopener'
										className='c-social-media-link'
									>
										<i className='fab fa-youtube'></i>
									</a>
								</Row>
							</>
						)}
						{donation && (
							<div>
								<Button
									variant='outline-primary'
									className='c-donation-button'
									onClick={() => {
										setShowModal(true);
									}}
								>
									<HeartFill /> DONAR
								</Button>
							</div>
						)}
					</div>
				)}
				{button && (
					<div className='c-top-header-section-button'>
						<div className='c-top-header-container'>
							{subtitle && (
								<div className='c-top-header-subtitle'>
									{subtitle}
								</div>
							)}
							<div className='c-top-header-title'>
								<h1>{title}</h1>
							</div>
							<div className='c-divisor'>
								<div className='c-line'></div>
							</div>
						</div>
						<div className='c-top-header-button'>
							<div>
								{colection && (
									<Button
										variant='outline-primary'
										className='c-button'
									>
										Lista de Colecciones
									</Button>
								)}
							</div>
						</div>
					</div>
				)}
			</Container>
			<DonateModal showModal={showModal} setShowModal={setShowModal} />
		</div>
	);
}
