import React from 'react';
import { Modal } from 'react-bootstrap';

export default function CustomModal(props) {
	const { title, children, showModal, setShowModal } = props;

	return (
		<Modal
			show={showModal}
			onHide={() => setShowModal(false)}
			size='lg'
			className='c-modal'
			centered
			aria-labelledby='modal-founder'>
			<Modal.Header
				className='c-modal-header'
				closeButton
				closeVariant='white'>
				<Modal.Title id='example-custom-modal-styling-title'>
					<h3 className='c-modal-title'>{title}</h3>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>{children}</Modal.Body>
		</Modal>
	);
}
