export const newsAndUpdates = [
	{
		url: '/noticias-y-novedades',
		title: 'Noticias y Novedades',
	},
];

export const eventsBreadcrumb = [
	...newsAndUpdates,
	{
		url: '/eventos',
		title: 'Eventos',
	},
];

export const clubsBreadcrumb = [
	...newsAndUpdates,
	{
		url: '/clubes',
		title: 'Nuestros clubes',
	},
];

export const acquisitionsBreadcrumb = [
	...newsAndUpdates,
	{
		url: '/adquisiciones',
		title: 'Adquisiciones',
	},
];

export const projectsBreadcrumb = [
	...newsAndUpdates,
	{
		url: '/proyectos',
		title: 'Proyectos',
	},
];

export const voluntierBreadcrumb = [
	...newsAndUpdates,
	{
		url: '/voluntarios-y-practicantes',
		title: 'Voluntarios y Practicantes',
	},
];
