import React, { useState, useEffect } from 'react';
import { Col, Card } from 'react-bootstrap';
import { CalendarFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { isValidURL, CustomAxios, removeRightClick } from '../utilities/util';

import { DRUPAL_URL, DRUPAL_HEADERS, BACKEND_URL } from '../utilities/globals';

export default function NewsCard(props) {
	const {
		image,
		title,
		subtitle,
		date,
		url,
		text,
		isURL = true,
		style = {},
	} = props;
	const navigate = useNavigate();
	const [imageData, setImageData] = useState();
	useEffect(() => {
		if (isValidURL(image)) {
			CustomAxios(
				'get',
				image,
				{},
				{ 'Content-Type': 'application/json', ...DRUPAL_HEADERS }
			).then((response) => {
				setImageData(response.success ? response.data.data : null);
			});
		}
	}, [image]);

	return (
		<Col sm={12} md={6} lg={4} className='c-news-card'>
			<Card
				className='c-card'
				onClick={() => {
					navigate(url);
				}}
			>
				<Card.Img
					variant='top'
					src={
						imageData
							? `${DRUPAL_URL}${imageData?.attributes?.uri?.url.replace(
									'/glifos',
									''
							  )}`
							: isURL
							? image
							: `${BACKEND_URL}${image}`
					}
					className='c-card-image'
					style={style}
					loading='lazy'
					onContextMenu={removeRightClick}
				/>
				<Card.Body className='c-card-body'>
					<Card.Subtitle className='c-card-subtitle'>
						{subtitle}
					</Card.Subtitle>
					<Card.Title className='c-card-title'>{title}</Card.Title>
					<Card.Subtitle className='c-card-date'>
						<CalendarFill /> {date}
					</Card.Subtitle>
					<div className='c-card-text'>{text}</div>
				</Card.Body>
			</Card>
		</Col>
	);
}
