import React, { useState } from 'react'
import { ChevronUp } from 'react-bootstrap-icons';

export default function Back2TopBtn() {
    const [scrollBtn, setScrollBtn] = useState(false);
    window.onscroll = function () {
        if (window.scrollY > 300) {
          setScrollBtn(true);
        } else {
          setScrollBtn(false);
        }
    };
    return (
        <>
        {scrollBtn && ( 
            <div
                className="btn btn-primary btn-floating btn-sm "
                id="btn-back-to-top"
                onClick={() => {
                    window.scrollTo(0, 0);
                }}
            >
                <ChevronUp style={{ stroke: "white", strokeWidth: "1" }} />
            </div>
        )}
        </>
    )
}
