import React from 'react';
import { Container } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
//import { useNavigate } from 'react-router-dom';
import SwiperCore, {
	Navigation,
	Pagination,
	Mousewheel,
	Keyboard,
} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { BACKEND_URL } from '../utilities/globals';

//import WBkey from './WBkey';

/* import s01 from '../images/slider01/01.jpg';
import s02 from '../images/slider01/02.jpg';
import s03 from '../images/slider01/03.jpg';
import s04 from '../images/slider01/04.jpg';
import s05 from '../images/slider01/05.jpg'; */
//import GLIFOSredirect from '../views/GLIFOSredirect';

SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard]);

const slides = [
	{
		url: '/glifos/collection/GT-CIRMA-FG-166',
		img:
			BACKEND_URL +
			'/v1/website/thumbnail/f6ad75eea73116ccd77b8ec17e70fcbc?w=480',
		name: 'Embajada de Guatemala en Washington sobre Guatemala',
	},
	{
		url: '/glifos/collection/GT-CIRMA-FG-165',
		img:
			BACKEND_URL +
			'/v1/website/thumbnail/7146fc7c716b29a439421c36e29b3af0?w=480',
		name: 'José María Mas sobre el terremoto de 1976 en Guatemala',
	},
	{
		url: '/glifos/collection/GT-CIRMA-FG-004',
		img:
			BACKEND_URL +
			'/v1/website/thumbnail/adfd9ff7f3118287fe7d2750845a47a4?w=480',
		name: 'Jacinto Rodríguez Díaz sobre el inicio de la aviación en Guatemala',
	},
	{
		url: '/glifos/collection/GT-CIRMA-FG-093',
		img:
			BACKEND_URL +
			'/v1/website/thumbnail/a39060a71ddebad67a0b548ecd8ecd6e?w=480',
		name: 'Archivo de Robert Hooton',
	},
	{
		url: '/glifos/collection/GT-CIRMA-FG-040',
		img:
			BACKEND_URL +
			'/v1/website/thumbnail/07a450aaa9b4688cd3c4773aca21bd0b?w=480',
		name: 'Fotografías de la Universidad de Pennsylvania sobre el Proyecto Tikal',
	},
	{
		url: '/glifos/collection/GT-CIRMA-FG-041',
		img:
			BACKEND_URL +
			'/v1/website/thumbnail/9673a9ecd772623a4bd223f1e7e6a89d?w=480',
		name: 'Museo Peabody sobre el sitio arqueológico Tikal',
	},
	{
		url: '/glifos/collection/GT-CIRMA-FG-036',
		img:
			BACKEND_URL +
			'/v1/website/thumbnail/d5575dcec7027e2853031b79c4a0b66c?w=480',
		name: 'Postales de Guatemala',
	},
	{
		url: '/glifos/collection/GT-CIRMA-FG-032',
		img:
			BACKEND_URL +
			'/v1/website/thumbnail/0060a8a40b3ee589d9e942f4bdde4433?w=480',
		name: 'British Museum sobre el sitio arqueológico Tikal',
	},
	{
		url: '/glifos/collection/GT-CIRMA-FG-022',
		img:
			BACKEND_URL +
			'/v1/website/thumbnail/19a4b7aa1fab9ddae453436f60ea172d?w=480',
		name: 'Ferrocarril Interoceánico de Guatemala',
	},
];

export default function SlidingGallery(props) {
	const { title } = props;
	//const navigate = useNavigate()
	return (
		<section className='c-sliding-gallery'>
			<Container>
				<h3 className='c-sg-title'>{title}</h3>
				<Swiper
					cssMode={true}
					navigation={true}
					spaceBetween={30}
					keyboard={true}
					freeMode={true}
					pagination={{
						clickable: true,
					}}
					breakpoints={{
						// when window width is >= 640px
						480: {
							width: 480,
							slidesPerView: 1,
						},
						640: {
							width: 640,
							slidesPerView: 2,
						},

						// when window width is >= 768px
						768: {
							width: 768,
							slidesPerView: 4,
						},
						1024: {
							width: 1024,
							slidesPerView: 4,
						},
					}}
					className='mySwiper'
				>
					{slides.map((slide, index) => (
						<SwiperSlide
							key={index}
							onClick={() => (window.location.href = slide.url)}
							style={{ cursor: 'pointer' }}
						>
							{/* <GLIFOSredirect/> */}
							<div
								style={{
									backgroundImage: 'url(' + slide.img + ')',
								}}
								className='c-slide-background'
							/>
							<div className='c-slide-text'>
								{/* <WBkey wbkey='BDU8do2K' /> */}
								{slide.name}
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			</Container>
		</section>
	);
}
