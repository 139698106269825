import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import TopMenu from '../components/Navbar/Navbar';
import SimpleSection from '../components/SimpleSection';
//import Footer from '../components/Footer';
import Footer from '../components/Footer/Footer';
import WBkey from '../components/WBkey';
import Subscribe from '../components/Subscribe';
import HeaderImage from '../components/HeaderImage';
import RegresarButton from '../components/RegresarButton';
import ShareButtons from '../components/ShareButtons';
import LastContent from '../components/LastContent';

import { DRUPAL_HEADERS, DRUPAL_URL } from '../utilities/globals';
import { CustomAxios, isValidURL } from '../utilities/util';
import Back2TopBtn from '../components/Back2TopBtn';
import BreadcrumbComponent from '../components/Breadcrumb/Breadcrumb';

import Axios from '../services/Api';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Autoplay } from 'swiper';
import EventCard from '../views/EventsScreen/EventsScreenContent/EventCard/EventCard';
import SwipperCards from '../components/SwipperCards/SwipperCards';

export default function NoticiaLayout(props) {
	const {
		children,
		backgroundImage,
		autor,
		date,
		title,
		goback,
		sidebar,
		breadcrumb,
	} = props;
	const [imageData, setImageData] = useState(null);

	useEffect(() => {
		if (isValidURL(backgroundImage)) {
			CustomAxios('get', backgroundImage, {}, { ...DRUPAL_HEADERS })
				.then((response) => {
					if (response.success) {
						setImageData(response.data.data);
					}
				})
				.catch((error) => {
					console.log(error);
					setImageData(null);
				});
		}
	}, [backgroundImage]);

	const SideNews = ({ type }) => {
		switch (type) {
			case 'eventos':
				return (
					<>
						<Row className='justify-content-center align-items-center'>
							<h3 className='c-cirma-subtitle text-center'>
								Próximos eventos
							</h3>
						</Row>
						<Row className='justify-content-center p-1'>
							<RecentEventsCards />
						</Row>
					</>
				);
			case 'clubes':
				return (
					<>
						<Row className='justify-content-center align-items-center'>
							<h3 className='c-cirma-subtitle text-center'>
								Contenidos de nuestros clubes
							</h3>
						</Row>
						<Row className='justify-content-center p-1'>
							<SwipperCards
								route='club'
								apiRoute='GetRecentClubs'
								subtitle='Nuestros Clubes'
								type='club'
							/>
						</Row>
					</>
				);
			case 'noticia':
				return (
					<>
						<Row className='justify-content-center align-items-center'>
							<h3 className='c-cirma-subtitle text-center'>
								Otras adquisiciones
							</h3>
						</Row>
						<Row className='justify-content-center p-1'>
							<SwipperCards
								route='noticia'
								apiRoute='GetRecentAcquisitions'
								subtitle='Adquisiciones'
								type='noticia'
							/>
						</Row>
					</>
				);
			case 'proyecto':
				return (
					<>
						<Row className='justify-content-center align-items-center'>
							<h3 className='c-cirma-subtitle text-center'>
								Otros proyectos
							</h3>
						</Row>
						<Row className='justify-content-center p-1'>
							<SwipperCards
								route='proyecto'
								apiRoute='GetRecentProjects'
								subtitle='Proyectos'
								type='proyecto'
							/>
						</Row>
					</>
				);
			case 'voluntario':
				return (
					<>
						<Row className='justify-content-center align-items-center'>
							<h3 className='c-cirma-subtitle text-center'>
								Más contenidos de voluntarios y practicantes
							</h3>
						</Row>
						<Row className='justify-content-center p-1'>
							<SwipperCards
								route='voluntarios-y-practicantes'
								apiRoute='GetRecentProjects'
								subtitle='Voluntarios y practicantes'
								type='voluntarios_practicantes'
							/>
						</Row>
					</>
				);
			default:
				return <LastContent title={title}></LastContent>;
		}
	};

	return (
		<div className='c-main-wrapper'>
			<TopMenu background='white' light='navbar-light' dark='' />
			{/* <TopMenu/> */}
			{/* <TopSimpleSection backgroundImage={backgroundImage} search={search} /> */}
			<SimpleSection>
				<Container>
					<Row>
						<HeaderImage
							backgroundImage={
								imageData
									? `${DRUPAL_URL}${imageData?.attributes?.uri?.url.replace(
											'/glifos',
											''
									  )}`
									: backgroundImage
							}
						/>
					</Row>
				</Container>
			</SimpleSection>
			<SimpleSection>
				<Container>
					<Row>
						<BreadcrumbComponent items={breadcrumb} />
					</Row>
					<Row>
						<Col sm={12} md={8}>
							<RegresarButton
								link={goback}
								autor={autor}
								date={date}
							/>
							<h1 className='c-content-title'>{title}</h1>
							{children}
							<ShareButtons />
						</Col>
						<Col sm={12} md={4}>
							<SideNews type={sidebar} />
						</Col>
					</Row>
				</Container>
			</SimpleSection>
			<SimpleSection className='c-cirma-conectado'>
				<Container>
					<Row>
						<Col className='c-mantcont'>
							<h2>
								<WBkey wbkey='2kYIVfKT' />
							</h2>
						</Col>
					</Row>
					<Row>
						<Col className='c-desc'>
							<WBkey wbkey='lvteTzsp' />
						</Col>
					</Row>
					<Row className='c-row-form'>
						<Col className='c-col-form'>
							<Subscribe />
						</Col>
					</Row>
				</Container>
			</SimpleSection>
			<Back2TopBtn />
			<Footer />
		</div>
	);
}

function RecentEventsCards() {
	const [events, setEvents] = useState({
		isLoading: false,
		isError: false,
		error: '',
		payload: [],
	});
	useEffect(() => {
		const fetchData = async () => {
			try {
				setEvents({
					isLoading: true,
					isError: false,
					error: '',
					payload: [],
				});
				const { data } = await Axios.GetRecentEvents();
				if (!Boolean(data?.success)) {
					throw new Error(
						data?.message ?? 'Error al obtener los eventos'
					);
				}
				setEvents((prevData) => ({
					...prevData,
					payload: data?.data ?? [],
				}));
			} catch (error) {
				setEvents({
					isLoading: false,
					isError: true,
					error: error?.message ?? 'Error al obtener los eventos',
					payload: [],
				});
			} finally {
				setEvents((prevData) => ({
					...prevData,
					isLoading: false,
				}));
			}
		};
		fetchData();
	}, []);
	return (
		<Swiper
			modules={[Navigation, Pagination, Autoplay, Mousewheel]}
			slidesPerView={1}
			onSlideChange={() => console.log('slide change')}
			cssMode
			keyboard
			freeMode
			autoplay={{ delay: 7000 }}
			navigation={{
				clickable: true,
			}}
			mousewheel={true}
			pagination={{
				clickable: true,
			}}
			onSwiper={(swiper) => console.log(swiper)}
			style={{
				color: 'red',
			}}
			className={`p-0 swiper-events`}
		>
			{events.payload.map((event) => {
				return (
					<SwiperSlide
						key={event.uuid}
						className={`p-0 d-flex justify-content-center`}
					>
						<EventCard key={event.uuid} event={event} />
					</SwiperSlide>
				);
			})}
		</Swiper>
	);
}
