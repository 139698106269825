import NewsCard from '../../../../components/NewsCard';
import { cleanText, getDateFormat } from '../../../../utilities/util';

export default function OurClubCard({ club }) {
	return (
		<NewsCard
			image={club?.relationships?.field_portada?.links?.related?.href}
			subtitle='Nuestros Clubes'
			title={club?.attributes?.title}
			date={getDateFormat(
				club?.attributes?.field_fecha_publicacion,
				'DD de month de YYYY'
			)}
			url={`/club/${club?.attributes?.drupal_internal__nid}/${cleanText(
				club?.attributes?.title
			)}`}
			text={
				<span
					dangerouslySetInnerHTML={{
						__html:
							club?.attributes?.field_parrafos_1[0]?.processed ??
							club?.attributes?.field_parrafos_2[0]?.processed ??
							club?.attributes?.field_parrafos_3[0]?.processed,
					}}
				/>
			}
		/>
	);
}
