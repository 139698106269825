import React, { useState } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';
import { Calendar3 } from 'react-bootstrap-icons';
import { DRUPAL_URL } from '../../../../utilities/globals';

function validarFechaHora(
	fecha,
	horaInicial = null,
	fechaFinal = null,
	horaFinal = null
) {
	console.log(fecha, horaInicial, fechaFinal, horaFinal);
	try {
		// Expresión regular para validar el formato de la fecha y la hora
		const regexFecha = /^\d{4}-\d{2}-\d{2}$/;
		const regexHora = /^\d{2}:\d{2}$/;

		if (!regexFecha.test(fecha)) {
			throw new Error(
				"Formato de fecha inválido. Debe ser 'YYYY-MM-DD'."
			);
		}

		if (horaFinal && !horaInicial) {
			throw new Error(
				'Si se proporciona la hora final, también se debe proporcionar la hora inicial.'
			);
		}

		if (horaInicial && !regexHora.test(horaInicial)) {
			throw new Error(
				"Formato de hora inicial inválido. Debe ser 'HH:MM'."
			);
		}

		if (fechaFinal && !regexFecha.test(fechaFinal)) {
			throw new Error(
				"Formato de fecha final inválido. Debe ser 'YYYY-MM-DD'."
			);
		}

		if (horaFinal && !regexHora.test(horaFinal)) {
			throw new Error(
				"Formato de hora final inválido. Debe ser 'HH:MM'."
			);
		}

		const date = new Date(fecha + 'T00:00');

		if (isNaN(date.getTime())) {
			throw new Error('Fecha inválida.');
		}

		// Configuración para el formato de fecha y hora en español
		const optionsDate = {
			weekday: 'long',
			year: 'numeric',
			month: 'long',
			day: 'numeric',
			timeZone: 'America/Guatemala',
		};
		const optionsTime = {
			hour: '2-digit',
			minute: '2-digit',
			timeZone: 'America/Guatemala',
		};

		// Obteniendo la fecha y la hora en el formato localizado
		let fechaLocalizada = date.toLocaleDateString('es-ES', optionsDate);
		fechaLocalizada =
			fechaLocalizada.charAt(0).toUpperCase() + fechaLocalizada.slice(1);

		if (!horaInicial) {
			return fechaLocalizada;
		}

		let horaLocalizada = new Date(
			fecha + 'T' + horaInicial
		).toLocaleTimeString('es-ES', optionsTime);

		if (!horaFinal && fecha === fechaFinal) {
			return `${fechaLocalizada} a las ${horaLocalizada}hrs`;
		}

		let horaFinalLocalizada = new Date(
			fecha + 'T' + horaFinal
		).toLocaleTimeString('es-ES', optionsTime);

		if (fecha === fechaFinal) {
			if (
				horaInicial === horaFinal ||
				!horaFinal ||
				horaFinal === '00:00' ||
				horaInicial > horaFinal
			)
				return `${fechaLocalizada} a las ${horaLocalizada}hrs`;
			return `${fechaLocalizada} de las ${horaLocalizada}hrs a las ${horaFinalLocalizada}hrs`;
		} else {
			const fechafinalizada = new Date(
				fechaFinal + 'T00:00'
			).toLocaleDateString('es-ES', optionsDate);
			let horaFinalLocalizada = new Date(
				fechaFinal + 'T' + horaFinal
			).toLocaleTimeString('es-ES', optionsTime);

			return `Desde ${fechaLocalizada} a las ${horaLocalizada}hrs al ${fechafinalizada} a las ${horaFinalLocalizada}hrs`;
		}
	} catch (error) {
		console.error(error);
		return error.message;
	}
}

function getContrastColor(hexcolor) {
	hexcolor = hexcolor.replace('#', '');
	const r = parseInt(hexcolor.substr(0, 2), 16);
	const g = parseInt(hexcolor.substr(2, 2), 16);
	const b = parseInt(hexcolor.substr(4, 2), 16);
	const yiq = (r * 299 + g * 587 + b * 114) / 1000;
	return yiq >= 128 ? 'black' : 'white';
}

function getButtonColor(hexcolor) {
	const colorContrast = getContrastColor(hexcolor);
	return colorContrast === 'white' ? 'outline-light' : 'outline-dark';
}

export default function EventCard({ event }) {
	console.log(event);
	const [isHovered, setIsHovered] = useState(false);
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	return (
		<>
			<Card
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
				onClick={handleShow}
				style={{
					width: '18.75rem',
					minHeight: '31.25rem',
					cursor: 'pointer',
				}}
				className='p-0'
			>
				<Card.Img
					variant='top'
					src={`${DRUPAL_URL}/glifos/file/get/thumbnail/${event.file_id}/400`}
					loading='lazy'
					height={240}
					style={{
						objectFit: 'cover',
						objectPosition: 'center',
						filter: isHovered ? 'brightness(0.8)' : 'brightness(1)',
					}}
				/>
				<Card.Body
					className='m-0 p-2rem'
					style={{
						backgroundColor: event?.background ?? 'initial',
						color: event?.background
							? getContrastColor(event?.background)
							: 'initial',
					}}
				>
					<Card.Text className='d-flex flex-row justify-content-center align-items-center'>
						<Calendar3 size='38px' style={{ aspectRatio: '1/1' }} />
					</Card.Text>
					<Card.Title
						className='d-flex flex-row justify-content-center align-items-center flex-wrap gap-2 text-center'
						style={{}}
					>
						{validarFechaHora(
							event.initial_date,
							event.initial_time,
							event.final_date,
							event.final_time
						)}
					</Card.Title>
					<Card.Text className='d-flex flex-row justify-content-center align-items-center flex-wrap gap-2'>
						{event.place}
					</Card.Text>
					<div className='m-0 p-0 d-flex flex-row justify-content-center align-items-center flex-wrap'>
						<Button
							variant={
								event?.background
									? getButtonColor(event.background)
									: 'primary'
							}
							xs={8}
							onClick={handleShow}
						>
							Más Información
						</Button>
					</div>
				</Card.Body>
			</Card>
			<Modal show={show} onHide={handleClose} centered size='lg'>
				<Modal.Header closeButton className='border-bottom-0 p-4 pb-4'>
					{event?.title && (
						<Modal.Title className='c-cirma-title' as={'h2'}>
							{event.title}
						</Modal.Title>
					)}
				</Modal.Header>
				<Modal.Body className='p-4 pt-2 '>
					<div
						dangerouslySetInnerHTML={{ __html: event.description }}
					/>
				</Modal.Body>
			</Modal>
		</>
	);
}
