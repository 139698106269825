import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
	Navigation,
	Pagination,
	Mousewheel,
	Keyboard,
	Autoplay,
} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { socialOptions } from '../data/SocialOptions';
import WBkey from './WBkey';
SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard, Autoplay]);

export default function SocialSwiper() {
	const navigate = useNavigate();
	return (
		<Container className='c-social-swiper'>
			<h3 className='c-social-title'>
				<WBkey wbkey='kgHLQflP' />
			</h3>
			<Swiper
				cssMode={true}
				navigation={true}
				keyboard={true}
				freeMode={true}
				/* loop={true} */ pagination={{
					clickable: true,
				}}
				breakpoints={{
					// when window width is >= 640px
					480: {
						width: 480,
						slidesPerView: 1,
					},
					640: {
						width: 640,
						slidesPerView: 2,
					},

					// when window width is >= 768px
					768: {
						width: 768,
						slidesPerView: 3,
						spaceBetween: 30,
					},
					992: {
						width: 992,
						slidesPerView: 5,
						spaceBetween: 40,
					},
					1024: {
						width: 1024,
						slidesPerView: 5,
						spaceBetween: 50,
					},
				}}
				autoplay={{ delay: 4000 }}
				className='justify-content-center align-items-center'
			>
				{socialOptions
					.filter((option) => option.show)
					.map((option, index) => (
						<SwiperSlide className='c-swiper-slide' key={index}>
							<div
								className='c-social-options'
								onClick={() => {
									navigate(option.url);
								}}
							>
								<img src={option.icon} alt={option.name} />
								<h5 className='c-swiper-name'>{option.name}</h5>
							</div>
						</SwiperSlide>
					))}
			</Swiper>
		</Container>
	);
}
