import React from 'react';
import { SearchIcon } from '@react-pdf-viewer/search';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { MoreActionsPopover } from '@react-pdf-viewer/toolbar';
import { SelectionMode } from '@react-pdf-viewer/selection-mode';
import es_ES from '@react-pdf-viewer/locales/lib/es_ES.json';
import GLIFOSSearchSidebar from './GLIFOSSearchSidebar';
//import { selectionModePlugin } from '@react-pdf-viewer/selection-mode';

// Import styles
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { BACKEND_URL } from '../utilities/globals';
export default function GLIFOSPdfViewer(props) {
	const { id } = props;

	const renderToolbar = (Toolbar) => (
		<Toolbar>
			{(slots) => {
				//console.log(slots);
				const {
					CurrentPageInput,
					EnterFullScreen,
					GoToNextPage,
					GoToPreviousPage,
					NumberOfPages,
					Rotate,
					SwitchTheme,
					Zoom,
					ZoomIn,
					ZoomOut,
				} = slots;
				const moreActions = {
					...slots,
					PrintMenuItem: () => <></>,
					OpenMenuItem: () => <></>,
					DownloadMenuItem: () => <></>,
					SwitchSelectionModeMenuItem: () => <></>,
					SwitchSelectionMode: () => <></>,
				};
				return (
					<div
						data-testid='toolbar'
						className='rpv-toolbar'
						role='toolbar'
						aria-orientation='horizontal'
					>
						<div className='rpv-toolbar__left'>
							<div className='rpv-toolbar__item'>
								<GoToPreviousPage />
							</div>
							<div className='rpv-toolbar__item'>
								<CurrentPageInput />
								<span className='rpv-toolbar__label'>
									{' / '}
									<NumberOfPages />
								</span>
							</div>
							<div className='rpv-toolbar__item'>
								<GoToNextPage />
							</div>
						</div>
						<div className='rpv-toolbar__center'>
							<div className='rpv-toolbar__item'>
								<ZoomOut />
							</div>
							<div className='rpv-toolbar__item'>
								<Zoom />
							</div>
							<div className='rpv-toolbar__item'>
								<ZoomIn />
							</div>
						</div>
						<div className='rpv-toolbar__right'>
							<div className='rpv-toolbar__item d-none d-md-flex'>
								<SwitchTheme />
							</div>
							<div className='rpv-toolbar__item'>
								<EnterFullScreen />
							</div>
							<div className='rpv-toolbar__item'>
								<Rotate />
							</div>
							<div className='rpv-toolbar__item'>
								<MoreActionsPopover toolbarSlot={moreActions} />
							</div>
						</div>
					</div>
				);
			}}
		</Toolbar>
	);
	const defaultLayoutPluginInstance = defaultLayoutPlugin({
		sidebarTabs: (defaultTabs) => [
			{
				content: (
					<GLIFOSSearchSidebar
						searchPluginInstance={
							defaultLayoutPluginInstance.toolbarPluginInstance
								.searchPluginInstance
						}
					/>
				),
				icon: <SearchIcon />,
				title: 'Buscar',
			},
			defaultTabs[0],
		],
		renderToolbar,
		toolbarPlugin: {
			selectionModePlugin: {
				selectionMode: SelectionMode.Hand,
			},
		},
	});

	return (
		<div className='gpdfv'>
			<Worker workerUrl='https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js'>
				<div style={{ height: '697px' }}>
					<Viewer
						fileUrl={BACKEND_URL + '/v1/website/file/' + id}
						plugins={[defaultLayoutPluginInstance]}
						localization={es_ES}
					/>
				</div>
			</Worker>
		</div>
	);
}
