import React from 'react';
import { Row } from 'react-bootstrap';
import MessageBox from '../../../components/MessageBox';

export default function OurClubsScreenError({ error }) {
	return (
		<Row>
			<MessageBox severity='danger'>{error}</MessageBox>
		</Row>
	);
}
