import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

/* import HeaderSection from '../components/HeaderSection';
import TopSimpleSection from '../components/TopSimpleSection'; */
import TopMenu from '../components/Navbar/Navbar';
import SimpleSection from '../components/SimpleSection';
//import Footer from '../components/Footer';
import Footer from '../components/Footer/Footer';
import WBkey from '../components/WBkey';
import Subscribe from '../components/Subscribe';
import Back2TopBtn from '../components/Back2TopBtn';

export default function BasicLayout(props) {
	const { children } = props;
	return (
		<div className='c-main-wrapper'>
			<TopMenu background='white' light='navbar-light' dark='' />
			{/* <TopMenu/> */}
			{/* <TopSimpleSection backgroundImage={backgroundImage} search={search} /> */}
			{children}
			<SimpleSection className='c-cirma-conectado'>
				<Container>
					<Row>
						<Col className='c-mantcont'>
							<h2>
								<WBkey wbkey='2kYIVfKT' />
							</h2>
						</Col>
					</Row>
					<Row>
						<Col className='c-desc'>
							<WBkey wbkey='lvteTzsp' />
						</Col>
					</Row>
					<Row className='c-row-form'>
						<Col className='c-col-form'>
							<Subscribe />
						</Col>
					</Row>
				</Container>
			</SimpleSection>
			<Back2TopBtn/>
			<Footer />
		</div>
	);
}
