import React, { useEffect, useState /* , { useEffect }  */ } from 'react';
/* import EventCard from '../components/EventCard'; */
import BasicLayout from '../../layouts/BasicLayout';
import SimpleSection from '../../components/SimpleSection';
import { Button, Col, Container, Row, Card } from 'react-bootstrap';
import { ArrowLeft } from 'react-bootstrap-icons';
import OurClubsScreenLoading from './OurClubsScreenLoading/OurClubLoading';
import OurClubsScreenError from './OurClubsScreenError/OurClubsScreenError';

import OurClubsScreenNoContent from './OurClubsScreenNoContent/OurClubsScreenNoContent';
import OurClubsScreenContent from './OurClubsScreenContent/OurClubsScreenContent';
import BreadcrumbComponent from '../../components/Breadcrumb/Breadcrumb';
import { newsAndUpdates } from '../../data/breadcrumbsData';
import { AxiosDrupal, removeRightClick } from '../../utilities/util';

import Ajedrez from '../../images/clubs/portada_club_ajedrez.jpg';
import LetrasEntreRuinas from '../../images/clubs/letras_entre_ruinas.jpg';
import LecturaNinos from '../../images/clubs/header_club_lectura_ninos.jpg';

import styles from './OurClubsScreen.module.scss';

const cirmaClubs = [
	{
		id: 1,
		title: 'Club de Ajedrez “Ajedrez para la Vida”',
		description:
			'“Ajedrez para la Vida” es el nombre de la metodología aplicada por los maestros y entrenadores de la Federación Internacional de Ajedrez (FIDE) Wilson Estuardo Lorenzana Aguilar y Sergio Miguel García Fuentes y practicada en el Club de Ajedrez que se fundó en CIRMA en abril del 2022.',
		img: Ajedrez,
		href: '/clubes/club-de-ajedrez-para-la-vida',
	},
	{
		id: 2,
		title: 'Club de lectura “Letras entre Ruinas”',
		description:
			'La lectura es la puerta a otros mundos, a otros tiempos, a otras realidades. Despierta y agudiza los sentidos, la mente se abre y vuela tan alto como los libros anteriores lo permiten. El club de lectura Letras entre Ruinas nace como ese grupo de personas, lectoras o no, que encuentran en la imaginación un escape a la realidad cotidiana. Fundado en el 2018, desde entonces hemos compartido más de 70 libros, de toda índole, que nos han invitado a volar. CIRMA ha sido nuestro hogar desde los inicios, brindándonos un valioso punto de encuentro, físico y académico, que nos permite llegar más lejos y mejor.',
		img: LetrasEntreRuinas,
		href: '/clubes/club-de-lectura-letras-entre-ruinas',
	},
	{
		id: 3,
		title: 'Club de lectura infantil “Pequeños Investigadores”',
		description:
			'Imagina un lugar donde la imaginación cobra vida, donde las páginas de los libros se convierten en portales a mundos mágicos y donde la amistad se fortalece a través de las historias compartidas.',
		img: LecturaNinos,
		href: '/clubes/club-de-lectura-infantil-pequenos-investigadores',
	},
];

export default function OurClubsScreen() {
	const [clubs, setClubs] = useState({
		isLoading: false,
		isError: false,
		error: '',
		payload: [],
	});

	useEffect(() => {
		setClubs((prevData) => ({
			isLoading: true,
			isError: false,
			error: '',
			payload: [],
		}));
		AxiosDrupal('GetClubs')
			.then(({ success, data, error }) => {
				if (success && data?.data.length > 0)
					setClubs({
						isLoading: false,
						isError: false,
						error: '',
						payload: data.data,
					});
				else if (success)
					setClubs({
						isLoading: false,
						isError: false,
						error: '',
						payload: [],
					});
				else
					setClubs({
						isLoading: false,
						isError: true,
						error: error,
						payload: [],
					});
			})
			.catch((error) => {
				console.log(error);
				setClubs({
					isLoading: false,
					isError: true,
					error: error,
					payload: [],
				});
			});
	}, []);

	return (
		<BasicLayout>
			<SimpleSection>
				<Container className='c-news-updates'>
					<Row className='justify-content-between align-items-center mb-4'>
						<Button
							href={'/noticias-y-novedades'}
							variant='transparent'
							className='c-btn-goback w-auto'
						>
							<div className='c-btn-content'>
								<ArrowLeft /> Regresar
							</div>
						</Button>
						<div className='w-auto align-items-center'>
							<BreadcrumbComponent
								items={[
									...newsAndUpdates,
									{
										url: '/eventos',
										title: 'Nuestros clubes',
									},
								]}
							/>
						</div>
					</Row>
					<Row className='mb-4'>
						{cirmaClubs.map((club) => (
							<Col xs={12} md={6} lg={4} key={club.id}>
								<Card
									as={'a'}
									className={styles.cardClub}
									href={club.href}
								>
									<Card.Img
										variant='top'
										src={club.img}
										className={styles.cardClubImg}
										loading='lazy'
										onContextMenu={removeRightClick}
									/>
									<Card.Body className={styles.cardClubBody}>
										<Card.Title className='c-title'>
											{club.title}
										</Card.Title>
										<Card.Text
											className={styles.cardClubText}
										>
											{club.description}
										</Card.Text>
									</Card.Body>
								</Card>
							</Col>
						))}
					</Row>
					<Row className='mb-3 justify-content-center'>
						<h2 className='c-title text-center'>
							Contenidos de nuestros clubes
						</h2>
					</Row>
					{clubs.isLoading && <OurClubsScreenLoading />}
					{!clubs.isLoading && clubs.isError && (
						<OurClubsScreenError error={clubs.error} />
					)}
					{!clubs.isLoading &&
						!clubs.error &&
						clubs.payload.length === 0 && (
							<OurClubsScreenNoContent />
						)}
					{!clubs.isLoading &&
						!clubs.error &&
						clubs.payload.length > 0 && (
							<OurClubsScreenContent clubs={clubs.payload} />
						)}
				</Container>
			</SimpleSection>
		</BasicLayout>
	);
}
