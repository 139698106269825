import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

import AboutLayout from '../../../layouts/AboutLayout';

import SimpleSection from '../../../components/SimpleSection';

import ChessImage from '../../../images/clubs/ajedrez/imagen_ajedrez.jpg';
import { removeRightClick } from '../../../utilities/util';
//import WBkey from '../../../components/WBkey';
import { ArrowLeft } from 'react-bootstrap-icons';
import DirectContact from '../DirectContact/DirectContact';
import WBkey from '../../../components/WBkey';

export default function ChessClubScreen(props) {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<AboutLayout
			title={
				<div className='p-0 m-0 text-center'>
					<WBkey wbkey='A7g_LQGM' />
					<br />
					<WBkey wbkey='NB0o0ba0' />
				</div>
			}
			backgroundImage='ajedrez'
			noMarginTop
		>
			<Container>
				<SimpleSection className={'mt-2'}>
					<Row className='justify-content-between align-items-center mb-2'>
						<Button
							href={'/clubes'}
							variant='transparent'
							className='c-btn-goback w-auto'
						>
							<div className='c-btn-content'>
								<ArrowLeft /> Regresar
							</div>
						</Button>
						{/* <div className='w-auto align-items-center'>
							<BreadcrumbComponent
								items={[
									...newsAndUpdates,
									{ url: '/eventos', title: 'Eventos' },
								]}
							/>
						</div> */}
					</Row>
					<Row>
						<Col xs={12} md={6} lg={4}>
							<div className='c-img-cont'>
								<img
									src={ChessImage}
									alt='Club de Ajedrez "Ajedrez para la vida"'
									loading='lazy'
									onContextMenu={removeRightClick}
								/>
							</div>
						</Col>
						<Col xs={12} md={6} lg={8}>
							<div className='d-flex flex-column'>
								<h2
									className='c-cirma-title'
									style={{ fontSize: '24px' }}
								>
									<WBkey wbkey='NB0o0ba0' />
								</h2>
								<p className='mt-0 fw-500' wbk='nOpa2EVU'>
									<WBkey wbkey='nOpa2EVU' variant='plain' />
								</p>
								<p className='mt-0 fw-500' wbk='I3o7PicX'>
									<WBkey wbkey='I3o7PicX' variant='plain' />
								</p>
								<p className='mt-0 fw-500' wbk='BJgbAXGQ'>
									<WBkey wbkey='BJgbAXGQ' variant='plain' />
								</p>
							</div>
						</Col>
					</Row>
					<Row className='justify-content-center mt-4'>
						<h2 className='c-cirma-title text-center'>
							<WBkey wbkey='ZvNk66t0' />
						</h2>
					</Row>
					<Row className='flex-column mt-2'>
						<p className='fw-500' wbk='HQTra_H_'>
							<WBkey wbkey='HQTra_H_' variant='plain' />
						</p>
					</Row>
					<Row className='justify-content-around flex-wrap gap-4'>
						<div className='d-flex flex-column w-auto align-items-center'>
							<i
								aria-hidden='true'
								className='fas fa-chess-pawn color-primary mb-4'
								style={{
									fontSize: '50px',
								}}
							></i>
							<h3 className='c-cirma-subtitle'>
								<WBkey wbkey='BcVGYT4u' />
							</h3>
							<p className='m-0 p-0 fw-500' wbk='Iux2yVo6'>
								<WBkey wbkey='Iux2yVo6' variant='plain' />
							</p>
						</div>
						<div className='d-flex flex-column w-auto align-items-center'>
							<i
								aria-hidden='true'
								className='fas fa-chess-knight color-primary mb-4'
								style={{
									fontSize: '50px',
								}}
							></i>
							<h3 className='c-cirma-subtitle'>
								<WBkey wbkey='zH1W_Fr3' />
							</h3>
							<p className='m-0 p-0 fw-500' wbk='MKrw6E5a'>
								<WBkey wbkey='MKrw6E5a' variant='plain' />
							</p>
						</div>
						<div className='d-flex flex-column w-auto align-items-center'>
							<i
								aria-hidden='true'
								className='fas fa-chess-king color-primary mb-4'
								style={{
									fontSize: '50px',
								}}
							></i>
							<h3 className='c-cirma-subtitle'>
								<WBkey wbkey='4qUF8bku' />
							</h3>
							<p className='m-0 p-0 fw-500' wbk='aLMWnJfH'>
								<WBkey wbkey='aLMWnJfH' variant='plain' />
							</p>
						</div>
					</Row>
				</SimpleSection>
			</Container>
			<DirectContact
				subject={WBkey({ variant: 'plain', wbkey: '8QJtzGsL' })}
				message={WBkey({ variant: 'plain', wbkey: 'I4pzlxsa' })}
			/>
		</AboutLayout>
	);
}
