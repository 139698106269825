import React from 'react';
import { Button } from 'react-bootstrap';
import { ArrowLeft, PersonCircle, CalendarFill } from 'react-bootstrap-icons';
export default function RegresarButton(props) {
	const { link, autor, date } = props;
	return (
		<div className='c-container'>
			{/* <Button href={'/' + link} variant='transparent' className='c-regresar-button' > */}
			<Button
				href={'/' + link}
				variant='transparent'
				className='c-regresar-button'
			>
				<div className='c-button-content'>
					<ArrowLeft /> Regresar
				</div>
			</Button>
			{autor && (
				<div className='c-author'>
					<div className='c-icon'>
						<PersonCircle />
					</div>
					{autor}
				</div>
			)}
			{date && (
				<div className='c-date'>
					<div className='c-icon'>
						<CalendarFill />
					</div>
					{date}
				</div>
			)}
		</div>
	);
}
