import React, { useState, useEffect } from 'react';
import ImageGroup from './ImageGroup';
import { DRUPAL_URL, DRUPAL_HEADERS } from '../utilities/globals';
import { CustomAxios } from '../utilities/util';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export default function DrupalGalery(props) {
	const { url, info } = props;
	const [galery, setGalery] = useState([]);
	const [index, setIndex] = useState(null);

	useEffect(() => {
		if (url)
			CustomAxios(
				'get',
				url,
				{},
				{ 'Content-Type': 'application/json', ...DRUPAL_HEADERS }
			).then((response) => {
				if (response.success) {
					setGalery(response.data.data);
				} else setGalery([]);
			});
	}, [url]);

	return (
		<ImageGroup>
			{index !== null && (
				<Lightbox
					reactModalStyle={'z-index: 3000;'}
					mainSrc={`${DRUPAL_URL}${galery[
						index
					]?.attributes?.uri?.url.replace('/glifos', '')}`}
					nextSrc={`${DRUPAL_URL}${galery[
						(index + 1) % galery.length
					]?.attributes?.uri?.url.replace('/glifos', '')}`}
					prevSrc={`${DRUPAL_URL}${galery[
						(index - 1) % galery.length
					]?.attributes?.uri?.url.replace('/glifos', '')}`}
					onCloseRequest={() => setIndex(null)}
					onMovePrevRequest={() =>
						setIndex((index + galery.length - 1) % galery.length)
					}
					onMoveNextRequest={() =>
						setIndex((index + 1) % galery.length)
					}
				/>
			)}
			{galery?.length > 0 &&
				galery.map((image, index) => (
					<img
						key={index}
						src={`${DRUPAL_URL}${image?.attributes?.uri?.url.replace(
							'/glifos',
							''
						)}`}
						alt={
							info[index]?.meta?.alt ??
							'No se pudo recuperar la imágen'
						}
						className='c-image-group-item'
						loading='lazy'
						style={{ cursor: 'pointer' }}
						onClick={() => {
							setIndex(index);
						}}
					/>
				))}
		</ImageGroup>
	);
}
