import React, { useState, useEffect } from 'react';
import SimpleSection from '../components/SimpleSection';
import { Container, Row } from 'react-bootstrap';
import BasicLayout from '../layouts/BasicLayout';
import RegresarButton from '../components/RegresarButton';

import { AxiosDrupal, cleanText, getDateFormat } from '../utilities/util';

import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import NewsCard from '../components/NewsCard';
import SoonMessage from '../components/SoonMessage';

export default function ProjectsScreen() {
	const [projects, setProjects] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');
	useEffect(() => {
		setLoading(true);
		setError('');
		AxiosDrupal('GetProjects')
			.then(({ success, data, error }) => {
				if (success && data?.data.length > 0) setProjects(data.data);
				else if (success) setProjects([]);
				else setError(error);
			})
			.catch((error) => {
				console.log(error);
				setError(error);
			})
			.finally(() => setLoading(false));
	}, []);

	return (
		<BasicLayout>
			<div className='c-container'>
				<Container>
					<SimpleSection>
						<Row>
							<RegresarButton link='noticias-y-novedades' />
						</Row>
						<Row>
							{loading && <LoadingBox />}
							{error && (
								<MessageBox severity='danger'>
									{error}
								</MessageBox>
							)}
							{projects.length > 0 ? (
								projects.map((item, index) => (
									<NewsCard
										key={index}
										image={
											item?.relationships?.field_portada
												?.links?.related?.href
										}
										subtitle='Proyectos'
										title={item?.attributes?.title}
										date={getDateFormat(
											item?.attributes
												?.field_fecha_publicacion,
											'DD de month de YYYY'
										)}
										url={`/proyecto/${
											item?.attributes
												?.drupal_internal__nid
										}/${cleanText(
											item?.attributes?.title
										)}`}
										text={
											<span
												dangerouslySetInnerHTML={{
													__html:
														item?.attributes
															?.field_parrafos_1[0]
															?.processed ??
														item?.attributes
															?.field_parrafos_2[0]
															?.processed ??
														item?.attributes
															?.field_parrafos_3[0]
															?.processed,
												}}
											/>
										}
									/>
								))
							) : (
								<SoonMessage>
									Proyectos próximamente
								</SoonMessage>
							)}
						</Row>
					</SimpleSection>
				</Container>
			</div>
		</BasicLayout>
	);
}
