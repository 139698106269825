import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import AboutLayout from '../layouts/AboutLayout';

import SimpleSection from '../components/SimpleSection';

import Exposiciones from '../images/exhibits/exposiciones2.jpg';
import ExpoAH from '../images/exhibits/exposicion_ah.jpg';
/* Exposiciones Fototeca Guatemala */
import Rit from '../images/exhibits/ritosyrituales.webp';
import Frac from '../images/exhibits/fractalisis.webp';
import Exodo from '../images/exhibits/exodoyretorno.webp';
import Guillemin from '../images/exhibits/Guillemin-e1714374045213.jpg';
import Yas from '../images/exhibits/Yas.webp';
import Pinceladas from '../images/exhibits/pinceladasdeluz-e1706153009566.webp';
import Trayectos from '../images/exhibits/trayectos_antiguenos.jpg';
import Eadweard from '../images/exhibits/eadweard.jpg';
import Expo from '../images/exhibits/expo-al-aire-e1637990892225.webp';
import SigloXIX from '../images/exhibits/guatemala_siglo_xix.jpg';

/* Exposiciones Archivo Histórico */
import ElPrincipito from '../images/exhibits/logo_el_principito.jpg';
import CuatroSiglosCartografia from '../images/exhibits/Logo-Cuatro-Siglos-de-Cartografia.jpg.webp';
import Mujeres from '../images/exhibits/mujeres_y_revolucion.jpg';
import Centinela from '../images/exhibits/Centinela-de-la-memoria.jpg';

import TitleBackground from '../components/TitleBackground';
import ModalComponent from '../components/Modal';
//import GuionExodo from '../files/exodoyretorno.pdf';
import Watcher from '../components/Watcher';
import WBkey from '../components/WBkey';

const dumpData = {
	ritos_rituales: {
		title: '_27aGz6L',
		description: 'I7F3sr43',
		img: Rit,
		file: '/viewer/exposiciones/dfc961e422ceb3da684f427b8b8d28dc',
		acervo: 'Fototeca',
	},
	fractalisis: {
		title: '6fUcH6WT',
		subtitle: 'avQlHTi3',
		description: 'htljj3XF',
		img: Frac,
		file: '/virtual-tour/exposicion-fractalisis/',
		acervo: 'Fototeca',
	},
	exodo_y_retorno: {
		title: 'Sg3D2GNU',
		description: 'NIGnDEMU',
		img: Exodo,
		file: '/viewer/exposiciones/d86e9e38ca3a4d7dd0ac833b8aeb6645',
		acervo: 'Fototeca',
	},
	guillemin: {
		title: '1Scdm3z8',
		subtitle: 'AYjfjgrH',
		description: 'IPBOi7QN',
		img: Guillemin,
		file: '/viewer/exposiciones/e4a4397973007cf4e3704160bb12bf30',
		acervo: 'Fototeca',
	},
	jj_yas: {
		title: 'v9s6nBe9',
		description: '7H0UEV4p',
		img: Yas,
		file: '/viewer/exposiciones/a1f03d1a5ddc4d3b2e0580d85a880749',
		acervo: 'Fototeca',
	},
	pinceladas_luz: {
		title: 'FPw4aEgJ',
		description: 'oaSWuV7e',
		img: Pinceladas,
		file: '/viewer/exposiciones/31cda7d8be46f52f04e88ff85f592011',
		acervo: 'Fototeca',
	},
	trayectos_antiguenos: {
		title: 'afdKtnwj',
		description: '74hPoFD9',
		img: Trayectos,
		file: '/viewer/exposiciones/bb1b702d28428445db270d6a6c5aafab',
		acervo: 'Fototeca',
	},
	eadweard_muybridge: {
		title: 'rK4jsNeh',
		subtitle: 'elBA0LSV',
		description: 'bapCKZ9p',
		img: Eadweard,
		file: '/viewer/exposiciones/b24c8456d5ac0a5e05d352e821336d63',
		acervo: 'Fototeca',
	},
	al_aire: {
		title: '8cjPa8wW',
		description: 'd5anX2n_',
		img: Expo,
		file: '/viewer/exposiciones/0fbc64cd8042a8cb1e6ddb14fe2b16d5',
		acervo: 'Fototeca',
	},
	guatemala_siglo_xix: {
		title: 'SG4xK9wa',
		description: '_iinTl9A',
		img: SigloXIX,
		file: '/viewer/exposiciones/4f84a2f2e263a55271983f1e81657aee',
		acervo: 'Fototeca',
	},
	principito: {
		title: 'Kj7tyOsV',
		description: '3hIzM_40',
		img: ElPrincipito,
		file: '/viewer/exposiciones/5dea7f29905aa42ca72eee6baa2e6615',
		acervo: 'Archivo',
	},
	cuatro_siglos_cartografia: {
		title: 'EXcG9DSm',
		description: 'mpeILfDO',
		img: CuatroSiglosCartografia,
		file: '/viewer/exposiciones/942dc459d766d50a2c720b9c06bceb9a',
		acervo: 'Archivo',
	},
	mujeres_revolucion: {
		title: 'bOpILw3o',
		description: 'QFuPJla7',
		img: Mujeres,
		file: '/viewer/exposiciones/210484affb0e0a2f29325ccd0b088ad9',
		acervo: 'Archivo',
	},
	centinela_memoria: {
		title: 'J7AitJjP',
		description: '3efIrEoy',
		img: Centinela,
		file: '/viewer/exposiciones/a7582cf2232ec85e5ce64a1688ef0179',
		acervo: 'Archivo',
	},
};

export default function ExhibitionsScreen(props) {
	const [showModal, setShowModal] = useState(false);
	const [exposition, setExposition] = useState({});
	const [showWatcher, setShowWatcher] = useState(false);
	const [link, setLink] = useState('');
	const activateModal = (selectedExposition, link) => {
		setExposition(dumpData[selectedExposition]);
		setShowModal(true);
		setLink(link);
	};
	return (
		<AboutLayout
			title='Exposiciones itinerantes'
			subtitle='Fototeca Guatemala / Archivo Histórico'
			backgroundImage='exposiciones'
		>
			<Container>
				<SimpleSection className='c-exhibits-cirma'>
					<Row>
						<Col sm={12} lg={3}>
							<div className='c-exhibits-title'>
								<h5 className='c-exhibits-subtitle'>
									<WBkey wbkey='cpA7FyYh' />
								</h5>
								<h2 className='c-cirma-h3'>
									<WBkey wbkey='Ey6HC5ry' />
									<div className='c-divisor'>
										<div className='c-line'></div>
									</div>
								</h2>
							</div>
						</Col>
						<Col sm={12} lg={3}>
							<div className='c-img-cont'>
								<img
									src={Exposiciones}
									alt='Más de 40 años de historia en Guatemala'
								/>
							</div>
						</Col>
						<Col sm={12} lg={6}>
							<div className='c-exhibits-info'>
								<WBkey wbkey='_RnHM4a6' />
							</div>
						</Col>
					</Row>
				</SimpleSection>
				<SimpleSection className='c-exhibits-cirma'>
					<Row>
						{dumpData &&
							Object.entries(dumpData)
								.filter(
									([_, value]) => value.acervo === 'Fototeca'
								)
								.map(([key, value]) => (
									<Col sm={12} md={6} lg={3} key={key}>
										<div
											className='c-img-cont'
											onClick={() =>
												activateModal(key, value.file)
											}
										>
											<img
												src={value.img}
												alt={
													<WBkey
														variant='plain'
														wbk={value.title}
													/>
												}
											/>
										</div>
									</Col>
								))}
					</Row>
				</SimpleSection>
			</Container>
			<TitleBackground
			/* title='Centinela de la Memoria'
			subtitle='Exposición' */
			/>
			<Container>
				<SimpleSection className='c-exhibits-cirma'>
					<Row>
						<Col sm={12} lg={3}>
							<div className='c-exhibits-title'>
								<h5 className='c-exhibits-subtitle'>
									<WBkey wbkey='cpA7FyYh' />
								</h5>
								<h2 className='c-cirma-h3'>
									<WBkey wbkey='vjVImOMY' />
									<div className='c-divisor'>
										<div className='c-line'></div>
									</div>
									{/* Archivo Histórico */}
								</h2>
							</div>
						</Col>
						<Col sm={12} lg={3}>
							<div className='c-img-cont'>
								<img
									src={ExpoAH}
									alt='Más de 40 años de historia en Guatemala'
								/>
							</div>
						</Col>
						<Col sm={12} lg={6}>
							<div className='c-exhibits-info'>
								<WBkey wbkey='qAhxNxi5' />
							</div>
						</Col>
					</Row>
					<Row>
						{dumpData &&
							Object.entries(dumpData)
								.filter(
									([_, value]) => value.acervo === 'Archivo'
								)
								.map(([key, value]) => (
									<Col sm={12} md={6} lg={3} key={key}>
										<div
											className='c-img-cont'
											onClick={() =>
												activateModal(key, value.file)
											}
										>
											<img
												src={value.img}
												alt={
													<WBkey
														variant='plain'
														wbk={value.title}
													/>
												}
											/>
										</div>
									</Col>
								))}
					</Row>
				</SimpleSection>
			</Container>
			<ModalComponent
				title={<WBkey wbkey={exposition.title} />}
				subtitle={<WBkey wbkey={exposition.subtitle} />}
				content={<WBkey wbkey={exposition.description} />}
				showModal={showModal}
				setShowModal={setShowModal}
				link={link}
				expo
			/>
			<Watcher
				link={link}
				show={showWatcher}
				picture
				setShowWatcher={setShowWatcher}
				horizontal={false}
				vertical={false}
			/>
		</AboutLayout>
	);
}
