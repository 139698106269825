import React from 'react';
import TimelineItem from './TimelineItem';

export default function Timeline(props) {
	//console.log(props?.timeline);
	return (
		<div className='c-site-timeline'>
			{props?.timeline &&
				props?.timeline?.map((item, index) => {
					return index % 2 === 0 ? (
						<TimelineItem
							key={index}
							position={'right'}
							item={item}
						/>
					) : (
						<TimelineItem
							key={index}
							position={'left'}
							item={item}
						/>
					);
				})}
		</div>
	);
}
