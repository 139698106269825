import React from 'react';
import { X } from 'react-bootstrap-icons';
import { removeRightClick } from '../utilities/util';

export default function Watcher(props) {
	const { link, show, youtube, picture, setShowWatcher } = props;
	console.log(props);
	return show ? (
		<div
			className='c-watcher-container'
			onClick={(e) => {
				//console.log(e)
			}}
		>
			<div className='c-exit'>
				<button
					type='button'
					className='c-exit-button'
					onClick={() => setShowWatcher(false)}
				>
					<X />
				</button>
			</div>
			<div
				className='c-watcher'
				onClick={(e) => {
					//console.log(e)
					setShowWatcher(false);
				}}
			>
				{youtube && (
					<iframe
						className='c-video'
						src={link}
						title='YouTube video player'
						frameborder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
						allowFullScreen
						onClick={(e) => {
							console.log(e);
						}}
					></iframe>
				)}
				{picture && (
					<img
						className='c-picture'
						src={link}
						alt=''
						onContextMenu={removeRightClick}
					></img>
					/* horizontal?(<img className='c-picture-horizontal' src={link}></img>)
                        : (vertical&&(<img className='c-picture-vertical' src={link}></img>)) */
				)}
			</div>
		</div>
	) : (
		''
	);
}
