import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import {
	cleanText,
	getDateFormat,
	removeRightClick,
} from '../../../utilities/util';
import { CalendarFill } from 'react-bootstrap-icons';
import styles from './OlderEventsCards.module.scss';
import Axios from '../../../services/Api';
import { DRUPAL_URL } from '../../../utilities/globals';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Autoplay } from 'swiper';

export default function OlderEventsCards() {
	const [events, setEvents] = useState({
		isLoading: false,
		isError: false,
		error: '',
		payload: [],
	});
	useEffect(() => {
		const fetchData = async () => {
			try {
				setEvents({
					isLoading: true,
					isError: false,
					error: '',
					payload: [],
				});
				const { data } = await Axios.GetOlderEvents();
				if (!Boolean(data?.success)) {
					throw new Error(
						data?.message ?? 'Error al obtener los eventos'
					);
				}
				setEvents((prevData) => ({
					...prevData,
					payload: data?.data ?? [],
				}));
			} catch (error) {
				setEvents({
					isLoading: false,
					isError: true,
					error: error?.message ?? 'Error al obtener los eventos',
					payload: [],
				});
			} finally {
				setEvents((prevData) => ({
					...prevData,
					isLoading: false,
				}));
			}
		};
		fetchData();
	}, []);
	return (
		<Swiper
			modules={[Navigation, Pagination, Autoplay, Mousewheel]}
			slidesPerView={1}
			onSlideChange={() => console.log('slide change')}
			cssMode
			keyboard
			freeMode
			autoplay={{ delay: 7000 }}
			navigation={{
				clickable: true,
			}}
			mousewheel={true}
			pagination={{
				clickable: true,
			}}
			onSwiper={(swiper) => console.log(swiper)}
			style={{
				color: 'red',
			}}
			className={`p-0 swiper-events`}
		>
			{events.payload.map((event) => {
				return (
					<SwiperSlide key={event.uuid} className={`p-0`}>
						<Card
							className={`text-decoration-none border-0 mw-100 p-0 ${styles.newsCard}`}
							as={'a'}
							href={
								'/evento/' +
								event.nid +
								'/' +
								cleanText(event.title)
							}
							rel='noopener noreferrer'
							target='_self'
							aria-label='Ir a la noticia'
						>
							<Card.Img
								variant='top'
								src={`${DRUPAL_URL}/glifos/file/get/thumbnail/${event.file_id}/400`}
								className={styles.newsCardImage}
								alt={event.image_alt ?? event.title}
								loading='lazy'
								onContextMenu={removeRightClick}
								height={175}
							/>
							<Card.Body className='m-0 p-2rem border-none'>
								<Card.Subtitle
									className={styles.newsCardSubtitle}
								>
									Eventos
								</Card.Subtitle>
								<Card.Title className={styles.newsCardTitle}>
									{event.title}
								</Card.Title>
								<Card.Subtitle className={styles.newsCardDate}>
									<CalendarFill />{' '}
									{getDateFormat(
										event.initial_date,
										'DD de month de YYYY'
									)}
								</Card.Subtitle>
								<div
									className={styles.newsCardText}
									dangerouslySetInnerHTML={{
										__html: event.description,
									}}
								/>
							</Card.Body>
						</Card>
					</SwiperSlide>
				);
			})}
		</Swiper>
	);
}
