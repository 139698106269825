import React from 'react';
import { Card, Placeholder } from 'react-bootstrap';

export default function OldCardEventLoading() {
	return (
		<Card
			style={{ width: '100%', height: '31.25rem' }}
			className='p-0 border-0'
		>
			<Placeholder as={Card.Text} animation='glow' className='m-0 p-0'>
				<Placeholder xs={12} size='lg' style={{ height: '175px' }} />
			</Placeholder>
			<Card.Body className='m-0 p-2rem'>
				<Placeholder as={Card.Text} animation='glow'>
					<Placeholder xs={5} size='lg' />
				</Placeholder>
				<Placeholder as={Card.Title} animation='glow'>
					<Placeholder size='lg' xs={7} />{' '}
					<Placeholder size='lg' xs={4} />{' '}
					<Placeholder size='lg' xs={4} />{' '}
					<Placeholder size='lg' xs={6} />{' '}
				</Placeholder>
				<Placeholder
					as={Card.Subtitle}
					animation='glow'
					className='mb-3'
				>
					<Placeholder size='sm' xs={1} />{' '}
					<Placeholder size='sm' xs={2} />{' '}
					<Placeholder size='sm' xs={4} />{' '}
				</Placeholder>
				<Placeholder as={Card.Text} animation='glow'>
					<Placeholder xs={7} /> <Placeholder xs={4} />{' '}
					<Placeholder xs={4} /> <Placeholder xs={6} />{' '}
					<Placeholder xs={8} /> <Placeholder xs={2} />{' '}
					<Placeholder xs={1} /> <Placeholder xs={9} />{' '}
				</Placeholder>
			</Card.Body>
		</Card>
	);
}
