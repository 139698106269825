import { BACKEND_URL } from '../utilities/globals';
const ApiURLs = {
	baseURL: BACKEND_URL,
	GetNews: {
		href: '/glifos/jsonapi/node/noticia',
		method: 'get',
	},
	Get3News: {
		href: '/glifos/jsonapi/node/noticia',
		method: 'get',
		params: ['page[limit]'],
	},
	GetEvents: {
		href: '/glifos/jsonapi/node/evento',
		method: 'get',
	},
	GetCollections: {
		href: '/glifos/jsonapi/node/colecciones',
		method: 'get',
	},
	GetProjects: {
		href: '/glifos/jsonapi/node/proyecto',
		method: 'get',
	},
	GetClubs: {
		href: '/glifos/jsonapi/node/club',
		method: 'get',
	},
	GetVoluntariosPracticantes: {
		href: '/glifos/jsonapi/node/voluntarios_practicantes',
		method: 'get',
	},
	GetTributes: {
		href: '/glifos/jsonapi/node/homenaje',
		method: 'get',
	},
	GetDonors: {
		href: '/glifos/jsonapi/node/donante_y_donacion',
		method: 'get',
	},
	GetSpecials: {
		href: '/glifos/jsonapi/node/especial',
		method: 'get',
	},
	GetImageData: {
		href: '/glifos/jsonapi/node/noticia',
		method: 'get',
	},
	GetNoticia: {
		href: '/glifos/jsonapi/node/noticia/:id',
		method: 'get',
		urlParams: ['id'],
	},
	SendContactForm: {
		href: '/v1/website/contact-us',
		method: 'post',
		requires: ['name', 'email', 'message'],
	},
	Subscribe: {
		href: '/v1/website/subscribe',
		method: 'post',
		requires: ['email'],
	},
	GetLastSocialContents: {
		href: '/glifos/api/ultimos-contenidos',
		method: 'get',
	},
	GetRecentEvents: {
		href: '/v1/website/get-recent-events',
		method: 'get',
	},
	GetOlderEvents: {
		href: '/v1/website/get-older-events',
		method: 'get',
	},
	GetSocialMedia: {
		href: '/v1/website/get-social-media',
		method: 'get',
	},
	GetDiffusionMedia: {
		href: '/v1/website/get-diffusion-media',
		method: 'get',
	},
	GetBoletines: {
		href: '/v1/website/get-boletines',
		method: 'get',
	},
	GetRecentClubs: {
		href: '/v1/website/get-recent-clubs',
		method: 'get',
	},
	GetRecentAcquisitions: {
		href: '/v1/website/get-recent-acquisitions',
		method: 'get',
	},
	GetRecentProjects: {
		href: '/v1/website/get-recent-projects',
		method: 'get',
	},
	GetNodes: {
		href: '/v1/website/get-nodes',
		method: 'get',
	},
	GetRecentNodes: {
		href: '/v1/website/get-recent-nodes',
		method: 'get',
	},
};

export default ApiURLs;
