import React, { useState, useRef } from 'react';
import { Spinner } from 'react-bootstrap';
import { Button, Form, Alert } from 'react-bootstrap';
import Axios from '../services/Api';

export default function Subscribe(props) {
	const { placeholder = 'Correo electrónico' } = props;
	const formRef = useRef(null);
	const [formData, setFormData] = useState({
		email: '',
		name: '',
	});
	const [response, setResponse] = useState({
		isLoading: false,
		isError: false,
		isSuscribed: false,
		payload: {
			message: '',
		},
	});

	const handleChange = (e) =>
		setFormData((prevData) => ({
			...prevData,
			[e.target.name]: e.target.value,
		}));

	const handleSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const form = e.currentTarget;
		if (form.checkValidity() === false) return;
		sendData();
	};

	const sendData = async () => {
		try {
			setResponse((prevResponse) => ({
				...prevResponse,
				isLoading: true,
			}));
			const { data } = await Axios.Subscribe(formData);
			const validRespose = Boolean(data?.success);
			setResponse((prevResponse) => ({
				...prevResponse,
				isLoading: false,
				isError: !validRespose,
				isSuscribed: validRespose,
				payload: {
					message: data.message,
				},
			}));
			validRespose && resetForm();
		} catch (error) {
			console.log(error);
			setResponse((prevResponse) => ({
				...prevResponse,
				isLoading: false,
				isError: true,
				payload: {
					message: error.message ?? 'Error al enviar el formulario',
				},
			}));
		}
	};

	const resetForm = () => {
		setFormData({
			email: '',
			name: '',
		});
		formRef.current?.reset();
	};

	return (
		<div className='c-input-subscribe'>
			{response.isSuscribed && (
				<Alert variant='success' className='text-center'>
					{response.payload.message}
				</Alert>
			)}
			{!response.isSuscribed && (
				<Form
					onSubmit={handleSubmit}
					ref={formRef}
					className='d-flex flex-column flex-md-row gap-1 w-100'
				>
					<Form.Group
						className='d-flex c-input-s'
						controlId='formEmail'
					>
						<Form.Control
							type='email'
							placeholder={placeholder}
							autoComplete='off'
							name='name'
							onChange={handleChange}
							value={formData.name}
							required
						/>
					</Form.Group>

					<Form.Group className='d-none' controlId='formName'>
						<Form.Control
							type='text'
							placeholder='Name'
							autoComplete='off'
							name='name'
							className='d-none'
							onChange={handleChange}
							value={formData.name}
						/>
					</Form.Group>

					<Button
						variant='primary'
						type='submit'
						className='d-inline-block .c-subsbtn'
					>
						{response.isLoading ? (
							<>
								<Spinner
									animation='border'
									variant='light'
									size='sm'
								/>{' '}
								Enviando...
							</>
						) : (
							'SUBSCRÍBETE'
						)}
					</Button>
				</Form>
			)}
			{!response.isLoading &&
				response.isError &&
				!response.isSuscribed &&
				response.payload.message && (
					<p className={`mt-3 text-wrap fw-bold text-danger}`}>
						{response.payload.message}
					</p>
				)}
		</div>
	);
}
