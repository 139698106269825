import React from 'react';
import Service from '../services/Api';
window.getTransitionEndEventName = () => {
	var transitions = {
		transition: 'transitionend',
		OTransition: 'oTransitionEnd',
		MozTransition: 'transitionend',
		WebkitTransition: 'webkitTransitionEnd',
	};
	let bodyStyle = document.body.style;
	for (let transition in transitions) {
		if (bodyStyle[transition] !== undefined) {
			return transitions[transition];
		}
	}
};

export default function WBkey(props) {
	const { wbkey, variant } = props;
	let asset = window.wb?.getAsset(wbkey);
	//console.log(asset)
	if (!asset) return '';
	/* const clickCourtain = (evcc) => {
		document.getElementById('wbmodal').classList.add('closing');
		document.getElementById('wbcourtain').classList.add('closing');
		setTimeout(() => {
			document.getElementById('wbmodal').remove();
			document.getElementById('wbcourtain').remove();
		}, 500);
	}; */
	const rightClick = (evrc) => {
		/* evrc.preventDefault();
        evrc.stopPropagation();
        let wbembeddiv=document.createElement('div');
        let wbembedc=document.createElement('div');
        wbembeddiv.id="wbmodal";
        wbembedc.id="wbcourtain";
        wbembedc.onclick=clickCourtain;
        wbembeddiv.classList.add('wbkeyembed');
        wbembeddiv.innerHTML="<iframe src='https://api.cirma.local/website-asset/key/"+evrc.target.getAttribute('wbk')+"' />";
        document.body.appendChild(wbembedc);
        document.body.appendChild(wbembeddiv);
        //window.open('https://api.cirma.local/website-asset/key/'+evrc.target.getAttribute('wbk')); */
	};
	const mouseOver = (evmo) => {
		evmo.target.classList.add('wbover');
	};
	/* const mouseOut = (evmo) => {
		evmo.target.classList.remove('wbover');
	}; */
	//console.log(asset.type);
	if (!variant)
		switch (asset.type) {
			case 1:
				return (
					<span
						wbk={wbkey}
						onContextMenu={rightClick}
						onMouseOver={mouseOver}
					>
						{asset.label}
					</span>
				);
			case 2:
				return (
					<span
						wbk={wbkey}
						onContextMenu={rightClick}
						onMouseOver={mouseOver}
						dangerouslySetInnerHTML={{ __html: asset.html }}
					/>
				);
			case 3:
				return (
					<span
						wbk={wbkey}
						onContextMenu={rightClick}
						onMouseOver={mouseOver}
					>
						{asset.long_text}
					</span>
				);
			case 6:
				return (
					<span
						wbk={wbkey}
						onContextMenu={rightClick}
						onMouseOver={mouseOver}
					>
						{asset.label}
					</span>
				);
			case 7:
				console.log(asset.href);
				/* return <img src={Service.__getFileSrc(
					asset.href
				)}></img> */
				return Service.__getFileSrc(asset.href);
			default:
				return <></>;
		}
	if (variant === 'plain')
		switch (asset.type) {
			case 1:
				return asset.label;
			case 2:
				return asset.html;
			case 3:
				return asset.long_text;
			case 6:
				return asset.label;
			default:
				return '';
		}
	if (variant === 'href') return asset.href;

	return '';
}
