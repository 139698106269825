import React from 'react'
import { Book } from 'react-bootstrap-icons'

export default function SoonMessage(props) {
    const { children} = props;
    return (
        <div className='c-soon-message'>
            <Book className='c-book-soon'/>
            <h2>
                {children}
            </h2>
        </div>
    )
}
