import React, { useEffect, useState } from 'react';
/* import EventCard from '../components/EventCard'; */
import SimpleSection from '../../components/SimpleSection';
import { Button, Container, Row } from 'react-bootstrap';
import { ArrowLeft } from 'react-bootstrap-icons';

import styles from './MediaScreen.module.scss';
import AboutLayout from '../../layouts/AboutLayout';
import { useSearchParams, Link } from 'react-router-dom';
import Axios from '../../services/Api';

import Masonry from 'react-masonry-css';
import { DRUPAL_URL } from '../../utilities/globals';
import Watcher from '../../components/Watcher';

const breakpointColumnsObj = {
	default: 4,
	1100: 3,
	768: 2,
	500: 1,
};

const DiffussionTypes = {
	ALL: { label: 'Todos', param: 'all', show: true },
	PRINTED_MEDIA: {
		label: 'Medios impresos',
		param: 'printed_media',
		show: true,
	},
	VIDEOS: { label: 'Videos', param: 'video', show: true },
	SOCIAL_MEDIA: {
		label: 'Medios Sociales',
		param: 'social_media',
		show: true,
	},
};

export default function MediaDiffussionScreen() {
	const [watcherInfo, setWatcherInfo] = useState({
		show: false,
		link: '',
		horizontal: false,
		vertical: false,
	});
	const [publications, setPublications] = useState({
		isLoading: false,
		isError: false,
		error: '',
		payload: [],
	});

	const [searchParams] = useSearchParams();
	const search_type =
		searchParams.get('search_type') || DiffussionTypes.ALL.param;

	const hideWatcher = (showWatcher) => {
		setWatcherInfo((prevData) => ({
			...prevData,
			show: showWatcher,
		}));
	};
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		const fetchData = async () => {
			try {
				setPublications({
					isLoading: true,
					isError: false,
					error: '',
					payload: [],
				});
				const { data } = await Axios.GetDiffusionMedia();
				if (!Boolean(data?.success)) {
					throw new Error(
						data?.message ?? 'Error al obtener las publicaciones'
					);
				}
				setPublications((prevData) => ({
					...prevData,
					payload: data?.data ?? [],
				}));
			} catch (error) {
				setPublications({
					isLoading: false,
					isError: true,
					error:
						error?.message ?? 'Error al obtener las publicaciones',
					payload: [],
				});
			} finally {
				setPublications((prevData) => ({
					...prevData,
					isLoading: false,
				}));
			}
		};
		fetchData();
	}, []);

	return (
		<AboutLayout
			title={'Difusión en medios'}
			backgroundImage='difusion'
			noMarginTop
		>
			<Container>
				<SimpleSection className={'mt-2'}>
					<Row className='justify-content-between align-items-center mb-2'>
						<Button
							href={'/noticias-y-novedades'}
							variant='transparent'
							className='c-btn-goback w-auto'
						>
							<div className='c-btn-content'>
								<ArrowLeft /> Regresar
							</div>
						</Button>
						{/* <div className='w-auto align-items-center'>
							<BreadcrumbComponent
								items={[
									...newsAndUpdates,
									{ url: '/eventos', title: 'Eventos' },
								]}
							/>
						</div> */}
					</Row>
					<Row className='d-flex justify-content-center'>
						<div className='c-difusion-head'>
							<h2 className='c-cirma-title'>
								A los medios que han dado voz a nuestra causa:
							</h2>
							<p className='c-subtitle'>
								Gracias por convertir la tinta en acción, por
								amplificar nuestro mensaje y por ser aliados en
								la construcción de un futuro mejor. CIRMA ha
								logrado un gran hito al ser publicado en medios
								nacionales. Este reconocimiento por parte de la
								comunidad periodística es un testimonio del
								impacto significativo que CIRMA ha tenido en el
								país. Las publicaciones han destacado el valor
								del resguardo y la investigación.
							</p>
						</div>
					</Row>
					<Row className='d-flex flex-wrap justify-content-center gap-3 mb-3'>
						{Object.entries(DiffussionTypes).map(([_, type]) => (
							<Link
								key={type.param}
								to={`?search_type=${type.param}`}
								className={`w-auto py-2 px-4 ${
									styles.mediaLink
								} ${
									type.param === search_type
										? styles.active
										: ''
								}`}
							>
								{type.label}
							</Link>
						))}
					</Row>
					<Row className='w-100'>
						<Masonry
							breakpointCols={breakpointColumnsObj}
							className='my-masonry-grid w-100'
							columnClassName='my-masonry-grid_column'
						>
							{publications.payload
								.filter((item) =>
									search_type === DiffussionTypes.ALL.param
										? true
										: item.source_type === search_type
								)
								.map((data) => (
									<div
										key={data.uuid}
										className={`w-auto h-auto ${styles.mediaCard}`}
									>
										<img
											src={`${DRUPAL_URL}/glifos/file/get/thumbnail/${data.file_id}/400`}
											alt={data.title}
											loading='lazy'
										/>
										{data.mime === 'application/pdf' ? (
											<a
												className={`${styles.mediaCardInfo}`}
												href={`/viewer/difusion-en-medios/${data.file_id}`}
											>
												<div
													className={`${styles.mediaCaption}`}
												>
													<h5>{data.title}</h5>
													<p>{data.origin}</p>
												</div>
											</a>
										) : (
											<div
												className={`${styles.mediaCardInfo}`}
												onClick={() => {
													setWatcherInfo({
														show: true,
														link: `${DRUPAL_URL}/glifos/file/get/${data.file_id}/`,
														horizontal: false,
														vertical: true,
													});
												}}
											>
												<div
													className={`${styles.mediaCaption}`}
												>
													<h5>{data.title}</h5>
													<p>{data.origin}</p>
												</div>
											</div>
										)}
									</div>
								))}
						</Masonry>
					</Row>
				</SimpleSection>
			</Container>
			<Watcher
				link={watcherInfo.link}
				show={watcherInfo.show}
				picture
				setShowWatcher={hideWatcher}
				horizontal={watcherInfo.horizontal}
				vertical={watcherInfo.vertical}
			/>
		</AboutLayout>
	);
}
