import Breadcrumb from 'react-bootstrap/Breadcrumb';
import styles from './Breadcrumb.module.scss';
import { Placeholder } from 'react-bootstrap';

function BreadcrumbComponent({ items = [], isLoading = false }) {

	if (isLoading) {
		<Placeholder as={Breadcrumb} animation='glow'>
			<Placeholder>
				<Breadcrumb.Item className={styles.breadcrumbItem} />
			</Placeholder>
			<Placeholder>
				<Breadcrumb.Item className={styles.breadcrumbItem} />
			</Placeholder>
			<Placeholder>
				<Breadcrumb.Item className={styles.breadcrumbItem} />
			</Placeholder>
		</Placeholder>;
	}
	return (
		<Breadcrumb>
			<Breadcrumb.Item href='/' className={styles.breadcrumbItem}>
				Inicio
			</Breadcrumb.Item>
			{items.map((item, index) => (
				<Breadcrumb.Item
					key={index}
					href={item.url}
					active={index === items.length - 1}
					className={styles.breadcrumbItem}
				>
					{item.title}
				</Breadcrumb.Item>
			))}
		</Breadcrumb>
	);
}

export default BreadcrumbComponent;
