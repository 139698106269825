import React from 'react';
import { Card, Col, Placeholder } from 'react-bootstrap';

export default function OurClubCardLoading() {
	return (
		<Col xs={12} md={6} lg={4} xl={3}>
			<Card style={{ width: '100%' }} className='p-0'>
				<Placeholder
					as={Card.Text}
					animation='glow'
					className='m-0 p-0'
				>
					<Placeholder
						xs={12}
						size='lg'
						style={{ height: '240px' }}
					/>
				</Placeholder>
				<Card.Body className='m-0 p-3'>
					<Placeholder as={Card.Text} animation='glow'>
						<Placeholder xs={5} size='lg' />
					</Placeholder>
					<Placeholder as={Card.Title} animation='glow'>
						<Placeholder size='lg' xs={4} />{' '}
						<Placeholder size='lg' xs={3} />{' '}
						<Placeholder size='lg' xs={2} />{' '}
						<Placeholder size='lg' xs={5} />{' '}
					</Placeholder>
					<Placeholder
						as={Card.Subtitle}
						animation='glow'
						className='mb-3'
					>
						<Placeholder size='sm' xs={1} />{' '}
						<Placeholder size='sm' xs={2} />{' '}
						<Placeholder size='sm' xs={4} />{' '}
					</Placeholder>
					<Placeholder as={Card.Text} animation='glow'>
						<Placeholder xs={7} /> <Placeholder xs={4} />{' '}
						<Placeholder xs={4} /> <Placeholder xs={6} />{' '}
					</Placeholder>
				</Card.Body>
			</Card>
		</Col>
	);
}
