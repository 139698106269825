import React from 'react';
import { Container } from 'react-bootstrap';
import SimpleSection from '../SimpleSection';
import { socialOptions } from '../../data/SocialOptions';
import { Link } from 'react-router-dom';

export default function NoticiasNovedadesMenu() {
	return (
		<Container>
			<SimpleSection className='c-sociales'>
				{socialOptions
					.filter((option) => option?.show)
					.filter(
						(_, index) => index <= 2
					) /* TODO: Al habilitar resto de opciones eliminar este filtro  */
					.map((option, index) => (
						<Link
							className='c-social-options text-decoration-none'
							to={option.url}
							key={index}
						>
							<div className='c-social-image'>
								<img src={option.icon} alt='' />
							</div>
							<div className='c-social-name'>
								<h5>{option.name}</h5>
							</div>
							<div className='c-social-description'>
								<span>{option.description}</span>
							</div>
						</Link>
					))}
				{/* TODO: Al habilitar todas las opciones del menu eliminar codigo */}
				{socialOptions
					.filter((option) => option?.show)
					.filter((_, index) => index > 2)
					.map((option, index) => (
						<Link
							className='c-social-options text-decoration-none -flex d-md-none'
							to={option.url}
							key={index}
						>
							<div className='c-social-image'>
								<img src={option.icon} alt='' />
							</div>
							<div className='c-social-name'>
								<h5>{option.name}</h5>
							</div>
							<div className='c-social-description'>
								<span>{option.description}</span>
							</div>
						</Link>
					))}
			</SimpleSection>

			{/* TODO: Al habilitar todas las opciones del menu eliminar codigo */}
			<div className='c-social-options-flex d-none d-md-flex'>
				{socialOptions
					.filter((option) => option?.show)
					.filter((_, index) => index > 2)
					.map((option, index) => (
						<Link
							className='c-social-options text-decoration-none'
							to={option.url}
							key={index}
						>
							<div className='c-social-image'>
								<img src={option.icon} alt='' />
							</div>
							<div className='c-social-name'>
								<h5>{option.name}</h5>
							</div>
							<div className='c-social-description'>
								<span>{option.description}</span>
							</div>
						</Link>
					))}
			</div>
		</Container>
	);
}
