import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import {
	cleanText,
	getDateFormat,
	removeRightClick,
} from '../../utilities/util';
import { CalendarFill } from 'react-bootstrap-icons';
import styles from './SwipperCards.module.scss';
import Axios from '../../services/Api';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Autoplay } from 'swiper';
import { DRUPAL_URL } from '../../utilities/globals';
import { useParams } from 'react-router-dom';

export default function SwipperCards({ route, apiRoute, subtitle, type }) {
	const { id } = useParams();
	const [cards, setCards] = useState({
		isLoading: false,
		isError: false,
		error: '',
		payload: [],
	});
	useEffect(() => {
		const fetchData = async () => {
			try {
				setCards({
					isLoading: true,
					isError: false,
					error: '',
					payload: [],
				});
				const { data } = await Axios.GetNodes({
					params: { from: id, type },
				});
				if (!Boolean(data?.success)) {
					throw new Error(
						data?.message ?? 'Error al obtener los articulos'
					);
				}
				setCards((prevData) => ({
					...prevData,
					payload: data?.data ?? [],
				}));
			} catch (error) {
				setCards({
					isLoading: false,
					isError: true,
					error: error?.message ?? 'Error al obtener los articulos',
					payload: [],
				});
			} finally {
				setCards((prevData) => ({
					...prevData,
					isLoading: false,
				}));
			}
		};
		fetchData();
		return () => {
			setCards({
				isLoading: false,
				isError: false,
				error: '',
				payload: [],
			});
		};
	}, [id, type]);

	const getImageURL = (file_id) => {
		if (!file_id.includes('/'))
			return `${DRUPAL_URL}/glifos/file/get/thumbnail/${file_id}/400`;
		else return `${DRUPAL_URL}${file_id}`;
	};
	return (
		<Swiper
			modules={[Navigation, Pagination, Autoplay, Mousewheel]}
			slidesPerView={1}
			onSlideChange={() => console.log('slide change')}
			cssMode
			keyboard
			freeMode
			autoplay={{ delay: 7000 }}
			navigation={{
				clickable: true,
			}}
			mousewheel={true}
			pagination={{
				clickable: true,
			}}
			onSwiper={(swiper) => console.log(swiper)}
			style={{
				color: 'red',
			}}
			className={`p-0 swiper-events`}
		>
			{cards.payload.map((card) => {
				return (
					<SwiperSlide key={card.uuid} className={`p-0`}>
						<Card
							className={`text-decoration-none border-0 mw-100 p-0 ${styles.newsCard}`}
							as={'a'}
							href={
								'/' +
								route +
								'/' +
								card.nid +
								'/' +
								cleanText(card.title)
							}
							rel='noopener noreferrer'
							target='_self'
							aria-label='Ir a la noticia'
						>
							<Card.Img
								variant='top'
								src={getImageURL(card.file_id)}
								className={styles.newsCardImage}
								alt={card?.image_alt ?? card.title}
								loading='lazy'
								onContextMenu={removeRightClick}
								height={175}
							/>
							<Card.Body className='m-0 p-2rem border-none'>
								<Card.Subtitle
									className={styles.newsCardSubtitle}
								>
									{subtitle}
								</Card.Subtitle>
								<Card.Title className={styles.newsCardTitle}>
									{card.title}
								</Card.Title>
								<Card.Subtitle className={styles.newsCardDate}>
									<CalendarFill />{' '}
									{getDateFormat(
										card.fecha_publicacion,
										'DD de month de YYYY'
									)}
								</Card.Subtitle>
								<div
									className={styles.newsCardText}
									dangerouslySetInnerHTML={{
										__html: card.descripcion,
									}}
								/>
							</Card.Body>
						</Card>
					</SwiperSlide>
				);
			})}
		</Swiper>
	);
}
