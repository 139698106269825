const HOSTNAME =
	window.location.hostname.indexOf('dev.cirma.org.gt') >= 0
		? window.location.hostname.replace(
				'dev.cirma.org.gt',
				'api.dev.cirma.org.gt'
		  )
		: //.replace('.cliente', '')
		window.location.hostname.indexOf('local') >= 0
		? 'api.dev.cirma.local'
		: 'api.cirma.org.gt';
export const BACKEND_URL = window.location.protocol + '//' + HOSTNAME;
export const YII_URL = `${BACKEND_URL}/v1/`;
//console.log(BACKEND_URL);
export const DRUPAL_URL = `${BACKEND_URL}/glifos`;
export const DRUPAL_URI_ARTICLES = '/jsonapi/node/noticia';
export const DRUPAL_URI_EVENTS = '/jsonapi/node/event';
export const DRUPAL_URI_COLLECTIONS = '/jsonapi/node/colecciones';
export const DRUPAL_URI_PROJECTS = '/jsonapi/node/proyecto';
export const DRUPAL_URI_CLUBS = '/jsonapi/node/club';
export const DRUPAL_URI_VOLUNTARIOS = '/jsonapi/node/voluntarios_practicantes';
export const DRUPAL_URI_TRIBUTES = '/jsonapi/node/homenaje';
export const DRUPAL_URI_DONORS = '/jsonapi/node/donante_y_donacion';
export const DRUPAL_URI_SPECIAL = '/jsonapi/node/especial';
export const DRUPAL_HEADERS = {
	Accept: 'application/vnd.api+json',
};

export const MONTHS = [
	'Enero',
	'Febrero',
	'Marzo',
	'Abril',
	'Mayo',
	'Junio',
	'Julio',
	'Agosto',
	'Septiembre',
	'Octubre',
	'Noviembre',
	'Diciembre',
];
