import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
//import EmptySpace from '../components/EmptySpace';
import Paragraph from '../components/Paragraph';
import PhotoWithCredits from '../components/PhotoWithCredits';
//import Video from '../components/Video';
import {
	DRUPAL_URL,
	DRUPAL_URI_VOLUNTARIOS,
	DRUPAL_HEADERS,
} from '../utilities/globals';

import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import NoticiaLayout from '../layouts/NoticiaLayout';
import DrupalGalery from '../components/DrupalGalery';
import { CustomAxios, getDateFormat } from '../utilities/util';
import { voluntierBreadcrumb } from '../data/breadcrumbsData';
export default function MeetScreen() {
	const { id, title } = useParams();
	const [article, setArticle] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');
	const [author, setAuthor] = useState('');

	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true);
				setError('');
				const response = await CustomAxios(
					'get',
					`${DRUPAL_URL}${DRUPAL_URI_VOLUNTARIOS}?filter[drupal_internal__nid]=${id}`,
					{},
					{ 'Content-Type': 'application/json', ...DRUPAL_HEADERS }
				);
				if (response.success && response.data?.data)
					setArticle(response.data.data[0]);
				else {
					setArticle({});
					setError(response.error);
				}
			} catch (error) {
				setError(error);
			} finally {
				setLoading(false);
			}
		};
		if (id) fetchData();
	}, [id]);

	useEffect(() => {
		if (article?.relationships?.revision_uid?.links?.related?.href) {
			CustomAxios(
				'get',
				article.relationships.revision_uid.links.related.href,
				{},
				{ 'Content-Type': 'application/json', ...DRUPAL_HEADERS }
			).then((response) => {
				if (response.success) {
					setAuthor(response.data.data.attributes.display_name);
				} else setAuthor('');
			});
		}
	}, [article]);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<NoticiaLayout
			backgroundImage={
				article?.relationships?.field_portada?.links?.related?.href
			}
			autor={author}
			date={getDateFormat(
				article?.attributes?.field_fecha_publicacion,
				'month DD, YYYY'
			)}
			title={article?.attributes?.title}
			goback='voluntarios-y-practicantes'
			breadcrumb={[
				...voluntierBreadcrumb,
				{
					title: article?.attributes?.title,
					link: `/voluntarios-y-practicantes/${id}/${title}`,
				},
			]}
			sidebar='voluntario'
		>
			{loading && <LoadingBox />}
			{error && <MessageBox severity='danger'>{error}</MessageBox>}
			{article?.attributes?.field_parrafos_1?.length > 0 &&
				article?.attributes?.field_parrafos_1?.map(
					(paragraph, index) => (
						<Paragraph key={index}>{paragraph.processed}</Paragraph>
					)
				)}
			{article?.relationships?.field_galeria_1?.data?.length > 1 && (
				<DrupalGalery
					url={
						article?.relationships?.field_galeria_1?.links?.related
							?.href
					}
					info={article?.relationships?.field_galeria_1?.data}
				/>
			)}
			{article?.relationships?.field_galeria_1?.data?.length === 1 && (
				<PhotoWithCredits
					className='c-image-w-credits'
					data={article?.relationships?.field_galeria_1?.data[0]}
					image={
						article?.relationships?.field_galeria_1?.links?.related
							?.href
					}
				/>
			)}
			{article?.attributes?.field_parrafos_2?.length > 0 &&
				article?.attributes?.field_parrafos_2?.map(
					(paragraph, index) => (
						<Paragraph key={index}>{paragraph.processed}</Paragraph>
					)
				)}
			{article?.relationships?.field_galeria_2?.data?.length > 1 && (
				<DrupalGalery
					url={
						article?.relationships?.field_galeria_2?.links?.related
							?.href
					}
					info={article?.relationships?.field_galeria_2?.data}
				/>
			)}
			{article?.relationships?.field_galeria_2?.data?.length === 1 && (
				<PhotoWithCredits
					className='c-image-w-credits'
					data={article?.relationships?.field_galeria_2?.data[0]}
					image={
						article?.relationships?.field_galeria_2?.links?.related
							?.href
					}
				/>
			)}
			{article?.attributes?.field_parrafos_3?.length > 0 &&
				article?.attributes?.field_parrafos_3?.map(
					(paragraph, index) => (
						<Paragraph key={index}>{paragraph.processed}</Paragraph>
					)
				)}
			{article?.relationships?.field_galeria_3?.data?.length > 1 && (
				<DrupalGalery
					url={
						article?.relationships?.field_galeria_3?.links?.related
							?.href
					}
					info={article?.relationships?.field_galeria_3?.data}
				/>
			)}
			{article?.relationships?.field_galeria_3?.data?.length === 1 && (
				<PhotoWithCredits
					className='c-image-w-credits'
					data={article?.relationships?.field_galeria_3?.data[0]}
					image={
						article?.relationships?.field_galeria_3?.links?.related
							?.href
					}
				/>
			)}
			{/* {
                state?.data?.structure?.map((item) => {
                    switch (item.component) {
                        case 'Paragraph':
                            return <Paragraph className={item.className}>
                                {item.content}
                            </Paragraph>
                        case 'PhotoWithCredits':
                            return <PhotoWithCredits className={item.className} image={item.image} autor={item.author} />
                        case 'Video':
                            return <Video className={item.className} link={item.link} credits={item.credits} />
                        case 'EmptySpace':
                            return <EmptySpace />
                        case 'ImageGroup':
                            return <ImageGroup>
                                {item.content.map((i) => {
                                    console.log(i)
                                    return <img src={i.image} className={i.className}/>
                                })}
                            </ImageGroup>
                    }
                })
            } */}
		</NoticiaLayout>
	);
}
