import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import AboutLayout from '../layouts/AboutLayout';

import SimpleSection from '../components/SimpleSection';

import Site from '../images/site/sede-cirma-antigua-guatemala.jpg';
import Timeline from '../components/Timeline';

import { timeline1 } from '../data/timeline';
import { removeRightClick } from '../utilities/util';
import WBkey from '../components/WBkey';

export default function SiteScreen(props) {
	return (
		<AboutLayout
			title='Casa No. 5'
			subtitle='Sede de Cirma Desde 1979'
			backgroundImage='sede'
		>
			<Container>
				<SimpleSection className='c-site-cirma'>
					<Row>
						<Col sm={12} lg={4}>
							<div className='c-img-cont'>
								<img
									src={Site}
									alt='Más de 40 años de historia en Guatemala'
									onContextMenu={removeRightClick}
								/>
							</div>
						</Col>
						<Col sm={12} lg={8}>
							<div className='c-site-info'>
								<h2 className='c-site-title'>
									<WBkey wbkey='caJ6YhrA' />
								</h2>
								<div className='c-site-since-info'>
									<div className='c-site-since'>
										<WBkey wbkey='JgzJ0Pfa' />
									</div>
								</div>
								<WBkey wbkey='LBy0D4hF' />
							</div>
						</Col>
					</Row>
				</SimpleSection>
				<SimpleSection className='c-site-cirma'>
					<div className='c-site-scriptures'>
						<div className='c-site-scriptures-describe'>
							<WBkey wbkey='5Hfgi0NB' />
						</div>
						<div className='c-site-scriptures-note'>
							<WBkey wbkey='BM4oeLpP' />
						</div>
					</div>
				</SimpleSection>
				<SimpleSection className='c-site-cirma'>
					<div className='c-site-history'>
						<Timeline timeline={timeline1} />
					</div>
				</SimpleSection>
				{/* <SimpleSection className='c-site-cirma'>
					<div className='c-site-scriptures'>
						<div className='c-site-scriptures-note'>
							<WBkey wbkey='szV39jkL'/>	
						</div>
					</div>
				</SimpleSection> */}
				{/* <SimpleSection className='c-site-cirma'>
					<div className='c-site-history'>
						<Timeline timeline={timeline2} />
					</div>
				</SimpleSection> */}
				<SimpleSection className='c-site-cirma'>
					<div className='c-site-scriptures'>
						<div className='c-site-scriptures-note'>
							<WBkey wbkey='szV39jkL' />
						</div>
					</div>
				</SimpleSection>
			</Container>
		</AboutLayout>
	);
}
