import React, { useState } from 'react';
import { CalendarCheck } from 'react-bootstrap-icons';
import BoletinModal from './BoletinModal';
export default function BoletinCard(props) {
	const { title, date, file, body } = props;
	const [show, setShow] = useState(false);
	const handleShow = () => setShow(true);
	return (
		<>
			<div className='c-bol-card' onClick={handleShow}>
				<div className='c-bol-dates'>
					<CalendarCheck className='c-bol-icon' />
					<h6>{date}</h6>
				</div>
				<div className='c-bol-title'>
					<h5>{title}</h5>
				</div>
			</div>
			<BoletinModal
				show={show}
				file={file}
				body={body}
				title={title}
				onHide={() => setShow(false)}
			/>
		</>
	);
}
