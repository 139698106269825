import React from 'react';
import { Modal } from 'react-bootstrap';
//import { Facebook, Instagram, Youtube } from 'react-bootstrap-icons';
//import Logo from '../images/cirma-logo-home.svg';
import { DRUPAL_URL } from '../utilities/globals';

export default function BoletinModal(props) {
	const { show, onHide, file, body, title } = props;
	return (
		<Modal show={show} onHide={onHide} className='c-bmodal' size={'lg'}>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			{file && (
				<Modal.Body>
					<iframe
						src={`${DRUPAL_URL}/glifos/file/get/${file}`}
						width='100%'
						height='100%'
						title={title}
					/>
				</Modal.Body>
			)}
			{!file && body && (
				<Modal.Body>
					<p>{body}</p>
				</Modal.Body>
			)}
			{/* <Modal.Header closeButton>
                <img src={Logo} alt='CIRMA Logo' className='c-bmodal-logo' />
            </Modal.Header>
            <Modal.Body>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
                </p>
                <p>Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</p>

            </Modal.Body>
            <Modal.Footer>
                <p>5a. Calle Oriente No. 5, La Antigua Guatemala</p>
                <p>(502) 7931-0300</p>
                <p>cirma@cirma.org.gt</p>
                <div className='c-modal-icons'>
                    <div className='c-modal-icon'>
                        <Facebook className='c-modal-i' />
                    </div>
                    <div className='c-modal-icon'>
                        <Instagram className='c-modal-i' />
                    </div>
                    <div className='c-modal-icon'>
                        <Youtube className='c-modal-i' />
                    </div>
                </div>
            </Modal.Footer> */}
		</Modal>
	);
}
