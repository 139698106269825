import React from 'react';
import { CircleFill } from 'react-bootstrap-icons';
import { removeRightClick } from '../utilities/util';

export default function TimelineItem(props) {
	return (
		<div className={`timeline-row ${props.position}`}>
			<div className={`timeline-box-cont ${props?.position}`}>
				<div className={`arrow ${props?.position}`}></div>
				{props?.item?.image && (
					<div className={`timeline-box ${props?.position} image`}>
						{props?.position === 'left' && (
							<div className='c-img'>
								<div className='c-img-cont'>
									<img
										src={props?.item?.image}
										alt='Imagen'
										onContextMenu={removeRightClick}
									/>
								</div>
							</div>
						)}
						{props?.item?.text &&
							props?.item?.text?.split('\n').map((t, i) => (
								<p className='mt-0' key={i}>
									{t}
								</p>
							))}
						{props?.position === 'right' && (
							<div className='c-img'>
								<div className='c-img-cont image-right'>
									<img
										src={props?.item?.image}
										alt='Imagen'
										onContextMenu={removeRightClick}
									/>
								</div>
							</div>
						)}
					</div>
				)}
				{!props?.item?.image && (
					<div className={`timeline-box ${props?.position}`}>
						{props?.item?.text &&
							props?.item?.text?.split('\n').map((t, i) => (
								<p className='mt-0' key={i}>
									{t}
								</p>
							))}
					</div>
				)}
			</div>
			<div className='timeline-marker'>
				<div className='vertical-line'></div>
				<CircleFill />
			</div>
			<div className={`timeline-date ${props?.position}`}>
				<h2 className={`${props?.position}`}>{props?.item?.year}</h2>
			</div>
		</div>
	);
}
