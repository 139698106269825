import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './DirectContact.module.scss';
import WBkey from '../../../components/WBkey';

export default function DirectContact({ subject, message }) {
	return (
		<Container fluid className='m-0 p-5 background-primary'>
			<Col>
				<Row className='justify-content-center'>
					<h2 className='text-center'>
						<WBkey wbkey='7WaazDr8' />
					</h2>
				</Row>
				<Row className='justify-content-center gap-3'>
					<a
						href={`mailto:${WBkey({
							variant: 'plain',
							wbkey: '0BDT_rqZ',
						})}?subject=${subject}&body=${message}`}
						target='blank_'
						rel='noreferrer'
						className={`text-decoration-none w-auto d-flex flex-row gap-2 align-items-center ${styles.contactLink}`}
					>
						<i aria-hidden='true' className='fas fa-envelope'></i>
						<span className='m-0 p-0' wbk='0BDT_rqZ'>
							<WBkey variant='plain' wbkey='0BDT_rqZ' />
						</span>
					</a>
					<a
						href={`https://wa.me/${WBkey({
							variant: 'plain',
							wbkey: 'MJTqAxP2',
						}).replace(/[+ -]/g, '')}?text=${message}`}
						target='_blank'
						rel='noreferrer'
						className={`text-decoration-none w-auto d-flex flex-row gap-2 align-items-center ${styles.contactLink}`}
					>
						<i
							aria-hidden='true'
							className='fab fa-whatsapp-square'
						></i>
						<span className='m-0 p-0' wbk='MJTqAxP2'>
							<WBkey variant='plain' wbkey='MJTqAxP2' />
						</span>
					</a>
				</Row>
			</Col>
		</Container>
	);
}
