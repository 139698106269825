import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Autoplay } from 'swiper';
import 'swiper/css';
import { removeRightClick } from '../utilities/util';
export default function Swiperr(props) {
	const {
		Images,
		slidesPerView,
		spaceBetween,
		publication,
		Ack,
		setLink,
		setShowWatcher,
	} = props;
	return (
		<>
			{publication && (
				<Swiper
					modules={[Navigation, Pagination, Autoplay, Mousewheel]}
					spaceBetween={spaceBetween}
					slidesPerView={slidesPerView}
					/* onSlideChange={() =>
                        //console.log('slide change')
                    } */
					cssMode
					keyboard
					freeMode
					autoplay={{ delay: 2000 }}
					navigation={{
						clickable: true,
					}}
					mousewheel={true}
					pagination={{
						clickable: true,
					}}
					onSwiper={(swiper) => console.log(swiper)}
					style={{
						color: 'red',
					}}>
					{Images.map((image) => {
						return (
							<SwiperSlide>
								<div className='c-img-cont'>
									<img src={image} alt='img' onContextMenu={removeRightClick} />
								</div>
							</SwiperSlide>
						);
					})}
				</Swiper>
			)}
			{Ack && (
				<Swiper
					modules={[Navigation, Pagination, Autoplay, Mousewheel]}
					spaceBetween={spaceBetween}
					slidesPerView={slidesPerView}
					onSlideChange={() => console.log('slide change')}
					cssMode
					keyboard
					freeMode
					autoplay={{ delay: 3000 }}
					mousewheel={true}
					pagination={{
						clickable: true,
					}}
					onSwiper={(swiper) => console.log(swiper)}
					style={{
						color: 'red',
					}}>
					{Images.map((image) => {
						return (
							<SwiperSlide>
								<div
									className='c-img-cont'
									onClick={() => {
										setShowWatcher(true);
										setLink(image);
									}}>
									<img src={image} alt='img' onContextMenu={removeRightClick}/>
								</div>
							</SwiperSlide>
						);
					})}
				</Swiper>
			)}
		</>
	);
}
